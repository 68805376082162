import { all } from 'redux-saga/effects';
import getUserProfileSaga from './watchers/getUserProfile';
import updateUserSaga from './watchers/updateUser';
import deleteUserSaga from './watchers/deleteUser';

function* competencesSaga() {
  yield all([getUserProfileSaga(), updateUserSaga(), deleteUserSaga()]);
}

export default competencesSaga;
