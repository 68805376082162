import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';

const ErrorLabel = ({ error, t }) => (
  <FormFeedback valid={false}>{t(`forms.validations.${error}`)}</FormFeedback>
);

ErrorLabel.propTypes = {
  error: PropTypes.string.isRequired,
};

export default withTranslation('common')(ErrorLabel);
