import { call, put, takeLatest } from 'redux-saga/effects';
import { Users } from 'services/api';
import { deleteProfile } from '../../actions';
import { logOut } from 'services/session/actions';

function* deleteUserSaga() {
  try {
    yield call(Users.delete);
    yield put(logOut());
  } catch (e) {
    console.log(e);
  }
}

export default () => takeLatest(deleteProfile, deleteUserSaga);
