import { handleActions, combineActions } from 'redux-actions';
import {
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
  addEmployee,
  addEmployeeSuccess,
  addEmployeeFailure,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFailure,
  removeEmployee,
  removeEmployeeSuccess,
  removeEmployeeFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  users: [],
  errors: [],
};

const employeesReducer = handleActions(
  {
    [combineActions(
      getEmployees,
      addEmployee,
      updateEmployee,
      removeEmployee
    )]: state => ({
      ...state,
      isFetching: true,
      errors: [],
    }),

    [combineActions(
      addEmployeeSuccess,
      updateEmployeeSuccess,
      removeEmployeeSuccess
    )]: state => ({
      ...state,
      isFetching: false,
      errors: [],
    }),

    [getEmployeesSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      users: payload.data,
      errors: [],
    }),

    [combineActions(
      getEmployeesFailure,
      addEmployeeFailure,
      updateEmployeeFailure,
      removeEmployeeFailure
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload && payload.errors,
    }),
  },
  INITIAL_STATE
);

export default employeesReducer;
