import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from '../../actions';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { PostTopicForm } from '../PostTopicForm';

import styles from './styles.module.scss';

class PostTopic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editForm: false,
    };
  }

  handleDelete = id => {
    this.props.dispatch(actions.deletePostTopic(id));
  };

  handleEdit = values => {
    this.props.dispatch(actions.updatePostTopic(values));
    this.setState({ editForm: false });
  };

  toggleEditForm = () => {
    this.setState({
      editForm: !this.state.editForm,
    });
  };

  render() {
    const { postTopic, t } = this.props;
    return (
      <div className={styles['post-topic']}>
        {this.state.editForm ? (
          <PostTopicForm
            postTopic={postTopic}
            onSubmit={this.handleEdit}
            onCancel={() => this.toggleEditForm()}
          />
        ) : (
          <div className={styles.wrapper}>
            <span className={styles.name}>{postTopic.name}</span>
            <span className={styles.count}>
              {postTopic.posts} {t('scenes.admin.postTopics.posts')}
            </span>
          </div>
        )}

        <Button
          color="link"
          className={styles.button + ' ' + styles.secondary}
          onClick={() => this.toggleEditForm()}
        >
          <i className="fas fa-pen" />
        </Button>
        <Button
          color="link"
          className={styles.button + ' ' + styles.danger}
          onClick={() => this.handleDelete(postTopic.id)}
        >
          <i className="fas fa-trash" />
        </Button>
      </div>
    );
  }
}

PostTopic.propTypes = {
  postTopic: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
};

export default compose(
  withTranslation('common'),
  connect()
)(PostTopic);
