import React from 'react';
import { Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class Competence extends React.Component {
  isChecked = option => {
    let answer = this.props.answers.find(
      obj => obj.competenceId === this.props.competence.id
    );

    return answer ? answer.answerOptionId === parseInt(option.id) : false;
  };

  getAnswerType = () => {
    return this.props.answerTypes.find(
      obj => obj.id === this.props.competence.answerTypeId
    );
  };

  handleChange = e => {
    if (typeof this.props.onAnswerChange === 'function') {
      this.props.onAnswerChange(e);
    }
  };

  render() {
    const { competence } = this.props;
    return (
      <div key={competence.id} className={styles['competence-wrapper']}>
        <div className={styles.header}>
          <div className={styles.left}>
            <span className={styles.name}>{competence.name}</span>
            {this.props.admin && (
              <Button
                color="link"
                className={styles.button + ' ' + styles.secondary}
                onClick={() => this.props.onCompetenceEdit(competence.id)}
              >
                <i className="fas fa-pen" />
              </Button>
            )}
            {this.props.admin && (
              <Button
                color="link"
                className={styles.button + ' ' + styles.danger}
                onClick={() =>
                  this.props.onCompetenceDelete(competence, 'competence')
                }
              >
                <i className="fas fa-trash" />
              </Button>
            )}
          </div>
          {this.props.admin && (
            <div className={styles.right}>
              <Button
                color="link"
                className={styles.button + ' ' + styles.secondary}
                id={this.props.togglerId}
              >
                <i className="fas fa-angle-down" />
              </Button>
            </div>
          )}
        </div>

        <div
          className={`btn-group btn-group-toggle ${styles.answers}`}
          data-toggle="buttons"
        >
          {this.getAnswerType().answerOptions.map(option => (
            <Label
              key={option.id}
              className={`btn btn-secondary ${styles.answer}
                    ${this.isChecked(option) ? styles.active : ''}
                    ${this.props.disabled ? 'disabled' : ''}`}
            >
              <Input
                type="radio"
                name={'competence_' + competence.id + '_' + option.id}
                value={option.id}
                checked={this.isChecked(option)}
                onChange={this.handleChange}
              />
              {option.text}
            </Label>
          ))}
        </div>
      </div>
    );
  }
}

Competence.propTypes = {
  competence: PropTypes.object.isRequired,
  admin: PropTypes.bool,
};

Competence.defaultProps = {
  answers: [],
  answerTypes: [],
  disabled: false,
  admin: false,
};

export default Competence;
