import { all } from 'redux-saga/effects';
import watchGetAnswerTypes from './watchers/getAnswerTypes';
import watchCreateAnswerType from './watchers/createAnswerType';
import watchUpdateAnswerType from './watchers/updateAnswerType';
import watchDeleteAnswerType from './watchers/deleteAnswerType';

function* answerTypesSaga() {
  yield all([
    watchGetAnswerTypes(),
    watchCreateAnswerType(),
    watchUpdateAnswerType(),
    watchDeleteAnswerType(),
  ]);
}

export default answerTypesSaga;
