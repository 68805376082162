import { createAction } from 'redux-actions';

export const getAdminPosts = createAction('ADMIN_POST_LIST_REQUEST');
export const getAdminPostsSuccess = createAction('ADMIN_POST_LIST_SUCCESS');
export const getAdminPostsFailure = createAction('ADMIN_POST_LIST_FAILURE');
export const acceptPost = createAction('ADMIN_POST_ACCEPT_REQUEST');
export const acceptPostSuccess = createAction('ADMIN_POST_ACCEPT_SUCCESS');
export const acceptPostFailure = createAction('ADMIN_POST_ACCEPT_FAILURE');
export const declinePost = createAction('ADMIN_POST_DECLINE_REQUEST');
export const declinePostSuccess = createAction('ADMIN_POST_DECLINE_SUCCESS');
export const declinePostFailure = createAction('ADMIN_POST_DECLINE_FAILURE');

export default {
  getAdminPosts,
  getAdminPostsSuccess,
  getAdminPostsFailure,
  acceptPost,
  acceptPostSuccess,
  acceptPostFailure,
  declinePost,
  declinePostSuccess,
  declinePostFailure,
};
