import { call, put, takeLatest } from 'redux-saga/effects';
import { PostTopics } from 'services/api';
import {
  deletePostTopic,
  deletePostTopicSuccess,
  deletePostTopicFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* deletePostTopicSaga(action) {
  try {
    const data = yield call(PostTopics.deletePostTopic, action.payload);
    yield put(deletePostTopicSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'deleted', resource: 'topic' } },
      ])
    );
  } catch (e) {
    yield put(deletePostTopicFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(deletePostTopic, deletePostTopicSaga);
