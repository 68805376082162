import { createAction } from 'redux-actions';

export const getDashboard = createAction('GET_DASHBOARD_REQUEST');
export const getDashboardSuccess = createAction('GET_DASHBOARD_SUCCESS');
export const getDashboardFailure = createAction('GET_DASHBOARD_FAILURE');

export default {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
};
