import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import {
  LEGAL_FORM_OPTIONS,
  POSITION_OPTIONS,
  validationOptions,
} from 'constants/SelectOptions';
import { Button, FormGroup } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';
import Users from 'services/api/Users';

import styles from './styles.module.scss';
import { CompaniesSelect } from 'components/CompaniesSelect';

const initialValues = {
  companyName: '',
  companyEmail: '',
  companyWebsite: '',
  companyLegalForm: '',
  companyPosition: '',
  companyLogo: undefined,
  checkEmail: true,
  companyId: '',
};

const validationSchema = Yup.object({
  checkEmail: Yup.boolean(),
  companyName: Yup.string().required('required'),
  companyEmail: Yup.string()
    .required('required')
    .email('email.invalid')
    .when('checkEmail', {
      is: true,
      then: Yup.string().test('free-email', 'email.taken', value => {
        if (typeof value !== 'undefined') {
          return Users.checkEmail({
            email: value,
            type: 'company',
          })
            .then(({ data: { isOk } }) => isOk)
            .catch(false);
        }
      }),
    }),
  companyWebsite: Yup.string()
    .matches(/[.]/, 'urlFormat')
    .required('required'),
  companyLegalForm: Yup.string()
    .required('required')
    .oneOf(validationOptions(LEGAL_FORM_OPTIONS), 'selectOption'),
  companyPosition: Yup.string()
    .required('required')
    .oneOf(validationOptions(POSITION_OPTIONS), 'selectOption'),
  companyLogo: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: '',
    };
  }

  handleImageSelect = e => {
    this.props.setFieldValue('companyLogo', e.target.files[0]);
    this.props.setFieldTouched('companyLogo');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('companyLogo', '');
    this.setState({
      previewImage: '',
    });
  };

  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  handleCompanySelect = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === '') {
      // Reset field values
      this.props.setFieldValue(name, value);

      this.props.setFieldValue('checkEmail', true);

      Object.keys(initialValues).forEach(key => {
        if (key !== 'companyLogo') {
          this.props.setFieldValue(key, '');
          this.props.setFieldTouched(key, false);
        }
      });

      this.setState({
        previewImage: '',
      });
    } else {
      // Set field values to the selected company
      this.props.setFieldValue(name, value);

      this.props.setFieldValue('checkEmail', false);

      const selectedCompany = this.props.companies.find(company => {
        return company.id.toString() === value;
      });

      Object.keys(initialValues).forEach(key => {
        if (!['companyLogo', 'companyPosition'].includes(key)) {
          const newKey = key.replace('company', '');
          this.props.setFieldValue(
            key,
            selectedCompany[newKey.charAt(0).toLowerCase() + newKey.slice(1)]
          );
          this.props.setFieldTouched(key, false);
        }
      });

      this.setState({
        previewImage: selectedCompany.logoUrl,
      });
    }
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    const disabled = Boolean(values.companyId);
    return (
      <section className="public-wrapper">
        <div className={styles.title}>
          <h2>{t('scenes.public.registration.company.title')}</h2>
          <hr />
        </div>
        <div className={styles['registration-form']}>
          <Form className={styles.form}>
            <div className={styles['form-wrapper']}>
              <div className={styles['left-side']}>
                <InputField
                  name="companyName"
                  type="string"
                  labelText={t('scenes.public.registration.company.form.name')}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  disabled={disabled}
                  errorLabel
                />
                <InputField
                  name="companyEmail"
                  type="email"
                  labelText={t('scenes.public.registration.company.form.email')}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  disabled={disabled}
                  errorLabel
                />
                <InputField
                  name="companyWebsite"
                  type="string"
                  labelText={t(
                    'scenes.public.registration.company.form.website'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  disabled={disabled}
                  errorLabel
                />
                <InputField
                  name="companyLegalForm"
                  type="select"
                  labelText={t(
                    'scenes.public.registration.company.form.legalForm.label'
                  )}
                  errors={errors}
                  touched={touched}
                  options={LEGAL_FORM_OPTIONS}
                  onChange={this.handleSelect}
                  className={styles.input}
                  value={values.companyLegalForm}
                  disabled={disabled}
                  errorLabel
                />
                <InputField
                  name="companyPosition"
                  type="select"
                  labelText={t(
                    'scenes.public.registration.company.form.position.label'
                  )}
                  errors={errors}
                  touched={touched}
                  options={POSITION_OPTIONS}
                  onChange={this.handleSelect}
                  className={styles.input}
                  errorLabel
                />
              </div>
              <div className={styles['right-side']}>
                <ImageUpload
                  name="companyLogo"
                  type="file"
                  onChange={this.handleImageSelect}
                  onClose={this.handleImageCancel}
                  previewImage={this.state.previewImage}
                  errorMessage={errors.companyLogo}
                  disabled={disabled}
                />
                <div className={styles['name-preview']}>
                  {values.companyName}
                </div>
              </div>
            </div>
            <CompaniesSelect
              onChange={this.handleCompanySelect}
              upperHr
              public
            />
            <footer>
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isFetching}
                  block
                >
                  {t('scenes.public.registration.company.form.submit')}
                </Button>
              </FormGroup>
              <FormGroup>
                <Button onClick={this.props.onBackClick} color="link" block>
                  {t('scenes.public.registration.company.form.cancel')}
                </Button>
              </FormGroup>
            </footer>
          </Form>
        </div>
      </section>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.companyLogo === 'undefined') {
        delete values.companyLogo;
      }

      if (values.companyId !== '') {
        Object.keys(initialValues).forEach(value => {
          if (value !== 'companyPosition' && value !== 'companyId') {
            delete values[value];
          }
        });
      } else {
        delete values.companyId;
      }
      delete values.checkEmail;
      formikBag.props.onSubmit(values);
    },

    displayName: 'RegistrationCompanyForm',
  })
)(Company);
