import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as adminUsersActions from '../../actions';
import { Nav, NavItem, Table } from 'reactstrap';

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(adminUsersActions.adminUsers());
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h1>{t('scenes.admin.users.title')}</h1>
        <Table hover responsive>
          <thead>
            <tr>
              <th>{t('scenes.admin.users.table.name')}</th>
              <th>{t('scenes.admin.users.table.email')}</th>
              <th>{t('scenes.admin.users.table.actions.header')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.users &&
              this.props.users.length > 0 &&
              this.props.users.map(user => (
                <tr key={user.id}>
                  <td>{`${user.firstName} ${user.lastName}`}</td>
                  <td>{user.email}</td>
                  <td>
                    <Nav>
                      <NavItem>
                        <Link to={`/admin/users/${user.id}`}>
                          {t('scenes.admin.users.table.actions.edit')}
                        </Link>
                      </NavItem>
                    </Nav>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.adminUsers.users,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(UsersTable);
