import { createAction } from 'redux-actions';

export const getAnswerTypes = createAction('ANSWER_TYPE_LIST_REQUEST');
export const getAnswerTypesSuccess = createAction('ANSWER_TYPE_LIST_SUCCESS');
export const getAnswerTypesFailure = createAction('ANSWER_TYPE_LIST_FAILURE');
export const createAnswerType = createAction('ANSWER_TYPE_CREATE_REQUEST');
export const createAnswerTypeSuccess = createAction(
  'ANSWER_TYPE_CREATE_SUCCESS'
);
export const createAnswerTypeFailure = createAction(
  'ANSWER_TYPE_CREATE_FAILURE'
);
export const updateAnswerType = createAction('ANSWER_TYPE_UPDATE_REQUEST');
export const updateAnswerTypeSuccess = createAction(
  'ANSWER_TYPE_UPDATE_SUCCESS'
);
export const updateAnswerTypeFailure = createAction(
  'ANSWER_TYPE_UPDATE_FAILURE'
);
export const deleteAnswerType = createAction('ANSWER_TYPE_DELETE_REQUEST');
export const deleteAnswerTypeSuccess = createAction(
  'ANSWER_TYPE_DELETE_SUCCESS'
);
export const deleteAnswerTypeFailure = createAction(
  'ANSWER_TYPE_DELETE_FAILURE'
);

export default {
  getAnswerTypes,
  getAnswerTypesSuccess,
  getAnswerTypesFailure,
  createAnswerType,
  createAnswerTypeSuccess,
  createAnswerTypeFailure,
  updateAnswerType,
  updateAnswerTypeSuccess,
  updateAnswerTypeFailure,
  deleteAnswerType,
  deleteAnswerTypeSuccess,
  deleteAnswerTypeFailure,
};
