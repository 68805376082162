import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompaniesTable } from './components/CompaniesTable';
import { CompanyEdit } from './components/CompanyEdit';

class Companies extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/companies" component={CompaniesTable} />
        <Route path="/admin/companies/:number" component={CompanyEdit} />
      </Switch>
    );
  }
}

export default Companies;
