import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  createPost,
  createPostSuccess,
  createPostFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function convertKey(key) {
  return key.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

function* createPostSaga({ payload }) {
  try {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (key === 'attachments' && value.length > 0) {
        Array.from(value).forEach(file => {
          formData.append('post[attachments][]', file);
        });
      }

      if (value !== '' && key !== 'attachments' && key !== 'asCompany') {
        formData.append(`post[${convertKey(key)}]`, value);
      }
    });
    const data = yield call(Posts.create, formData);
    yield put(createPostSuccess(data));
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'postCreated' },
        },
      ])
    );
  } catch (e) {
    yield put(createPostFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(createPost, createPostSaga);
