import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Table, Tooltip } from 'reactstrap';
import { ConfirmModal } from 'components/ConfirmModal';

import styles from './styles.module.scss';

class EmployeesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: {},
      openModal: false,
      deletableId: '',
    };
  }

  toggleTooltip = (admin, index) => {
    this.setState({
      tooltipOpen: { [`${index}`]: !this.state.tooltipOpen[index] },
    });
  };

  removeUser = id => {
    if (this.props.employees && this.props.employees.length > 0) {
      this.props.onRemoveUser(id);
    }
    this.setState({ openModal: false });
  };

  changePermission = (id, admin) => {
    if (this.canChange(admin)) {
      this.props.onPermissionChange(id, admin);
    }
  };

  canChange = admin => {
    // Always allowed to make a user an admin
    if (!admin) {
      return true;
    }

    // Check if there is just one user
    if (this.props.employees && this.props.employees.length === 1) {
      return false;
    }

    // Check it there is another company admin
    return Boolean(
      this.props.employees &&
        this.props.employees.find(
          user =>
            user.admin === true && user.user.id !== this.props.sessionUser.id
        )
    );
  };

  toggleModal = id => {
    this.setState({ openModal: !this.state.openModal, deletableId: id });
  };

  render() {
    const { employees, t } = this.props;
    return (
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('components.manageEmployees.table.name')}</th>
            <th>{t('components.manageEmployees.table.email')}</th>
            <th>{t('components.manageEmployees.table.position')}</th>
            <th>{t('components.manageEmployees.table.admin')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {employees &&
            employees.length > 0 &&
            employees.map((companyUser, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{`${companyUser.user.firstName} ${
                  companyUser.user.lastName
                }`}</td>
                <td>{companyUser.user.email}</td>
                <td>{companyUser.position}</td>
                <td>
                  <Button
                    id={`companyAdminButton${index}`}
                    color="link"
                    onClick={() =>
                      this.changePermission(
                        companyUser.user.id,
                        companyUser.admin
                      )
                    }
                    disabled={!this.canChange(companyUser.admin)}
                  >
                    {companyUser.admin ? (
                      <i className="fas fa-check" />
                    ) : (
                      <i className="fas fa-times" />
                    )}
                  </Button>
                  <Tooltip
                    placement="right"
                    isOpen={this.state.tooltipOpen[index]}
                    target={`companyAdminButton${index}`}
                    toggle={() => this.toggleTooltip(companyUser.admin, index)}
                  >
                    {companyUser.admin
                      ? t('components.manageEmployees.tooltip.remove')
                      : t('components.manageEmployees.tooltip.add')}
                  </Tooltip>
                </td>
                <td>
                  <Button
                    outline
                    color="danger"
                    onClick={() => this.toggleModal(companyUser.user.id)}
                    disabled={!this.canChange(companyUser.admin)}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
        <ConfirmModal
          modalOpen={this.state.openModal}
          toggleModal={this.toggleModal}
          primaryButtonText={t('confirmationModal.submit')}
          onModalConfirm={() => this.removeUser(this.state.deletableId)}
        >
          <i className={`far fa-times-circle ${styles['modal-icon']}`} />
          <h3>{t('confirmationModal.title')}</h3>
          <p>
            {t('confirmationModal.text', {
              name: 'this user',
              resource: 'from your company',
            })}
          </p>
        </ConfirmModal>
      </Table>
    );
  }
}

EmployeesTable.propTypes = {
  company: PropTypes.object.isRequired,
  sessionUser: PropTypes.object.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
};

export default withTranslation('common')(EmployeesTable);
