import { call, put, takeLatest } from 'redux-saga/effects';
import { AdminCompetences } from 'services/api';
import {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getCompaniesSaga(action) {
  try {
    const { data } = yield call(AdminCompetences.filterOptions, action.payload);
    yield put(getFilterOptionsSuccess(data));
  } catch (e) {
    yield put(getFilterOptionsFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(getFilterOptions, getCompaniesSaga);
