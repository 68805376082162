import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  declinePost,
  declinePostSuccess,
  declinePostFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* declinePostSaga(action) {
  try {
    const data = yield call(Posts.declinePost, action.payload);
    yield put(declinePostSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'moderatePosts.decline' } },
      ])
    );
  } catch (e) {
    yield put(declinePostFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(declinePost, declinePostSaga);
