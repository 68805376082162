import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const ErrorAlert = ({ errors }) => {
  return errors.map(error => (
    <Alert color="danger" key={error.code}>
      {error.status} - {error.code}
    </Alert>
  ));
};

ErrorAlert.propTypes = {
  errors: PropTypes.array.isRequired,
};

ErrorAlert.defaultProps = {
  errors: [],
};

export default ErrorAlert;
