import { call, put, takeLatest } from 'redux-saga/effects';
import { Companies } from 'services/api';
import {
  adminUpdateCompany,
  adminUpdateCompanySuccess,
  adminUpdateCompanyFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function convertKey(key) {
  return key.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

function* updateCompany({ payload }) {
  try {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(`company[${convertKey(key)}]`, value);
    });
    const { data } = yield call(
      Companies.adminCompanyUpdate,
      payload.id,
      formData
    );
    yield put(adminUpdateCompanySuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'changes' } },
      ])
    );
  } catch (e) {
    yield put(adminUpdateCompanyFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(adminUpdateCompany, updateCompany);
