import store from 'store2';
import { call, take } from 'redux-saga/effects';
import { logOut } from 'services/session/actions';

function removeAuthToken() {
  store.remove('token');
}

function* logoutSaga() {
  while (true) {
    yield take(logOut);
    yield call(removeAuthToken);
  }
}

export default logoutSaga;
