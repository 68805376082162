import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button, FormGroup } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';

import styles from './styles.module.scss';

const initialValues = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, 'password')
    .required('required'),
  password: Yup.string()
    .notOneOf([Yup.ref('oldPassword')], 'Passwords needs to be a new one')
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, 'password')
    .required('required'),
  passwordConfirmation: Yup.string()
    .notOneOf([Yup.ref('oldPassword')], 'Passwords needs to be a new one')
    .oneOf([Yup.ref('password')], 'passwordConfirmationMatch'),
});

class PasswordChange extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.onCancel('personal');
    }
  }

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <div className={styles['change-password-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            <InputField
              name="oldPassword"
              type="password"
              labelText={t('scenes.public.profileModal.changePassword.old')}
              errors={errors}
              touched={touched}
              className={styles.input}
              errorLabel
            />
            <hr />
            <InputField
              name="password"
              type="password"
              labelText={t('scenes.public.profileModal.changePassword.new')}
              errors={errors}
              touched={touched}
              className={styles.input}
              errorLabel
            />
            <InputField
              name="passwordConfirmation"
              type="password"
              labelText={t(
                'scenes.public.profileModal.changePassword.confirmation'
              )}
              errors={errors}
              touched={touched}
              className={styles.input}
              errorLabel
            />
          </div>
          <footer>
            <FormGroup>
              <Button
                onClick={() => this.props.onCancel('personal')}
                color="secondary"
                outline
              >
                {t('scenes.public.profileModal.changePassword.cancel')}
              </Button>
              <Button type="submit" color="primary" disabled={isFetching}>
                {t('scenes.public.profileModal.changePassword.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
      </div>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.onSubmit(values);
    },

    displayName: 'PasswordChangeFrom',
  })
)(PasswordChange);
