import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'reactstrap';
import { withFormik, Form as FormikForm } from 'formik';
import { InputField } from 'components/Form';
import * as Yup from 'yup';

import styles from './styles.module.scss';

const initialValues = {
  body: '',
};

const validationSchema = Yup.object({
  body: Yup.string(),
});

class Form extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.resetForm();
    }
  }

  render() {
    const { isFetching, touched, errors } = this.props;
    return (
      <div className={styles['form-wrapper']}>
        <div className={styles.count}>
          <Button
            color="link"
            onClick={this.props.onListOpenChange}
            disabled={this.props.count === 0}
          >
            {this.props.count} comments
          </Button>
        </div>
        <FormikForm className={styles.form}>
          <div className={styles.inputs}>
            <InputField
              errors={errors}
              touched={touched}
              name="body"
              type="text"
              placeholder="Comment"
              validate={false}
              className={styles.input}
            />
            <FormGroup className={styles.button}>
              <Button type="submit" color="primary" disabled={isFetching} block>
                Send
              </Button>
            </FormGroup>
          </div>
        </FormikForm>
      </div>
    );
  }
}

Form.propTypes = { count: PropTypes.number };

export default withFormik({
  mapPropsToValues: () => initialValues,

  validationSchema,

  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
  },

  displayName: 'CommentFrom',
})(Form);
