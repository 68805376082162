import { handleActions } from 'redux-actions';
import {
  selectLanguage,
  selectLanguageSuccess,
  selectLanguageFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  language: '',
  errors: [],
};

const languageSelectorReducer = handleActions(
  {
    [selectLanguage]: state => ({
      ...state,
      isFetching: true,
      errors: [],
    }),

    [selectLanguageSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      language: payload,
      errors: [],
    }),

    [selectLanguageFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload && payload.errors,
    }),
  },
  INITIAL_STATE
);

export default languageSelectorReducer;
