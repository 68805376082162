function createOptions(key, number) {
  return Array.apply(null, { length: number })
    .map(Function.call, Number)
    .map(count => `forms.options.${key}.option${count + 1}`);
}

export function validationOptions(options) {
  return Array.apply(null, { length: options.length })
    .map(Function.call, Number)
    .map(count => `option${count + 1}`);
}

export const LEGAL_FORM_OPTIONS = createOptions('legalForm', 6);

export const POSITION_OPTIONS = createOptions('position', 3);

export const EDUCATION_LEVELS = createOptions('education', 7);

export const LEVELS = createOptions('level', 3);

export const STUDENT = createOptions('student', 2);

export const LANGUAGES = ['en', 'hu', 'sr'];

export default {
  LEGAL_FORM_OPTIONS,
  POSITION_OPTIONS,
  EDUCATION_LEVELS,
  LANGUAGES,
  LEVELS,
  STUDENT,
};
