import { takeLatest, put } from 'redux-saga/effects';
import {
  saveFilter,
  saveFilterSuccess,
  saveFilterFailure,
} from '../../actions';

function* saveFilterSaga({ payload }) {
  try {
    yield put(saveFilterSuccess(payload));
  } catch (e) {
    yield put(saveFilterFailure(e.data));
  }
}

export default () => takeLatest(saveFilter, saveFilterSaga);
