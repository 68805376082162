import React from 'react';
import { connect } from 'react-redux';
import { UserForm } from './UserForm';
import * as adminUsersActions from '../../actions';
import Spinner from 'reactstrap/es/Spinner';
import * as sessionActions from 'services/session/actions';

class UserEdit extends React.Component {
  componentDidMount() {
    this.props.dispatch(
      adminUsersActions.adminUser(this.props.match.params.number)
    );
  }

  handleGeneratePassword = () => {
    this.props.user &&
      this.props.dispatch(
        adminUsersActions.generatePassword({
          id: this.props.user.id,
          locale: this.props.language,
        })
      );
  };

  handleConfirmationEmail = () => {
    this.props.user &&
      !this.props.user.confirmed &&
      this.props.dispatch(
        adminUsersActions.sendConfirmationEmail({
          id: this.props.user.id,
          locale: this.props.language,
        })
      );
  };

  handleSubmit = values => {
    this.props.dispatch(adminUsersActions.adminUpdateUser(values));
  };

  refreshSessionUser = () => {
    this.props.sessionUser.id === this.props.user.id &&
      this.props.dispatch(sessionActions.profile());
  };

  render() {
    return this.props.user &&
      this.props.user.id &&
      this.props.user.id.toString() === this.props.match.params.number ? (
      <UserForm
        user={this.props.user}
        isFetching={this.props.isFetching}
        apiErrors={this.props.apiErrors}
        onGeneratePassword={this.handleGeneratePassword}
        onConfirmationEmail={this.handleConfirmationEmail}
        onSubmit={this.handleSubmit}
        onRefreshSessionUser={this.refreshSessionUser}
      />
    ) : (
      <Spinner color="primary" />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.adminUsers.isFetching,
  apiErrors: state.adminUsers.errors,
  user: state.adminUsers.user,
  sessionUser: state.session.user,
  language: state.language.language,
});

export default connect(mapStateToProps)(UserEdit);
