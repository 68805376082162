import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class Sidebar extends React.Component {
  render() {
    return (
      <aside
        className={`${styles.sidebar} ${styles[`${this.props.name}-sidebar`]} ${
          this.props.className
        }`}
      >
        <div className={styles['sidebar-text']}>
          <span className={styles.title}>{this.props.titleText}</span>
          <span className={styles.subtitle}>{this.props.subtitleText}</span>
        </div>
      </aside>
    );
  }
}

Sidebar.propTypes = {
  name: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string,
};

export default Sidebar;
