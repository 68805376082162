import React from 'react';
import { connect } from 'react-redux';
import { confirmation } from './actions';
import { Login } from 'scenes/Login';

class Confirmation extends React.Component {
  componentDidMount() {
    this.props.location.search
      ? this.props.dispatch(
          confirmation(this.props.location.search.replace('?token=', ''))
        )
      : this.props.history.push('/login');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.props.history.push('/login');
    }
  }

  render() {
    return <Login />;
  }
}

const mapStateToProps = state => ({
  isFetching: state.confirmation.isFetching,
  apiErrors: state.confirmation.errors,
});

export default connect(mapStateToProps)(Confirmation);
