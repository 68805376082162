import { createAction } from 'redux-actions';

export const getCompetences = createAction('COMPETENCE_LIST_REQUEST');
export const getCompetencesSuccess = createAction('COMPETENCE_LIST_SUCCESS');
export const getCompetencesFailure = createAction('COMPETENCE_LIST_FAILURE');
export const updateCompetenceAnswers = createAction(
  'COMPETENCE_ANSWER_UPDATE_REQUEST'
);
export const updateCompetenceAnswersSuccess = createAction(
  'COMPETENCE_ANSWER_UPDATE_SUCCESS'
);
export const updateCompetenceAnswersFailure = createAction(
  'COMPETENCE_ANSWER_UPDATE_FAILURE'
);

export default {
  getCompetences,
  getCompetencesSuccess,
  getCompetencesFailure,
  updateCompetenceAnswers,
  updateCompetenceAnswersSuccess,
  updateCompetenceAnswersFailure,
};
