import { call, put, takeLatest } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  profile,
  profileSuccess,
  profileFailure,
} from 'services/session/actions';
import { actions as notificationActions } from 'components/Notification';

function* getUserProfileSaga(action) {
  try {
    const { data } = yield call(Users.profile, action.payload);
    yield put(profileSuccess(data));
  } catch (e) {
    yield put(profileFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(profile, getUserProfileSaga);
