import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthorizedRoute, PublicRoute } from 'components/Routes';

import { MainLayout } from 'layouts/MainLayout';

import { Login } from 'scenes/Login';
import { ForgotPassword } from 'scenes/ForgotPassword';
import { ResetPassword } from 'scenes/ResetPassword';
import { Registration } from 'scenes/Registration';
import { Confirmation } from 'scenes/Confirmation';
import { Notification } from 'components/Notification';

import { selectLanguage } from 'components/LanguageSelector/actions';

class App extends Component {
  componentDidMount() {
    !this.props.language && this.props.dispatch(selectLanguage());
  }

  render() {
    return (
      <main className="app">
        <Notification />

        <BrowserRouter>
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/forgot-password" component={ForgotPassword} />
            <PublicRoute path="/reset-password" component={ResetPassword} />
            <PublicRoute path="/registration" component={Registration} />
            <PublicRoute path="/confirmation" component={Confirmation} />

            <AuthorizedRoute path="/" component={MainLayout} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
});

export default connect(mapStateToProps)(App);
