import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';

import styles from './styles.module.scss';

const validationSchema = Yup.object().shape(
  {
    nameEn: Yup.string().when(['nameHu', 'nameSr'], {
      is: (nameHu, nameSr) => !(nameHu || nameSr),
      then: s => s.required('required'),
    }),
    nameHu: Yup.string().when(['nameEn', 'nameSr'], {
      is: (nameEn, nameSr) => !(nameEn || nameSr),
      then: s => s.required('required'),
    }),
    nameSr: Yup.string().when(['nameEn', 'nameHu'], {
      is: (nameEn, nameHu) => !(nameEn || nameHu),
      then: s => s.required('required'),
    }),
    answerTypeId: Yup.string().required('required'),
  },
  [['nameEn', 'nameHu'], ['nameEn', 'nameSr'], ['nameHu', 'nameSr']]
);

class AddCompetenceForm extends React.Component {
  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  handleClick = () => {
    this.props.onCancel();
  };

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <div className={styles['form-wrapper']}>
        <Form className={styles.form}>
          <InputField
            name="nameEn"
            type="text"
            placeholder={t('scenes.admin.competences.competenceName') + ' (en)'}
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <InputField
            name="nameHu"
            type="text"
            placeholder={t('scenes.admin.competences.competenceName') + ' (hu)'}
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <InputField
            name="nameSr"
            type="text"
            placeholder={t('scenes.admin.competences.competenceName') + ' (sr)'}
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <InputField
            name="answerTypeId"
            type="select"
            errors={errors}
            touched={touched}
            options={this.props.answerTypes.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={this.handleSelect}
            className={styles.inputs}
            value={this.props.values.companyLegalForm}
            errorLabel
          />
          <Button
            type="submit"
            color="primary"
            disabled={isFetching}
            className={styles.buttons}
          >
            Add
          </Button>
          <Button
            color="link"
            onClick={this.handleClick}
            className={styles.buttons}
          >
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

AddCompetenceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => ({
      nameEn: '',
      nameHu: '',
      nameSr: '',
      competenceGroupId: props.competenceGroup || '',
      answerTypeId: props.answerTypes[0] ? props.answerTypes[0].id : '',
    }),

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.onSubmit(values);
    },

    displayName: 'AddCompetenceForm',
  })
)(AddCompetenceForm);
