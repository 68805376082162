import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Post } from 'components/Post';
import { Button } from 'reactstrap';
import { default as socialActions } from './actions';

import styles from './styles.module.scss';

class Social extends React.Component {
  componentDidMount() {
    this.props.dispatch(socialActions.posts());
  }

  handleTopicClick = topic => {
    this.props.dispatch(socialActions.posts({ topic: topic }));
  };

  handleTopicUnselect = topic => {
    const result = this.props.activeTopics.filter(
      activeTopic => activeTopic !== topic
    );
    this.props.dispatch(socialActions.posts({ topic: result }));
  };

  handleDeletePost = id => {
    this.props.dispatch(socialActions.deletePost(id));
  };

  render() {
    return (
      <div className={styles.posts}>
        <h1>
          {this.props.t('scenes.public.social.title')}
          {this.props.activeTopics &&
            this.props.activeTopics.length > 0 &&
            this.props.activeTopics.map(topic => (
              <Button
                className={styles['active-topics']}
                onClick={() => this.handleTopicUnselect(topic)}
                key={topic}
                color="link"
              >
                {' '}
                #{topic}
              </Button>
            ))}
        </h1>
        {this.props.posts &&
          this.props.posts.length > 0 &&
          this.props.posts.map((post, index) => (
            <Post
              post={post}
              currentUser={this.props.sessionUser}
              onTopicClick={this.handleTopicClick}
              key={index}
              t={this.props.t}
              onDeletePost={this.handleDeletePost}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.social.posts,
  activeTopics: state.social.activeTopics,
  sessionUser: state.session.user,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Social);
