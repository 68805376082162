import { handleActions } from 'redux-actions';
import {
  getPostTopics,
  getPostTopicsSuccess,
  getPostTopicsFailure,
  createPostTopic,
  createPostTopicSuccess,
  createPostTopicFailure,
  updatePostTopic,
  updatePostTopicSuccess,
  updatePostTopicFailure,
  deletePostTopic,
  deletePostTopicSuccess,
  deletePostTopicFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  postTopics: [],
  errors: [],
};

const postTopicsReducer = handleActions(
  {
    [getPostTopics]: state => ({
      ...state,
      isFetching: true,
    }),

    [getPostTopicsSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      postTopics: payload.data.postTopics,
    }),

    [getPostTopicsFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [createPostTopic]: state => ({
      ...state,
      isFetching: true,
    }),

    [createPostTopicSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      postTopics: payload.data.postTopics,
    }),

    [createPostTopicFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [updatePostTopic]: state => ({
      ...state,
      isFetching: true,
    }),

    [updatePostTopicSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      postTopics: payload.data.postTopics,
    }),

    [updatePostTopicFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [deletePostTopic]: state => ({
      ...state,
      isFetching: true,
    }),

    [deletePostTopicSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      postTopics: payload.data.postTopics,
    }),

    [deletePostTopicFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default postTopicsReducer;
