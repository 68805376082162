import { createAction } from 'redux-actions';

export const saveFilter = createAction('SAVE_FILTER_REQUEST');
export const saveFilterSuccess = createAction('SAVE_FILTER_SUCCESS');
export const saveFilterFailure = createAction('SAVE_FILTER_FAILURE');

export const clearFilter = createAction('CLEAR_FILTER_REQUEST');
export const clearFilterSuccess = createAction('CLEAR_FILTER_SUCCESS');
export const clearFilterFailure = createAction('CLEAR_FILTER_FAILURE');

export const getFilterOptions = createAction('GET_FILTER_OPTIONS_REQUEST');
export const getFilterOptionsSuccess = createAction(
  'GET_FILTER_OPTIONS_SUCCESS'
);
export const getFilterOptionsFailure = createAction(
  'GET_FILTER_OPTIONS_FAILURE'
);

export default {
  saveFilter,
  saveFilterSuccess,
  saveFilterFailure,
  clearFilter,
  clearFilterSuccess,
  clearFilterFailure,
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
};
