import { all } from 'redux-saga/effects';
import watchGetAdminCompetences from './watchers/getAdminCompetences';
import watchCreateCompetence from './watchers/createCompetence';
import watchUpdateCompetence from './watchers/updateCompetence';
import watchDeleteCompetence from './watchers/deleteCompetence';
import watchCreateCompetenceGroup from './watchers/createCompetenceGroup';
import watchUpdateCompetenceGroup from './watchers/updateCompetenceGroup';
import watchDeleteCompetenceGroup from './watchers/deleteCompetenceGroup';

function* competencesSaga() {
  yield all([
    watchGetAdminCompetences(),
    watchCreateCompetence(),
    watchUpdateCompetence(),
    watchDeleteCompetence(),
    watchCreateCompetenceGroup(),
    watchUpdateCompetenceGroup(),
    watchDeleteCompetenceGroup(),
  ]);
}

export default competencesSaga;
