import { all } from 'redux-saga/effects';
import getAdminUsers from './watchers/getAdminUsers';
import getAdminUser from './watchers/getAdminUser';
import updateUser from './watchers/updateUser';
import generateNewPassword from './watchers/generateNewPassword';
import sendConfirmEmail from './watchers/sendConfirmEmail';

function* answerTypesSaga() {
  yield all([
    getAdminUsers(),
    getAdminUser(),
    updateUser(),
    generateNewPassword(),
    sendConfirmEmail(),
  ]);
}

export default answerTypesSaga;
