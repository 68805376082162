import { call, put, takeLatest } from 'redux-saga/effects';
import { PostTopics } from 'services/api';
import {
  createPostTopic,
  createPostTopicSuccess,
  createPostTopicFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* createPostTopicSaga(action) {
  try {
    const data = yield call(PostTopics.createPostTopic, action.payload);
    yield put(createPostTopicSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'created', resource: 'topic' } },
      ])
    );
  } catch (e) {
    yield put(createPostTopicFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(createPostTopic, createPostTopicSaga);
