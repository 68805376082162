import { get, post, patch, del } from '../rest';

export default {
  all: params => get('/companies', params),
  allPublic: params => get('/public/companies', params),
  addUser: (id, params) => post(`/companies/${id}/users`, params),
  removeUser: (id, userId) => del(`/companies/${id}/users/${userId}`),
  updateUser: (id, userId, params) =>
    patch(`/companies/${id}/users/${userId}`, params),
  employees: id => get(`/companies/${id}/users`),

  adminCompanies: params => get('/admin/companies', params),
  adminCompany: id => get('/admin/companies/' + id),
  adminCompanyUpdate: (id, params) => patch(`/admin/companies/${id}`, params),
};
