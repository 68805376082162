import { handleActions } from 'redux-actions';
import {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  errors: [],
};

const forgotPasswordReducer = handleActions(
  {
    [resetPassword]: state => ({
      ...state,
      isFetching: true,
    }),

    [resetPasswordSuccess]: state => ({
      ...state,
      isFetching: false,
    }),

    [resetPasswordFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default forgotPasswordReducer;
