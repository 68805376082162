import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { PersonalInfo } from 'components/PersonalInfo';
import { LanguageSelector } from 'components/LanguageSelector';
import { saveFilter } from 'components/CompetenceFilter/actions';

import logo from 'assets/images/logo2.png';
import styles from './styles.module.scss';

class AdminSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeToggle: '' };
  }

  communicationToggle = () => {
    this.setState({
      activeToggle:
        this.state.activeToggle === 'communication' ? '' : 'communication',
    });
  };

  competencesToggle = () => {
    this.setState({
      activeToggle:
        this.state.activeToggle === 'competences' ? '' : 'competences',
    });
  };

  clearFilters = () => {
    this.props.dispatch(
      saveFilter({
        competences: [],
        competenceGroups: [],
        answerTypes: [],
        users: [],
        companies: [],
        isFiltered: false,
      })
    );
  };

  render() {
    const { t } = this.props;
    return (
      <aside className={styles['app-sidebar']}>
        <header>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="CheckIt Logo" />
            </Link>
          </div>
          <PersonalInfo user={this.props.user} />
        </header>
        <main>
          <Nav vertical>
            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/admin/dashboard"
                className={styles['nav-link']}
                activeClassName={styles.active}
                onClick={this.clearFilters}
              >
                <span className={styles.icon}>
                  <i className="fas fa-cogs" />
                </span>
                <span className={styles.text}>
                  {t('adminSidebar.dashboard')}
                </span>
              </NavLink>
            </NavItem>

            <NavItem
              className={`${styles['nav-item']} ${styles['nav-item-parent']}`}
            >
              <NavLink
                className={styles['nav-link']}
                activeClassName={styles.active}
                onClick={this.communicationToggle}
                to="#"
              >
                <span className={styles.icon}>
                  <i className="fas fa-comment-alt" />
                </span>
                <span className={styles.text}>
                  {t('adminSidebar.communicationPlatform')}
                </span>
                <i
                  className={`${styles['toggle-indicator']} fas ${
                    this.state.activeToggle === 'communication'
                      ? 'fa-angle-up'
                      : 'fa-angle-down'
                  }`}
                />
              </NavLink>
            </NavItem>

            <Collapse isOpen={this.state.activeToggle === 'communication'}>
              <NavItem
                className={`${styles['nav-item']} ${styles['nav-item-child']}`}
              >
                <NavLink
                  className={styles['nav-link']}
                  activeClassName={styles.active}
                  to="/admin/post-topics"
                >
                  <span className={styles.icon}>
                    <i className="fas fa-align-center" />
                  </span>
                  <span className={styles.text}>
                    {t('adminSidebar.postTopics')}
                  </span>
                </NavLink>
              </NavItem>
            </Collapse>

            <Collapse isOpen={this.state.activeToggle === 'communication'}>
              <NavItem
                className={`${styles['nav-item']} ${styles['nav-item-child']}`}
              >
                <NavLink
                  to="/admin/moderation"
                  className={styles['nav-link']}
                  activeClassName={styles.active}
                >
                  <span className={styles.icon}>
                    <i className="fas fa-exclamation" />
                  </span>
                  <span className={styles.text}>
                    {t('adminSidebar.moderation')}
                  </span>
                </NavLink>
              </NavItem>
            </Collapse>

            <NavItem className={styles['nav-item']}>
              <NavLink
                className={styles['nav-link']}
                activeClassName={styles.active}
                onClick={this.competencesToggle}
                to="#"
              >
                <span className={styles.icon}>
                  <i className="fas fa-graduation-cap" />
                </span>
                <span className={styles.text}>
                  {t('adminSidebar.competences')}
                </span>
                <i
                  className={`${styles['toggle-indicator']} fas ${
                    this.state.activeToggle === 'competences'
                      ? 'fa-angle-up'
                      : 'fa-angle-down'
                  }`}
                />
              </NavLink>
            </NavItem>

            <Collapse isOpen={this.state.activeToggle === 'competences'}>
              <NavItem
                className={`${styles['nav-item']} ${styles['nav-item-child']}`}
              >
                <NavLink
                  to="/admin/competences"
                  className={styles['nav-link']}
                  activeClassName={styles.active}
                >
                  <span className={styles.icon}>
                    <i className="fas fa-tasks" />
                  </span>
                  <span className={styles.text}>
                    {t('adminSidebar.competences')}
                  </span>
                </NavLink>
              </NavItem>
            </Collapse>

            <Collapse isOpen={this.state.activeToggle === 'competences'}>
              <NavItem
                className={`${styles['nav-item']} ${styles['nav-item-child']}`}
              >
                <NavLink
                  to="/admin/answer-types"
                  className={styles['nav-link']}
                  activeClassName={styles.active}
                >
                  <span className={styles.icon}>
                    <i className="fas fa-question" />
                  </span>
                  <span className={styles.text}>
                    {t('adminSidebar.answerTypes')}
                  </span>
                </NavLink>
              </NavItem>
            </Collapse>

            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/admin/users"
                className={styles['nav-link']}
                activeClassName={styles.active}
              >
                <span className={styles.icon}>
                  <i className="fas fa-users" />
                </span>
                <span className={styles.text}>{t('adminSidebar.users')}</span>
              </NavLink>
            </NavItem>

            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/admin/companies"
                className={styles['nav-link']}
                activeClassName={styles.active}
              >
                <span className={styles.icon}>
                  <i className="fas fa-building" />
                </span>
                <span className={styles.text}>
                  {t('adminSidebar.companies')}
                </span>
              </NavLink>
            </NavItem>

            <hr />

            <NavItem className={styles['nav-item']}>
              <NavLink to="/" className={styles['nav-link']}>
                <span className={styles.icon}>
                  <i className="fas fa-sign-out-alt" />
                </span>
                <span className={styles.text}>
                  {t('adminSidebar.backToMainPage')}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </main>
        <footer>
          <Nav vertical>
            <NavItem className={styles['nav-item']}>
              {this.props.language && <LanguageSelector />}
            </NavItem>
          </Nav>
        </footer>
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  withRouter,
  connect(mapStateToProps)
)(AdminSidebar);
