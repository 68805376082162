import { call, put, takeLatest } from 'redux-saga/effects';
import { AdminCompetences } from 'services/api';
import {
  updateCompetence,
  updateCompetenceSuccess,
  updateCompetenceFailure,
  getAdminCompetences,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';
import { clearFilter } from 'components/CompetenceFilter/actions';

function* updateCompetenceSaga({ payload }) {
  try {
    const data = yield call(
      AdminCompetences.updateCompetence,
      payload.values.id,
      {
        competence: payload.values,
      }
    );
    yield put(updateCompetenceSuccess(data));
    yield put(getAdminCompetences({ locale: payload.locale }));
    yield put(clearFilter());
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'changes' } },
      ])
    );
  } catch (e) {
    yield put(updateCompetenceFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(updateCompetence, updateCompetenceSaga);
