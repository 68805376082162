import store from 'store2';
import { call, take, put, fork, cancel } from 'redux-saga/effects';
import { Users } from '../api';
import { signIn, signInFailure, signInSuccess, logOut } from './actions';
import { selectLanguage } from 'components/LanguageSelector/actions';
import { actions as notificationActions } from 'components/Notification';

function getAuthToken() {
  return store('token');
}

function setAuthToken(token) {
  store.set('token', token);
}

function removeAuthToken() {
  store.remove('token');
}

function* authorize(user) {
  try {
    const { data } = yield call(Users.signIn, { user });
    yield call(setAuthToken, data.token);
    yield put(signInSuccess(data));
    yield put(selectLanguage());
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'signedIn' } },
      ])
    );
  } catch (e) {
    yield put(signInFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

function* validateToken() {
  try {
    const { data } = yield call(Users.profile);
    yield put(signInSuccess(data));
    yield put(selectLanguage());
  } catch (e) {
    yield call(removeAuthToken);
    yield put(signInFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

function* sessionSaga() {
  let authTask;
  while (true) {
    const token = getAuthToken();
    if (token) {
      yield fork(validateToken);
    } else {
      const { payload: user } = yield take(signIn);
      authTask = yield fork(authorize, user);
    }

    const { type } = yield take([signInFailure, logOut]);

    // eslint-disable-next-line eqeqeq
    if (type == logOut) {
      if (authTask) {
        yield cancel(authTask);
      }
      yield call(removeAuthToken);
    }
  }
}

export default sessionSaga;
