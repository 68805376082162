import { handleActions } from 'redux-actions';
import {
  getCompetences,
  getCompetencesSuccess,
  getCompetencesFailure,
  updateCompetenceAnswers,
  updateCompetenceAnswersSuccess,
  updateCompetenceAnswersFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  competences: [],
  competenceGroups: [],
  answerTypes: [],
  competenceAnswers: [],
  errors: [],
};

const competencesReducer = handleActions(
  {
    [getCompetences]: state => ({
      ...state,
      isFetching: true,
    }),

    [getCompetencesSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competences: payload.data.competences,
      competenceGroups: payload.data.competenceGroups,
      answerTypes: payload.data.answerTypes,
      competenceAnswers: payload.data.competenceAnswers,
    }),

    [getCompetencesFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
    [updateCompetenceAnswers]: state => ({
      ...state,
      isFetching: true,
    }),

    [updateCompetenceAnswersSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competenceAnswers: payload.data.competenceAnswers,
    }),

    [updateCompetenceAnswersFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default competencesReducer;
