import { combineActions, handleActions } from 'redux-actions';
import {
  adminUsers,
  adminUsersSuccess,
  adminUsersFailure,
  adminUser,
  adminUserSuccess,
  adminUserFailure,
  adminUpdateUser,
  adminUpdateUserSuccess,
  adminUpdateUserFailure,
  generatePassword,
  generatePasswordSuccess,
  generatePasswordFailure,
  sendConfirmationEmail,
  sendConfirmationEmailSuccess,
  sendConfirmationEmailFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  user: {},
  users: [],
  errors: [],
};

const answerTypesReducer = handleActions(
  {
    [combineActions(
      adminUsers,
      adminUser,
      adminUpdateUser,
      generatePassword,
      sendConfirmationEmail
    )]: state => ({
      ...state,
      isFetching: true,
    }),

    [adminUsersSuccess]: (state, { payload: users }) => ({
      ...state,
      isFetching: false,
      users: users.data,
      errors: [],
    }),

    [combineActions(adminUserSuccess, adminUpdateUserSuccess)]: (
      state,
      { payload: user }
    ) => ({
      ...state,
      isFetching: false,
      user,
      errors: [],
    }),

    [combineActions(
      generatePasswordSuccess,
      sendConfirmationEmailSuccess
    )]: state => ({
      ...state,
      isFetching: false,
      errors: [],
    }),

    [combineActions(
      adminUsersFailure,
      adminUserFailure,
      adminUpdateUserFailure,
      generatePasswordFailure,
      sendConfirmationEmailFailure
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default answerTypesReducer;
