import { get, post, put, del } from '../rest';

export default {
  getCompetences: params => get('/admin/competences', params),
  createCompetence: params => post('/admin/competences', params),
  updateCompetence: (id, params) => put('/admin/competences/' + id, params),
  deleteCompetence: id => del('/admin/competences/' + id),
  createCompetenceGroup: params => post('/admin/competence_groups', params),
  updateCompetenceGroup: (id, params) =>
    put('/admin/competence_groups/' + id, params),
  deleteCompetenceGroup: id => del('/admin/competence_groups/' + id),
  filterOptions: params => get('/admin/competences/filter_options', params),
};
