import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { POSITION_OPTIONS } from 'constants/SelectOptions';
import { FormGroup, Label, Button, Input } from 'reactstrap';
import { Field, FieldArray } from 'formik';
import { ErrorLabel } from 'components/Form';

import styles from './styles.module.scss';

class ExperienceInput extends React.Component {
  // TODO: Refactor!
  getError = (index, name) => {
    return (
      this.props.errors[this.props.name] &&
      this.props.errors[this.props.name][index] &&
      this.props.errors[this.props.name][index][name]
    );
  };

  getTouched = (index, name) => {
    return (
      this.props.touched[this.props.name] &&
      this.props.touched[this.props.name][index] &&
      this.props.touched[this.props.name][index][name]
    );
  };

  render() {
    const isThereAValue = this.props.value && this.props.value.length > 0;
    const { t } = this.props;
    return (
      <FieldArray name={this.props.name}>
        {({ push, remove }) => (
          <div className={styles['experience-select']}>
            <Label>
              {t('scenes.public.profileModal.studies.form.experiences.label')}
            </Label>
            {isThereAValue &&
              this.props.value.map((language, index) => (
                <FormGroup key={index}>
                  <div className={styles.wrapper}>
                    <div className={styles.inputs}>
                      <div className={styles['inputs-left']}>
                        <FormGroup className={styles['workplace-field']}>
                          <Input
                            tag={Field}
                            type="text"
                            name={`${this.props.name}[${index}].workplace`}
                            placeholder={t(
                              'scenes.public.profileModal.studies.form.experiences.fields.workplace'
                            )}
                            valid={Boolean(
                              this.getTouched(index, 'workplace') &&
                                !this.getError(index, 'workplace')
                            )}
                            invalid={Boolean(
                              this.getTouched(index, 'workplace') &&
                                this.getError(index, 'workplace')
                            )}
                            value={this.props.value[index].workplace}
                          />
                          {this.getTouched(index, 'workplace') &&
                            this.getError(index, 'workplace') && (
                              <ErrorLabel
                                error={this.getError(index, 'workplace')}
                              />
                            )}
                        </FormGroup>
                        <FormGroup className={styles['position-field']}>
                          <Input
                            type="select"
                            id={`${this.props.name}[${index}].position`}
                            name={`${this.props.name}[${index}].position`}
                            onChange={this.props.onSelect}
                            valid={Boolean(
                              this.getTouched(index, 'position') &&
                                !this.getError(index, 'position')
                            )}
                            invalid={Boolean(
                              this.getTouched(index, 'position') &&
                                this.getError(index, 'position')
                            )}
                            value={this.props.value[index].position}
                          >
                            <option value={''} key={'choose'}>
                              {t(
                                'scenes.public.profileModal.studies.form.experiences.fields.position.label'
                              )}
                            </option>
                            {POSITION_OPTIONS.map(option => (
                              <option
                                value={option.split('.').pop()}
                                key={option.split('.').pop()}
                              >
                                {t(option)}
                              </option>
                            ))}
                          </Input>
                          {this.getTouched(index, 'position') &&
                            this.getError(index, 'position') && (
                              <ErrorLabel
                                error={this.getError(index, 'position')}
                              />
                            )}
                        </FormGroup>
                      </div>
                      <div className={styles['inputs-right']}>
                        <FormGroup className={styles['from-field']}>
                          <Input
                            tag={Field}
                            type="number"
                            name={`${this.props.name}[${index}].from`}
                            placeholder={t(
                              'scenes.public.profileModal.studies.form.experiences.fields.from'
                            )}
                            valid={Boolean(
                              this.getTouched(index, 'from') &&
                                !this.getError(index, 'from')
                            )}
                            invalid={Boolean(
                              this.getTouched(index, 'from') &&
                                this.getError(index, 'from')
                            )}
                            value={this.props.value[index].from}
                          />
                          {this.getTouched(index, 'from') &&
                            this.getError(index, 'from') && (
                              <ErrorLabel
                                error={this.getError(index, 'from')}
                              />
                            )}
                        </FormGroup>
                        <div className={styles.minus}>
                          <i className="fas fa-minus" />
                        </div>
                        <FormGroup className={styles['to-field']}>
                          <Input
                            tag={Field}
                            type="number"
                            name={`${this.props.name}[${index}].to`}
                            placeholder={t(
                              'scenes.public.profileModal.studies.form.experiences.fields.to'
                            )}
                            valid={Boolean(
                              this.getTouched(index, 'to') &&
                                !this.getError(index, 'to')
                            )}
                            invalid={Boolean(
                              this.getTouched(index, 'to') &&
                                this.getError(index, 'to')
                            )}
                            value={this.props.value[index].to}
                          />
                          {this.getTouched(index, 'to') &&
                            this.getError(index, 'to') && (
                              <ErrorLabel error={this.getError(index, 'to')} />
                            )}
                        </FormGroup>
                      </div>
                    </div>
                    <Button
                      color="link"
                      className={styles['remove-button']}
                      onClick={() =>
                        this.props.value.length > 1 && remove(index)
                      }
                      disabled={this.props.value.length === 1}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </div>
                </FormGroup>
              ))}
            <div className={styles['add-button-wrapper']}>
              <Button
                color="link"
                className={styles['add-button']}
                onClick={() =>
                  push({ workplace: '', position: '', from: '', to: '' })
                }
              >
                <i className="fas fa-plus" />{' '}
                {t('scenes.public.profileModal.studies.form.experiences.add')}
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    );
  }
}

ExperienceInput.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
};

export default withTranslation('common')(ExperienceInput);
