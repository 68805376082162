import { call, put, takeLatest } from 'redux-saga/effects';
import { AdminCompetences } from 'services/api';
import {
  deleteCompetenceGroup,
  deleteCompetenceGroupSuccess,
  deleteCompetenceGroupFailure,
  getAdminCompetences,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';
import { clearFilter } from 'components/CompetenceFilter/actions';

function* deleteCompetenceGroupSaga({ payload }) {
  try {
    const data = yield call(AdminCompetences.deleteCompetenceGroup, payload.id);
    yield put(deleteCompetenceGroupSuccess(data));
    yield put(getAdminCompetences({ locale: payload.locale }));
    yield put(clearFilter());
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'deleted', resource: 'competenceGroup' },
        },
      ])
    );
  } catch (e) {
    yield put(deleteCompetenceGroupFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () =>
  takeLatest(deleteCompetenceGroup, deleteCompetenceGroupSaga);
