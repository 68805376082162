import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';
import { ErrorLabel } from '../ErrorLabel';
import { TextArea } from './components/TextArea';
import { Link } from 'react-router-dom';

const getFieldData = (path, object) => {
  path = path.split('.');
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);
};

const InputField = ({
  id,
  name,
  type,
  touched,
  errors,
  placeholder,
  labelText,
  className,
  errorLabel,
  value,
  options,
  onChange,
  disabled,
  validate,
  t,
  downloadPdf,
  pdf,
}) => {
  const isTouched = touched && getFieldData(name, touched);
  const error = errors && getFieldData(name, errors);

  const downloadIcon = pdf && (
    <Link
      to={pdf}
      target="_blank"
      color="link"
      style={{ paddingTop: 0, paddingLeft: 5 }}
    >
      <i className="fas fa-file-download" />
    </Link>
  );

  switch (type) {
    case 'radio':
      return (
        <FormGroup check className={className}>
          <Label check>
            <Input
              tag={Field}
              type="radio"
              id={id}
              name={name}
              onChange={onChange}
              value={id}
              checked={value === id}
              valid={Boolean(isTouched && !error)}
              invalid={Boolean(isTouched && error)}
            />
            {labelText}
          </Label>
          {errorLabel && isTouched && error && <ErrorLabel error={error} />}
        </FormGroup>
      );
    case 'checkbox':
      return (
        <FormGroup check className={className}>
          <Label check>
            <Input
              tag={Field}
              type="checkbox"
              id={name}
              name={name}
              checked={value}
              valid={Boolean(isTouched && !error)}
              invalid={Boolean(isTouched && error)}
              disabled={disabled}
            />
            {labelText}
            {downloadPdf && downloadIcon}
          </Label>
          {errorLabel && isTouched && error && <ErrorLabel error={error} />}
        </FormGroup>
      );
    case 'select':
      return (
        <FormGroup className={className}>
          {labelText && <Label for={name}>{labelText}</Label>}
          <Input
            type="select"
            id={name}
            name={name}
            onChange={onChange}
            valid={validate && Boolean(isTouched && !error)}
            invalid={validate && Boolean(isTouched && error)}
            value={value}
            disabled={disabled}
          >
            <option value={''} key={'choose'}>
              {placeholder || t('forms.labels.select')}
            </option>
            {options.map(option => (
              <option
                value={option.value || option.split('.').pop()}
                key={option.value || option.split('.').pop()}
              >
                {t(option.label) || t(option)}
              </option>
            ))}
          </Input>
          {errorLabel && isTouched && error && <ErrorLabel error={error} />}
        </FormGroup>
      );
    case 'textarea':
      return (
        <Field
          component={TextArea}
          name={name}
          placeholder={placeholder}
          valid={validate && Boolean(isTouched && !error)}
          invalid={validate && Boolean(isTouched && error)}
          label={labelText}
          disabled={disabled}
          className={className}
        />
      );
    default:
      return (
        <FormGroup className={className}>
          {labelText && <Label for={name}>{labelText}</Label>}
          <Input
            tag={Field}
            type={type}
            name={name}
            placeholder={placeholder}
            valid={validate && Boolean(isTouched && !error)}
            invalid={validate && Boolean(isTouched && error)}
            disabled={disabled}
          />
          {errorLabel && isTouched && error && <ErrorLabel error={error} />}
        </FormGroup>
      );
  }
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  className: PropTypes.string,
  errorLabel: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  downloadPdf: PropTypes.bool,
};

InputField.defaultProps = {
  touched: {},
  errors: {},
  errorLabel: false,
  validate: true,
  downloadPdf: false,
};

export default withTranslation('common')(InputField);
