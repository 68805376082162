import { createAction } from 'redux-actions';

export const comments = createAction('COMMENTS_REQUEST');
export const commentsSuccess = createAction('COMMENTS_SUCCESS');
export const commentsFailure = createAction('COMMENTS_FAILURE');

export const addComment = createAction('ADD_COMMENT_REQUEST');
export const addCommentSuccess = createAction('ADD_COMMENT_SUCCESS');
export const addCommentFailure = createAction('ADD_COMMENT_FAILURE');

export default {
  comments,
  commentsSuccess,
  commentsFailure,
  addComment,
  addCommentSuccess,
  addCommentFailure,
};
