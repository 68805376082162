import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Competence } from 'components/Competence';
import actions from './actions';
import { Spinner, Button } from 'reactstrap';
import { CompetenceFilter } from 'components/CompetenceFilter';
import { AddCompetenceGroupForm } from './components/AddCompetenceGroupForm';
import { EditCompetenceGroupForm } from './components/EditCompetenceGroupForm';
import { AddCompetenceForm } from './components/AddCompetenceForm';
import { EditCompetenceForm } from './components/EditCompetenceForm';
import { UserAnswers } from './components/UserAnswers';
import { ConfirmModal } from 'components/ConfirmModal';

import styles from './styles.module.scss';

class AdminCompetences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter.isFiltered || false,
      addCompetenceGroup: false,
      editCompetenceGroup: null,
      addCompetence: null,
      editCompetence: null,
      locale: props.language,
      openDelete: false,
      forDelete: '',
      forDeleteType: '',
    };
  }

  componentDidMount() {
    this.props.language &&
      (this.props.competencesData && this.props.competencesData.length === 0) &&
      this.props.dispatch(
        actions.getAdminCompetences({ locale: this.props.language })
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.language !== this.props.language &&
      this.props.language !== ''
    ) {
      this.props.dispatch(
        actions.getAdminCompetences({ locale: this.props.language })
      );
    }
  }

  handleSearch = isFiltered => {
    this.setState({ filter: isFiltered });
  };

  toggleAddCompetenceGroupForm = () => {
    this.setState({ addCompetenceGroup: !this.state.addCompetenceGroup });
  };

  handleAddCompetenceGroup = values => {
    this.props.dispatch(
      actions.createCompetenceGroup({
        values: values,
        locale: this.props.language,
      })
    );
    this.setState({ addCompetenceGroup: null });
  };

  toggleEditCompetenceGroupForm = id => {
    this.setState({
      editCompetenceGroup: this.state.editCompetenceGroup === id ? null : id,
    });
  };

  handleEditCompetenceGroup = values => {
    this.props.dispatch(
      actions.updateCompetenceGroup({
        values: values,
        locale: this.props.language,
      })
    );
    this.setState({ editCompetenceGroup: null });
  };

  handleCompetenceGroupDelete = id => {
    this.props.dispatch(
      actions.deleteCompetenceGroup({
        id: id,
        locale: this.props.language,
      })
    );
  };

  toggleAddCompetenceForm = id => {
    this.setState({
      addCompetence: this.state.addCompetence === id ? null : id,
    });
  };

  handleCompetenceCreate = values => {
    this.props.dispatch(
      actions.createCompetence({
        values: values,
        locale: this.props.language,
      })
    );
    this.setState({ addCompetence: null });
  };

  toggleEditCompetenceForm = id => {
    this.setState({
      editCompetence: this.state.editCompetence === id ? null : id,
    });
  };

  handleCompetenceEdit = values => {
    this.props.dispatch(
      actions.updateCompetence({
        values: values,
        locale: this.props.language,
      })
    );
    this.setState({ editCompetence: null });
  };

  handleCompetenceDelete = id => {
    this.props.dispatch(
      actions.deleteCompetence({
        id: id,
        locale: this.props.language,
      })
    );
  };

  toggleDelete = (item, type) => {
    this.setState({
      forDelete: item,
      forDeleteType: type,
      openDelete: !this.state.openDelete,
    });
  };

  handleDelete = () => {
    if (this.state.forDeleteType === 'competence') {
      this.handleCompetenceDelete(this.state.forDelete.id);
    }

    if (this.state.forDeleteType === 'competenceGroup') {
      this.handleCompetenceGroupDelete(this.state.forDelete.id);
    }

    this.toggleDelete('', '');
  };

  render() {
    const { competencesData, answerTypes, isFetching, t } = this.props;
    return (
      <div>
        <h1>{t('scenes.admin.competences.title')}</h1>
        <CompetenceFilter onSubmit={this.handleSearch} />

        {!isFetching ? (
          competencesData && competencesData.length > 0 ? (
            competencesData.map(({ competenceGroup, competences }) => (
              <div key={competenceGroup.id} className={styles.competences}>
                {this.state.editCompetenceGroup === competenceGroup.id ? (
                  <EditCompetenceGroupForm
                    competenceGroup={competenceGroup}
                    onSubmit={this.handleEditCompetenceGroup}
                    onCancel={() =>
                      this.toggleEditCompetenceGroupForm(competenceGroup.id)
                    }
                  />
                ) : (
                  <div className={styles['competence-group']}>
                    <h4 className={styles.name}>{competenceGroup.name}</h4>
                    <Button
                      color="link"
                      className={styles.button + ' ' + styles.secondary}
                      onClick={() =>
                        this.toggleEditCompetenceGroupForm(competenceGroup.id)
                      }
                    >
                      <i className="fas fa-pen" />
                    </Button>
                    <Button
                      color="link"
                      className={styles.button + ' ' + styles.danger}
                      onClick={() =>
                        this.toggleDelete(competenceGroup, 'competenceGroup')
                      }
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </div>
                )}

                {competences.map(competence => (
                  <div key={competence.id}>
                    <Competence
                      competence={competence}
                      answerTypes={answerTypes}
                      disabled
                      admin
                      togglerId={'toggler_' + competence.id}
                      onCompetenceEdit={this.toggleEditCompetenceForm}
                      onCompetenceDelete={this.toggleDelete}
                    />
                    <div className={styles['user-answers-wrapper']}>
                      <UserAnswers
                        competenceId={competence.id}
                        competenceAnswers={competence.competenceAnswers}
                      />
                    </div>
                    {this.state.editCompetence === competence.id && (
                      <EditCompetenceForm
                        competence={competence}
                        onSubmit={this.handleCompetenceEdit}
                        onCancel={() =>
                          this.toggleEditCompetenceForm(competence.id)
                        }
                      />
                    )}
                  </div>
                ))}

                {this.state.addCompetence === competenceGroup.id ? (
                  <AddCompetenceForm
                    competenceGroup={competenceGroup.id}
                    answerTypes={answerTypes}
                    onSubmit={this.handleCompetenceCreate}
                    onCancel={() =>
                      this.toggleAddCompetenceForm(competenceGroup.id)
                    }
                  />
                ) : (
                  <div className={styles['add-button']}>
                    <Button
                      color="link"
                      className={styles['remove-button']}
                      onClick={() =>
                        this.toggleAddCompetenceForm(competenceGroup.id)
                      }
                    >
                      <i className="fas fa-plus" />{' '}
                      {t('scenes.admin.competences.newCompetence')}
                    </Button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div>{t('scenes.admin.competences.noResults')}</div>
          )
        ) : (
          <Spinner color="primary" />
        )}

        {this.state.addCompetenceGroup && (
          <AddCompetenceGroupForm
            onSubmit={this.handleAddCompetenceGroup}
            onCancel={this.toggleAddCompetenceGroupForm}
          />
        )}
        {!this.state.addCompetenceGroup && (
          <div className={styles['add-button']}>
            <Button
              color="link"
              className={styles['remove-button']}
              onClick={this.toggleAddCompetenceGroupForm}
            >
              <h5>
                <i className="fas fa-plus" />{' '}
                {t('scenes.admin.competences.newCompetenceGroup')}
              </h5>
            </Button>
          </div>
        )}
        <ConfirmModal
          modalOpen={this.state.openDelete}
          toggleModal={this.toggleDelete}
          primaryButtonText={t('confirmationModal.submit')}
          onModalConfirm={this.handleDelete}
        >
          <i className={`far fa-times-circle ${styles['modal-icon']}`} />
          <h3>{t('confirmationModal.title')}</h3>
          <p>
            {t('confirmationModal.text', {
              name: this.state.forDelete && this.state.forDelete.name,
              resource:
                this.state.forDeleteType !== '' &&
                t(
                  `scenes.admin.competences.resources.${
                    this.state.forDeleteType
                  }`
                ),
            })}
          </p>
        </ConfirmModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.adminCompetences.isFetching,
  apiErrors: state.adminCompetences.errors,
  competencesData: state.adminCompetences.competences,
  answerTypes: state.adminCompetences.answerTypes,
  filter: state.competenceFilter.filter,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(AdminCompetences);
