import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const PersonalInfo = ({ user }) => {
  function image() {
    if (user.avatarUrl === '') {
      return (
        <span className={styles.icon}>
          <i className="fas fa-user" />
        </span>
      );
    }
    return <img src={user.avatarUrl} alt="Avatar" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles['image-wrapper']}>
        <div className={styles.content}>{image()}</div>
      </div>
      <span className={styles.name}>
        {user.firstName + ' ' + user.lastName}
      </span>
    </div>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PersonalInfo;
