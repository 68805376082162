import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink as ReactstrapNavLink } from 'reactstrap';
import { actions as sessionActions } from 'services/session';
import { PersonalInfo } from 'components/PersonalInfo';
import { LanguageSelector } from 'components/LanguageSelector';
import { ProfileModal } from 'components/ProfileModal';
import { PostModal } from 'components/PostModal';
import { actions as postActions } from 'components/PostModal';

import logo from 'assets/images/logo2.png';
import styles from './styles.module.scss';

class AppSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openProfileModal: false,
      openNewPostModal: false,
    };
  }

  toggleProfileModal = () => {
    this.setState({ openProfileModal: !this.state.openProfileModal });
  };

  toggleNewPostModal = () => {
    !this.state.openNewPostModal &&
      this.props.dispatch(postActions.getPostTopics());
    this.setState({ openNewPostModal: !this.state.openNewPostModal });
  };

  render() {
    const { t } = this.props;
    return (
      <aside className={styles['app-sidebar']}>
        <header>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="CheckIt Logo" />
            </Link>
          </div>
          <PersonalInfo user={this.props.user} />
        </header>
        <main>
          <Nav vertical>
            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/social"
                className={styles['nav-link']}
                activeClassName={styles.active}
              >
                <span className={styles.icon}>
                  <i className="fas fa-newspaper" />
                </span>
                <span className={styles.text}>{t('sidebar.posts')}</span>
              </NavLink>
            </NavItem>

            <NavItem className={styles['nav-item']}>
              <ReactstrapNavLink
                className={styles['nav-link']}
                onClick={this.toggleProfileModal}
              >
                <span className={styles.icon}>
                  <i className="fas fa-user" />
                </span>
                <span className={styles.text}>{t('sidebar.profile')}</span>
              </ReactstrapNavLink>
            </NavItem>

            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/competences"
                className={styles['nav-link']}
                activeClassName={styles.active}
              >
                <span className={styles.icon}>
                  <i className="fas fa-tasks" />
                </span>
                <span className={styles.text}>{t('sidebar.competences')}</span>
              </NavLink>
            </NavItem>

            <NavItem className={styles['nav-item']}>
              <NavLink
                to="/"
                className={styles['nav-link']}
                onClick={() => this.props.dispatch(sessionActions.logOut())}
              >
                <span className={styles.icon}>
                  <i className="fas fa-sign-out-alt" />
                </span>
                <span className={styles.text}>{t('sidebar.logout')}</span>
              </NavLink>
            </NavItem>

            {this.props.user.admin && <hr />}
            {this.props.user.admin && (
              <NavItem className={styles['nav-item']}>
                <NavLink
                  className={styles['nav-link']}
                  activeClassName={styles.active}
                  to="/admin"
                >
                  <span className={styles.icon}>
                    <i className="fas fa-cogs" />
                  </span>
                  <span className={styles.text}>{t('sidebar.admin')}</span>
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <ProfileModal
            modalOpen={this.state.openProfileModal}
            toggleModal={this.toggleProfileModal}
          />
        </main>
        <footer>
          <Nav vertical>
            <NavItem className={styles['nav-item']}>
              <ReactstrapNavLink
                className={styles['nav-link']}
                onClick={this.toggleNewPostModal}
              >
                <span className={styles.icon}>
                  <i className="fas fa-plus" />
                </span>
                <span className={styles.text}>{t('sidebar.newPost')}</span>
              </ReactstrapNavLink>
            </NavItem>

            <NavItem className={styles['nav-item']}>
              {this.props.language && <LanguageSelector />}
            </NavItem>
          </Nav>

          <PostModal
            openNewPostModal={this.state.openNewPostModal}
            toggleNewPostModal={this.toggleNewPostModal}
          />
        </footer>
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  withRouter,
  connect(mapStateToProps)
)(AppSidebar);
