import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import {
  LEGAL_FORM_OPTIONS,
  POSITION_OPTIONS,
  validationOptions,
} from 'constants/SelectOptions';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';
import { Users } from 'services/api';

import styles from './styles.module.scss';

const initialValues = {
  name: '',
  email: '',
  website: '',
  legalForm: '',
  position: '',
  logo: undefined,
  checkEmail: false,
};

const validationSchema = Yup.object({
  checkEmail: Yup.boolean(),
  name: Yup.string().required('required'),
  email: Yup.string()
    .required('required')
    .email('email.invalid')
    .when('checkEmail', {
      is: true,
      then: Yup.string().test('free-email', 'email.taken', value => {
        if (typeof value !== 'undefined') {
          return Users.checkEmail({
            email: value,
            type: 'company',
          })
            .then(({ data: { isOk } }) => isOk)
            .catch(false);
        }
      }),
    }),
  website: Yup.string()
    .matches(/[.]/, 'Error')
    .required('required'),
  legalForm: Yup.string()
    .required('required')
    .oneOf(validationOptions(LEGAL_FORM_OPTIONS), 'selectOption'),
  position: Yup.string()
    .oneOf(validationOptions(POSITION_OPTIONS), 'selectOption')
    .when('checkEmail', {
      is: true,
      then: Yup.string().required('required'),
    }),
  logo: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});
class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: (props.company && props.company.logoUrl) || '',
    };
  }

  componentDidMount() {
    if (!Boolean(this.props.company)) {
      this.props.setFieldValue('checkEmail', true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.resetForm();
    }
  }

  handleImageSelect = e => {
    this.props.setFieldValue('logo', e.target.files[0]);
    this.props.setFieldTouched('logo');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('logo', '');
    this.setState({
      previewImage: '',
    });
  };

  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <div className={styles['registration-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            <div className={styles['left-side']}>
              <InputField
                name="name"
                type="string"
                labelText={t('scenes.admin.companies.form.name')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="email"
                type="email"
                labelText={t('scenes.admin.companies.form.email')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="website"
                type="string"
                labelText={t('scenes.admin.companies.form.website')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="legalForm"
                type="select"
                labelText={t('scenes.admin.companies.form.legalForm.label')}
                errors={errors}
                touched={touched}
                options={LEGAL_FORM_OPTIONS}
                onChange={this.handleSelect}
                className={styles.input}
                value={values.legalForm}
                errorLabel
              />
              {Boolean(this.props.company) ? (
                <FormGroup className={styles.input}>
                  <Label>
                    {t('scenes.admin.companies.form.employees.label')}
                  </Label>
                  <Button
                    onClick={this.props.onPageChange}
                    color="secondary"
                    block
                  >
                    {t('scenes.admin.companies.form.employees.button')}
                  </Button>
                </FormGroup>
              ) : (
                <InputField
                  name="position"
                  type="select"
                  labelText="Position of the person registering"
                  errors={errors}
                  touched={touched}
                  options={POSITION_OPTIONS}
                  onChange={this.handleSelect}
                  className={styles.input}
                  errorLabel
                />
              )}
            </div>
            <div className={styles['right-side']}>
              <ImageUpload
                name="logo"
                type="file"
                onChange={this.handleImageSelect}
                onClose={this.handleImageCancel}
                previewImage={this.state.previewImage}
                errorMessage={errors.logo}
              />
              <div className={styles['name-preview']}>{values.name}</div>
            </div>
          </div>
          <footer>
            <FormGroup>
              <Link to="/admin/companies">
                <Button color="secondary" outline>
                  {t('scenes.admin.companies.form.cancel')}
                </Button>
              </Link>
              <Button type="submit" color="primary" disabled={isFetching}>
                {t('scenes.admin.companies.form.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
      </div>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => props.company || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.avatar === 'undefined') {
        delete values.avatar;
      }
      delete values.avatarUrl;

      formikBag.props.onSubmit(values);
    },

    displayName: 'AdminUserEditForm',
  })
)(CompanyForm);
