import { call, put, takeLatest } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  adminUpdateUser,
  adminUpdateUserSuccess,
  adminUpdateUserFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function convertKey(key) {
  return key.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

function* updateUser({ payload }) {
  try {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(`user[${convertKey(key)}]`, value);
    });
    const { data } = yield call(Users.adminUserUpdate, payload.id, formData);
    yield put(adminUpdateUserSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'changes' } },
      ])
    );
  } catch (e) {
    yield put(adminUpdateUserFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(adminUpdateUser, updateUser);
