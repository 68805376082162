import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  addComment,
  addCommentSuccess,
  addCommentFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* addCommentSaga({ payload }) {
  try {
    const { data } = yield call(Posts.addComment, payload.postId, {
      comment: payload.comment,
    });
    yield put(addCommentSuccess(data));
  } catch (e) {
    yield put(addCommentFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(addComment, addCommentSaga);
