import { take, put, call } from 'redux-saga/effects';
import { Dashboard } from 'services/api';
import {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
} from './actions';

function* dashboardSaga() {
  while (true) {
    const { payload } = yield take(getDashboard);
    try {
      const { data } = yield call(Dashboard.index, payload);
      yield put(getDashboardSuccess(data));
    } catch (e) {
      yield put(getDashboardFailure(e.data));
    }
  }
}

export default dashboardSaga;
