import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormGroup, Button, Label } from 'reactstrap';

import styles from './styles.module.scss';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
  }

  handleClick = () => {
    this.fileInput.current.click();
  };

  render() {
    const {
      name,
      className,
      labelText,
      selected,
      onDeleteSelected,
      t,
      tReady,
      disabled,
      ...rest
    } = this.props;
    return (
      <FormGroup className={`${styles['file-upload-wrapper']} ${className}`}>
        {labelText && <Label for={name}>{labelText}</Label>}
        <input
          type="file"
          name={name}
          ref={this.fileInput}
          style={{ display: 'none' }}
          disabled={disabled}
          {...rest}
        />
        <Button
          onClick={this.handleClick}
          color="secondary"
          className={styles.button}
          disabled={disabled}
        >
          <i className="far fa-file" /> {t('forms.labels.file')}
        </Button>
        {selected && selected.length > 0 && (
          <ul className={styles.selected}>
            {selected.map((file, index) => (
              <li key={index}>
                <span className={styles.name}>{file}</span>
                {!disabled && (
                  <Button
                    onClick={() => onDeleteSelected(index)}
                    className={styles.delete}
                  >
                    <i className="fas fa-times" />
                  </Button>
                )}
              </li>
            ))}
          </ul>
        )}
      </FormGroup>
    );
  }
}

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.array,
  onDeleteSelected: PropTypes.func,
  disabled: PropTypes.bool,
};

FileUpload.defaultProps = {
  disabled: false,
};

export default withTranslation('common')(FileUpload);
