import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Table, UncontrolledCollapse } from 'reactstrap';

import styles from './styles.module.scss';

const UserSubscriptions = ({ postId, subscriptions, t }) => (
  <div className={styles['user-answers']}>
    <UncontrolledCollapse toggler={'#toggler_' + postId}>
      <Table hover responsive bordered className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <th className={styles.name}>{t('scenes.public.social.name')}</th>
            <th className={styles.email}>{t('scenes.public.social.email')}</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map(subscription => (
              <tr className={styles.row} key={subscription.id}>
                <td className={styles.name}>{subscription.name}</td>
                <td className={styles.email}>{subscription.email}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </UncontrolledCollapse>
  </div>
);

UserSubscriptions.propTypes = {
  postId: PropTypes.number.isRequired,
  subscriptions: PropTypes.array.isRequired,
};

UserSubscriptions.defaultProps = {
  subscriptions: [],
};

export default withTranslation('common')(UserSubscriptions);
