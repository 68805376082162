import React from 'react';
import { connect } from 'react-redux';
import { CompanyForm } from './components/CompanyForm';
import { ManageEmployees } from 'components/ManageEmployees';

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sessionUser.companyAdmin &&
      !this.props.sessionUser.companyAdmin &&
      this.props.sessionUser.companyAdmin === false
    ) {
      this.setState({ form: true });
    }
  }

  handlePageChange = () => {
    this.setState({ form: !this.state.form });
  };

  render() {
    return this.state.form ? (
      <CompanyForm
        company={this.props.company}
        companyAdmin={this.props.sessionUser.companyAdmin}
        isFetching={this.props.isFetching}
        apiErrors={this.props.apiErrors}
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        onManageEmployees={this.handlePageChange}
      />
    ) : (
      !this.state.disabled && (
        <ManageEmployees
          company={this.props.company}
          companyAdmin={this.props.sessionUser.companyAdmin}
          onCancel={this.handlePageChange}
          onUpdate={this.checkPermission}
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companies.companies,
  sessionUser: state.session.user,
});

export default connect(mapStateToProps)(Company);
