import { call, put, takeLatest } from 'redux-saga/effects';
import { Competences } from 'services/api';
import {
  getCompetences,
  getCompetencesSuccess,
  getCompetencesFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getCompetencesSaga({ payload }) {
  try {
    const data = yield call(Competences.getCompetences, { locale: payload });
    yield put(getCompetencesSuccess(data));
  } catch (e) {
    yield put(getCompetencesFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(getCompetences, getCompetencesSaga);
