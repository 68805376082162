import { all } from 'redux-saga/effects';
import getFilterOptionsSaga from './watchers/getFilterOptions';
import saveFilterSaga from './watchers/saveFilter';
import clearFilterSaga from './watchers/clearFilter';

function* competenceFilterSaga() {
  yield all([getFilterOptionsSaga(), saveFilterSaga(), clearFilterSaga()]);
}

export default competenceFilterSaga;
