import store from 'store2';
import qs from 'query-string';
import { actions as sessionActions } from 'services/session';
import { actions as notificationActions } from 'components/Notification';
import { store as reduxStore } from 'store';

// Endpoint prefix that is proxied to the API
const PREFIX = '/api';

// Basic headers
const HEADERS = {
  Accept: 'application/json',
};

// Default opts for setting headers
const HEADER_OPTS = {
  isContentJson: true,
  isCors: false,
  sendAuth: true,
};

// Array of status codes that do not come back with a response body either by standard or convention
// This is checked so that the response body is not tried to be parsed as JSON at all
const EMPTY_RESPONSES = [204];

const _getUrl = url => (url.startsWith('http') ? url : `${PREFIX}${url}`);

const _getQueryParams = params => {
  if (typeof params !== 'object') {
    throw new Error(`Expected type Object for 'params', got ${typeof params}`);
  }

  if (Object.keys(params).length === 0) {
    return '';
  }

  return `?${qs.stringify(params, { arrayFormat: 'bracket' })}`;
};

const _getHeaders = opts => {
  // Provide default opts values
  // XXX: We are not mutating the opts here, it just holds a reference!
  opts = Object.assign({}, HEADER_OPTS, opts);

  // Add headers based on opts
  // XXX: Do not mutate the HEADERS const, create a new object!
  const headers = Object.assign({}, HEADERS, {
    'Content-Type': opts.isContentJson && 'application/json',
    // 'Access-Control-Allow-Origin': opts.isCors && '*',
    // XXX: Normally should not check `opts.isCors` for Authorization,
    // but restcountries blocks requests with such header
    Authorization:
      store.has('token') && opts.sendAuth && `Bearer ${store.get('token')}`,
  });

  // Since the above step might assign falsy props, we have to remove them
  for (const header in headers) {
    if (headers.hasOwnProperty(header) && !headers[header]) {
      delete headers[header];
    }
  }

  return headers;
};

const _fetch = async (url, opts, file) => {
  if (file) {
    // TODO: Handle errors
    fetch(url, opts)
      .then(response => response.blob())
      .then(blob => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `${file[0]}-${Date.now()}.${file[1]}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  } else {
    const response = await fetch(url, opts);

    let data = {};

    if (!EMPTY_RESPONSES.includes(response.status)) {
      try {
        data = await response.json();
      } catch (e) {
        console.warn(`Received empty/invalid ${response.status} response`);
      }
    }

    // TODO: Handle 40X and 50X errors properly
    if (response.ok) {
      return {
        status: response.status,
        data,
      };
    } else {
      if (response.status === 401) {
        if (typeof reduxStore.getState().session.user.id === 'undefined') {
          reduxStore.dispatch(
            notificationActions.addNotification(
              data.errors.map(error => ({ type: 'error', message: error.code }))
            )
          );
        }
        reduxStore.dispatch(sessionActions.logOut());
      }

      const error = new Error(
        `Fetch returned ${response.status} for ${opts.method} ${response.url}`
      );

      Object.assign(error, {
        isFetch: true,
        status: response.status,
        data,
      });

      throw error;
    }
  }
};

export async function get(url, queryParams = {}, download = false) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'GET',
    headers: _getHeaders({ isContentJson: false }),
  };

  if (download) {
    const FILE = url
      .split('/')
      .pop()
      .split('.');
    return _fetch(URL, OPTS, FILE);
  } else {
    return _fetch(URL, OPTS);
  }
}

export async function post(url, payload = null) {
  if (payload === null) {
    throw new Error('Can not send POST request with empty payload');
  }

  const isFormPayload = payload instanceof FormData;
  const URL = _getUrl(url);

  const OPTS = {
    method: 'POST',
    // Do not set/overwrite the Content-Type header if we are about to send a multipart request
    headers: _getHeaders({ isContentJson: !isFormPayload }),
    // Multipart body can be passed directly to fetch, Content-Type will be se automatically
    body: isFormPayload ? payload : JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function patch(url, payload = null) {
  if (payload === null) {
    throw new Error('Can not send POST request with empty payload');
  }

  const isFormPayload = payload instanceof FormData;
  const URL = _getUrl(url);

  const OPTS = {
    method: 'PATCH',
    headers: _getHeaders({ isContentJson: !isFormPayload }),
    body: isFormPayload ? payload : JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function put(url, payload = null) {
  if (
    payload === null ||
    (typeof payload === 'object' && !Object.keys(payload).length)
  ) {
    throw new Error('Can not send PATCH request with empty payload');
  }

  const URL = _getUrl(url);

  const OPTS = {
    method: 'PUT',
    headers: _getHeaders(),
    body: JSON.stringify(payload),
  };

  return _fetch(URL, OPTS);
}

export async function del(url, queryParams = {}) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'DELETE',
    headers: _getHeaders({ isContentJson: false }),
  };

  return _fetch(URL, OPTS);
}

export async function corsGet(url, queryParams = {}, headerOpts = {}) {
  if (typeof queryParams !== 'object') {
    throw new Error(
      `Expected type Object for 'queryParams', got ${typeof queryParams}`
    );
  }

  const URL = _getUrl(url) + _getQueryParams(queryParams);

  const OPTS = {
    method: 'GET',
    // Make sure to pass opts last here to override their passed equvivalent
    headers: _getHeaders({ ...headerOpts, isContentJson: false, isCors: true }),
  };

  return _fetch(URL, OPTS);
}
