import React from 'react';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

class Error404 extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <span className={styles.icon}>
          <i className="fas fa-bug" />
        </span>
        <span className={styles.text}>ERROR</span>
        <span className={styles.number}>404</span>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Error404);
