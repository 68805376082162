import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

function checkSession(session) {
  return Boolean(Object.keys(session.user).length);
}

const PublicRoute = ({ component: Component, session, location, ...rest }) => {
  let { from } = (location && location.state) || {
    from: { pathname: '/' },
  };

  return (
    <Route
      {...rest}
      render={props =>
        checkSession(session) ? (
          <Redirect to={from} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

export default withRouter(connect(mapStateToProps)(PublicRoute));
