import { handleActions } from 'redux-actions';
import {
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  errors: [],
};

const profileReducer = handleActions(
  {
    [updateUserProfile]: state => ({
      ...state,
      isFetching: true,
      errors: [],
    }),

    [updateUserProfileSuccess]: (state, _payload) => ({
      ...state,
      isFetching: false,
      errors: [],
    }),

    [updateUserProfileFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload && payload.errors,
    }),
  },
  INITIAL_STATE
);

export default profileReducer;
