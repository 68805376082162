import { call, take, put } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from './actions';
import { actions as notificationActions } from 'components/Notification';

function* forgotPasswordSaga() {
  while (true) {
    const { payload } = yield take(forgotPassword);
    try {
      const data = yield call(Users.newPassword, {
        user: payload.values,
        locale: payload.locale,
      });
      yield put(forgotPasswordSuccess(data));
      yield put(
        notificationActions.addNotification([
          { type: 'success', message: { code: 'passwordRequested' } },
        ])
      );
    } catch (e) {
      yield put(forgotPasswordFailure(e.data));
      yield put(
        notificationActions.addNotification(
          e.data.errors.map(error => ({ type: 'error', message: error.code }))
        )
      );
    }
  }
}

export default forgotPasswordSaga;
