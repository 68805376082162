import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import { InputField } from 'components/Form';
import { ProgrammeLogos } from 'components/ProgrammeLogos';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { actions as sessionActions } from 'services/session';

import styles from './styles.module.scss';
import logo from 'assets/images/logo2.png';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('email.invalid')
    .required('required'),
  password: Yup.string()
    .min(6)
    .required(),
});

class SignIn extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.resetForm();
    }
  }

  render() {
    const { isFetching, touched, errors, t, language } = this.props;
    return (
      <article className="public">
        <aside className={styles['welcome-image']}>
          <div className={styles.blur} />
          <div className={styles['image-wrapper']}>
            <img src={logo} alt="Check It Logo" />
          </div>
          <div className={styles.footer}>
            <ProgrammeLogos language={language} />
            <p className={styles.disclaimer}>{t('footer.disclaimer')}</p>
          </div>
        </aside>
        <section className={styles['login-wrapper']}>
          <div className={styles['login-form']}>
            <h2 className={styles.title}>{t('scenes.public.login.title')}</h2>
            <Form className="form">
              <InputField
                name="email"
                type="email"
                placeholder={t('scenes.public.login.form.email')}
                errors={errors}
                touched={touched}
              />
              <InputField
                name="password"
                type="password"
                placeholder={t('scenes.public.login.form.password')}
                errors={errors}
                touched={touched}
              />
              <FormGroup className={styles['forgot-your-password']}>
                <Link to="/forgot-password">
                  {t('scenes.public.login.forgotPassword')}
                </Link>
              </FormGroup>
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isFetching}
                  block
                >
                  {t('scenes.public.login.form.login')}
                </Button>
              </FormGroup>
            </Form>
          </div>

          <div className={styles['register-button']}>
            <Link to="/registration">
              <Button color="secondary" block>
                {t('scenes.public.login.form.registration')}
              </Button>
            </Link>
          </div>
        </section>
      </article>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.session.isFetching,
  apiErrors: state.session.errors,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.dispatch(sessionActions.signIn(values));
    },

    displayName: 'SignInFrom',
  })
)(SignIn);
