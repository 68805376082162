import { call, take, put } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  confirmation,
  confirmationSuccess,
  confirmationFailure,
} from './actions';
import { actions as notificationActions } from 'components/Notification';

function* confirmationSaga() {
  while (true) {
    const { payload: token } = yield take(confirmation);
    try {
      const data = yield call(Users.confirmation, { token });
      yield put(confirmationSuccess(data));
      yield put(
        notificationActions.addNotification([
          {
            type: 'success',
            message: { code: 'confirmed', resource: 'profile' },
          },
        ])
      );
    } catch (e) {
      yield put(confirmationFailure(e.data));
      yield put(
        notificationActions.addNotification(
          e.data.errors.map(error => ({ type: 'error', message: error.code }))
        )
      );
    }
  }
}

export default confirmationSaga;
