import { combineActions, handleActions } from 'redux-actions';
import {
  getAdminPosts,
  getAdminPostsSuccess,
  getAdminPostsFailure,
  acceptPost,
  acceptPostSuccess,
  acceptPostFailure,
  declinePost,
  declinePostSuccess,
  declinePostFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  posts: [],
  errors: [],
};

const moderationReducer = handleActions(
  {
    [combineActions(getAdminPosts, acceptPost, declinePost)]: state => ({
      ...state,
      isFetching: true,
    }),

    [combineActions(
      getAdminPostsSuccess,
      acceptPostSuccess,
      declinePostSuccess
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      posts: payload.data.posts,
    }),

    [combineActions(
      getAdminPostsFailure,
      acceptPostFailure,
      declinePostFailure
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default moderationReducer;
