import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

function checkSession(session, checkAdmin) {
  return checkAdmin
    ? Boolean(Object.keys(session.user).length) && session.user.admin
    : Boolean(Object.keys(session.user).length);
}

const AuthorizedRoute = ({
  component: Component,
  session,
  checkAdmin = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      checkSession(session, checkAdmin) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

export default withRouter(connect(mapStateToProps)(AuthorizedRoute));
