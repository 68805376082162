import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Sidebar } from 'components/Sidebar';
import * as RegistrationActions from './actions';

import { Personal } from './components/Personal';
import { Company } from './components/Company';

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personalData: {},
      registerCompany: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.history.push('/login');
    }
  }

  setPersonalData = personalValues => {
    if (personalValues.registerCompany) {
      this.setState({
        personalData: personalValues,
        registerCompany: personalValues.registerCompany,
      });
    } else {
      this.props.dispatch(
        RegistrationActions.registration({
          values: personalValues,
          locale: this.props.language,
        })
      );
    }
  };

  handleSubmit = companyData => {
    this.props.dispatch(
      RegistrationActions.registration({
        values: {
          ...this.state.personalData,
          ...companyData,
        },
        locale: this.props.language,
      })
    );
  };

  handleBackClick = () => {
    this.setState({ registerCompany: false });
  };

  render() {
    const { apiErrors, isFetching, touched, errors, values, t } = this.props;
    return (
      <article className="public">
        <Sidebar
          name="registration"
          titleText={t(
            `scenes.public.registration.${
              this.state.registerCompany ? 'company' : 'personal'
            }.sidebar.title`
          )}
          subtitleText={t(
            `scenes.public.registration.${
              this.state.registerCompany ? 'company' : 'personal'
            }.sidebar.subtitle`
          )}
        />
        {this.state.registerCompany ? (
          <Company
            apiErrors={apiErrors}
            isFetching={isFetching}
            touched={touched}
            errors={errors}
            values={values}
            onSubmit={this.handleSubmit}
            onBackClick={this.handleBackClick}
            companies={this.props.companies}
          />
        ) : (
          <Personal
            apiErrors={apiErrors}
            isFetching={isFetching}
            touched={touched}
            errors={errors}
            values={values}
            personalData={this.state.personalData}
            language={this.props.language}
            onSubmit={this.setPersonalData}
          />
        )}
      </article>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.registration.isFetching,
  apiErrors: state.registration.errors,
  companies: state.companies.companies,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Registration);
