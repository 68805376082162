import { handleActions } from 'redux-actions';
import {
  getAdminCompetences,
  getAdminCompetencesSuccess,
  getAdminCompetencesFailure,
  createCompetenceGroup,
  createCompetenceGroupSuccess,
  createCompetenceGroupFailure,
  updateCompetenceGroup,
  updateCompetenceGroupSuccess,
  updateCompetenceGroupFailure,
  deleteCompetenceGroup,
  deleteCompetenceGroupSuccess,
  deleteCompetenceGroupFailure,
  createCompetence,
  createCompetenceSuccess,
  createCompetenceFailure,
  updateCompetence,
  updateCompetenceSuccess,
  updateCompetenceFailure,
  deleteCompetence,
  deleteCompetenceSuccess,
  deleteCompetenceFailure,
} from './actions';

const INITIAL_STATE = {
  competences: [],
  answerTypes: [],
  isFetching: false,
  errors: [],
};

const adminCompetencesReducer = handleActions(
  {
    [getAdminCompetences]: state => ({
      ...state,
      isFetching: true,
    }),

    [getAdminCompetencesSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competences: payload.data,
      answerTypes: payload.meta.answerTypes,
    }),

    [getAdminCompetencesFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [createCompetenceGroup]: state => ({
      ...state,
      isFetching: true,
    }),

    [createCompetenceGroupSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competenceGroups: payload.data.competenceGroups,
    }),

    [createCompetenceGroupFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [updateCompetenceGroup]: state => ({
      ...state,
      isFetching: true,
    }),

    [updateCompetenceGroupSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competenceGroups: payload.data.competenceGroups,
    }),

    [updateCompetenceGroupFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [deleteCompetenceGroup]: state => ({
      ...state,
      isFetching: true,
    }),

    [deleteCompetenceGroupSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competenceGroups: payload.data.competenceGroups,
    }),

    [deleteCompetenceGroupFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [createCompetence]: state => ({
      ...state,
      isFetching: true,
    }),

    [createCompetenceSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competences: payload.data.competences,
      result: payload.data.result,
    }),

    [createCompetenceFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [updateCompetence]: state => ({
      ...state,
      isFetching: true,
    }),

    [updateCompetenceSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competences: payload.data.competences,
      result: payload.data.result,
    }),

    [updateCompetenceFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [deleteCompetence]: state => ({
      ...state,
      isFetching: true,
    }),

    [deleteCompetenceSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      competences: payload.data.competences,
      result: payload.data.result,
    }),

    [deleteCompetenceFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default adminCompetencesReducer;
