import { createAction } from 'redux-actions';

export const resetPassword = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');

export default {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
};
