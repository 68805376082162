import { call, put, takeLatest } from 'redux-saga/effects';
import { Companies } from 'services/api';
import {
  removeEmployee,
  removeEmployeeSuccess,
  removeEmployeeFailure,
  getEmployees,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* removeEmployeeSaga({ payload }) {
  try {
    const data = yield call(Companies.removeUser, payload.id, payload.userId);
    yield put(removeEmployeeSuccess(data));
    yield put(getEmployees(payload.id));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'manageEmployees.remove' } },
      ])
    );
  } catch (e) {
    yield put(removeEmployeeFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(removeEmployee, removeEmployeeSaga);
