import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { UserSubscriptions } from './components/UserSubscriptions';
import { subscriptions, addSubscription } from './actions';

import styles from './styles.module.scss';

class PostSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      canAdd: this.canAdd() || false,
    };
  }

  componentDidMount() {
    this.props.canEdit &&
      this.props.post.subscriptionCount > 0 &&
      this.props.dispatch(subscriptions(this.props.post.id));
    this.props.post &&
      this.setState({ count: this.props.post.subscriptionCount });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.addIsFetching &&
      !this.props.addIsFetching &&
      this.props.apiErrors.length === 0 &&
      this.props.subscriptions
    ) {
      this.setState({ count: this.state.count + 1, canAdd: false });
    }
    prevProps.post.subscriptionCount !== this.props.post.subscriptionCount &&
      this.props.post &&
      this.setState({ count: this.props.post.subscriptionCount });
  }

  handleAddSubscription = () => {
    !this.props.canEdit &&
      this.state.canAdd &&
      this.props.dispatch(addSubscription(this.props.post.id));
  };

  canAdd = () => {
    return !Boolean(
      this.props.userSubscriptions &&
        this.props.userSubscriptions.includes(this.props.post.id)
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.subscription}>
          <span className={styles.count}>
            {this.state.count} {t('scenes.public.social.usersInterested')}
          </span>
          {!this.props.canEdit && this.state.canAdd && (
            <div className={styles.button}>
              <span className={styles.divider}>|</span>
              <Button color="secondary" onClick={this.handleAddSubscription}>
                {t('scenes.public.social.interested')}
              </Button>
            </div>
          )}
          {this.props.canEdit && (
            <div className={styles.right}>
              <Button
                color="link"
                className={styles.button + ' ' + styles.secondary}
                id={'toggler_' + this.props.post.id}
                onClick={this.handleClick}
              >
                <i className="fas fa-angle-down" />
              </Button>
            </div>
          )}
        </div>

        {this.props.canEdit && (
          <div className={styles.subscriptions}>
            <UserSubscriptions
              postId={this.props.post.id}
              subscriptions={this.props.subscriptions}
            />
          </div>
        )}
      </div>
    );
  }
}

PostSubscription.propTypes = {
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  apiErrors: state.postSubscription.errors,
  addIsFetching: state.postSubscription.addIsFetching,
  subscriptions: state.postSubscription.subscriptions[ownProps.post.id],
  userSubscriptions: state.session.user.subscriptions,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(PostSubscription);
