import { createAction } from 'redux-actions';

export const selectLanguage = createAction('SELECT_LANGUAGE_REQUEST');
export const selectLanguageSuccess = createAction('SELECT_LANGUAGE_SUCCESS');
export const selectLanguageFailure = createAction('SELECT_LANGUAGE_FAILURE');

export default {
  selectLanguage,
  selectLanguageSuccess,
  selectLanguageFailure,
};
