import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { companies, companiesPublic } from './actions';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import styles from './styles.module.scss';

class CompaniesSelect extends React.Component {
  componentDidMount() {
    if (this.props.public) {
      this.props.dispatch(companiesPublic());
    } else {
      this.props.dispatch(companies());
    }
  }

  render() {
    if (this.props.companies.length === 0) {
      return null;
    }

    return (
      <div className={styles['companies-select']}>
        {this.props.upperHr && <hr />}
        <FormGroup>
          <Label for="companyId">Select an existing company</Label>
          <Input
            type="select"
            id="companyId"
            name="companyId"
            onChange={this.props.onChange}
          >
            <option value={''} key={'choose'}>
              {this.props.t('forms.labels.select')}
            </option>
            {this.props.companies.length > 0 &&
              this.props.companies.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
          </Input>
        </FormGroup>
        {!this.props.upperHr && <hr />}
      </div>
    );
  }
}

CompaniesSelect.propTypes = {
  onChange: PropTypes.func,
  upperHr: PropTypes.bool,
  public: PropTypes.bool,
};

CompaniesSelect.defaultProps = {
  upperHr: false,
  public: false,
};

const mapStateToProps = state => ({
  companies: state.companies.companies,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(CompaniesSelect);
