import i18n from 'i18next';
import store from 'store2';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './translations/en/common.json';
import commonHu from './translations/hu/common.json';
import commonSr from './translations/sr/common.json';

const language = navigator.language.includes('-')
  ? navigator.language.split('-')[0]
  : navigator.language;

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,

    detection: {
      order: ['localStorage', 'querystring'],
      lookupQuerystring: 'locale',
      lookupLocalStorage: 'lng',
      caches: ['localStorage'],
    },

    lng: store('lng') || language,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: commonEn,
      },
      hu: {
        common: commonHu,
      },
      sr: {
        common: commonSr,
      },
    },
  });

export default i18n;
