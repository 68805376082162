import { combineActions, handleActions } from 'redux-actions';
import {
  adminCompanies,
  adminCompaniesSuccess,
  adminCompaniesFailure,
  adminCompany,
  adminCompanySuccess,
  adminCompanyFailure,
  adminUpdateCompany,
  adminUpdateCompanySuccess,
  adminUpdateCompanyFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  company: {},
  companies: [],
  errors: [],
};

const answerTypesReducer = handleActions(
  {
    [combineActions(
      adminCompanies,
      adminCompany,
      adminUpdateCompany
    )]: state => ({
      ...state,
      isFetching: true,
    }),

    [adminCompaniesSuccess]: (state, { payload: companies }) => ({
      ...state,
      isFetching: false,
      companies: companies.data,
    }),

    [combineActions(adminCompanySuccess, adminUpdateCompanySuccess)]: (
      state,
      { payload: company }
    ) => ({
      ...state,
      isFetching: false,
      company,
    }),

    [combineActions(
      adminCompaniesFailure,
      adminCompanyFailure,
      adminUpdateCompanyFailure
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default answerTypesReducer;
