import { call, put, takeEvery } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  subscriptions,
  subscriptionsSuccess,
  subscriptionsFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getSubscriptionsSaga({ payload }) {
  try {
    const { data } = yield call(Posts.subscriptions, payload);
    yield put(subscriptionsSuccess(data));
  } catch (e) {
    yield put(subscriptionsFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeEvery(subscriptions, getSubscriptionsSaga);
