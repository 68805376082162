import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { users } from './actions';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';

import styles from './styles.module.scss';

class UsersSelect extends React.Component {
  componentDidMount() {
    if (this.props.users && this.props.users.length === 0) {
      this.props.dispatch(users());
    }
  }

  render() {
    if (this.props.users.length === 0) {
      return null;
    }

    return (
      <div className={`${styles['users-select']} ${this.props.className}`}>
        <FormGroup className={styles.input}>
          <Input
            type="select"
            id="userId"
            name="userId"
            onChange={this.props.onChange}
          >
            <option value={''} key={'choose'}>
              {this.props.t('forms.labels.select')}
            </option>
            {this.props.users.length > 0 &&
              this.props.users.map(user => (
                <option value={user.id} key={user.id}>
                  {`${user.firstName} ${user.lastName} - ${user.email}`}
                </option>
              ))}
          </Input>
        </FormGroup>
      </div>
    );
  }
}

UsersSelect.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  users: state.users.users,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(UsersSelect);
