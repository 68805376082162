import { all } from 'redux-saga/effects';
import watchGetPostTopics from './watchers/getPostTopics';
import watchCreatePostTopic from './watchers/createPostTopic';
import watchUpdatePostTopic from './watchers/updatePostTopic';
import watchDeletePostTopic from './watchers/deletePostTopic';

function* postTopicsSaga() {
  yield all([
    watchGetPostTopics(),
    watchCreatePostTopic(),
    watchUpdatePostTopic(),
    watchDeletePostTopic(),
  ]);
}

export default postTopicsSaga;
