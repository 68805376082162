import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Comment } from 'components/Comment';
import { PostSubscription } from 'components/PostSubscription';
import { ConfirmModal } from 'components/ConfirmModal';

import styles from './styles.module.scss';

class Post extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDelete: false,
      activeItem: null,
    };
  }

  toggleDelete = item => {
    this.canDelete() &&
      this.setState({ activeItem: item, openDelete: !this.state.openDelete });
  };

  handleDelete = () => {
    this.props.onDeletePost(this.state.activeItem.id);
    this.toggleDelete(this.state.activeItem);
  };

  canEdit = () => {
    const { authorUserIds } = this.props.post;
    const { id } = this.props.currentUser;

    return authorUserIds.includes(id);
  };

  canDelete = () => {
    return this.canEdit() || this.props.currentUser.admin;
  };

  render() {
    const {
      authorName,
      authorImage,
      topic,
      title,
      content,
      date,
      subscription,
      bannerUrl,
      attachmentUrls,
    } = this.props.post;
    const { t } = this.props;
    return (
      <div className={styles.post}>
        <header>
          <div className={styles['avatar-wrapper']}>
            <div className={styles.content}>
              {authorImage === '' ? (
                <span className={styles.icon}>
                  <i className="fas fa-user" />
                </span>
              ) : (
                <img src={authorImage} alt="Avatar" />
              )}
            </div>
          </div>
          <div className={styles.middle}>
            <span className={styles.name}>{authorName}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <div className={styles['right-side']}>
            <div className={styles.wrapper}>
              <Button
                onClick={() => this.props.onTopicClick(topic)}
                color="link"
                className={styles.button}
              >
                <i className={`fas fa-hashtag ${styles.icon}`} />
                <span className={styles.name}>{topic}</span>
              </Button>
            </div>
          </div>
        </header>
        <main className={styles.main}>
          {bannerUrl && (
            <div className={styles.banner}>
              <img src={bannerUrl} alt="Post banner" />
            </div>
          )}
          <div className={styles['main-wrapper']}>
            <div className={styles.content}>
              <h3>{title}</h3>
              <p>{content}</p>
              {this.canDelete() && (
                <Button
                  className={styles['delete-post']}
                  onClick={() => this.toggleDelete(this.props.post)}
                  color="link"
                >
                  <i className="fas fa-trash" />
                </Button>
              )}
            </div>
            {attachmentUrls && attachmentUrls.length > 0 && (
              <ul className={styles.attachments}>
                {attachmentUrls.map(({ name, url }) => (
                  <li key={name}>
                    <a href={url}>
                      <i className="far fa-file" /> {name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {subscription && (
              <PostSubscription
                post={this.props.post}
                canEdit={this.canEdit()}
              />
            )}
          </div>
        </main>
        <footer>
          <Comment
            postId={this.props.post.id}
            comments={this.props.post.comments}
            count={this.props.post.commentCount}
          />
        </footer>

        <ConfirmModal
          modalOpen={this.state.openDelete}
          toggleModal={this.toggleDelete}
          primaryButtonText={t('confirmationModal.submit')}
          onModalConfirm={this.handleDelete}
        >
          <i className={`far fa-times-circle ${styles['modal-icon']}`} />
          <h3>{t('confirmationModal.title')}</h3>
          <p>
            {t('confirmationModal.text', {
              name: this.state.activeItem && this.state.activeItem.title,
              resource: 'post',
            })}
          </p>
        </ConfirmModal>
      </div>
    );
  }
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
  onTopicClick: PropTypes.func.isRequired,
};

export default Post;
