import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { LANGUAGES } from 'constants/SelectOptions';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { selectLanguage } from './actions';

import styles from './styles.module.scss';

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      selected: props.language,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  selectLanguage = language => {
    this.setState({ selected: language });
    this.props.i18n.changeLanguage(language);
    this.props.dispatch(selectLanguage(language));
  };

  render() {
    const { t } = this.props;
    return (
      <Dropdown
        direction="up"
        size="sm"
        className={styles['dropdown-wrapper']}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle tag="button" className={styles['dropdown-button']}>
          <i className="fas fa-globe-americas" />{' '}
          {t(`languages.${this.state.selected}`)}
        </DropdownToggle>
        <DropdownMenu className={styles['dropdown-menu']} right>
          {LANGUAGES.map(language => (
            <DropdownItem
              onClick={() => this.selectLanguage(language)}
              key={language}
            >
              {t(`languages.${language}`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
  isFetching: state.language.isFetching,
  apiErrors: state.language.errors,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(LanguageSelector);
