import { call, put, takeLatest } from 'redux-saga/effects';
import { AdminCompetences } from 'services/api';
import {
  deleteCompetence,
  deleteCompetenceSuccess,
  deleteCompetenceFailure,
  getAdminCompetences,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';
import { clearFilter } from 'components/CompetenceFilter/actions';

function* deleteCompetenceSaga({ payload }) {
  try {
    const data = yield call(AdminCompetences.deleteCompetence, payload.id);
    yield put(deleteCompetenceSuccess(data));
    yield put(getAdminCompetences({ locale: payload.locale }));
    yield put(clearFilter());
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'deleted', resource: 'competence' },
        },
      ])
    );
  } catch (e) {
    yield put(deleteCompetenceFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(deleteCompetence, deleteCompetenceSaga);
