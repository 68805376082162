import { combineActions, handleActions } from 'redux-actions';
import {
  signIn,
  signInSuccess,
  signInFailure,
  logOut,
  profile,
  profileSuccess,
  profileFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  user: {},
  errors: [],
};

const sessionReducer = handleActions(
  {
    [combineActions(signIn, profile)]: state => ({
      ...state,
      isFetching: true,
    }),

    [combineActions(signInSuccess, profileSuccess)]: (
      state,
      { payload: user }
    ) => ({
      ...state,
      isFetching: false,
      user,
    }),

    [combineActions(signInFailure, profileFailure)]: (
      state,
      { payload: errors }
    ) => ({
      ...state,
      isFetching: false,
      errors: errors && errors.errors,
    }),

    [logOut]: (_state, _action) => INITIAL_STATE,
  },
  INITIAL_STATE
);

export default sessionReducer;
