import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';

import styles from './styles.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  avatar: undefined,
  admin: false,
  companyAdmin: false,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  avatar: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
  admin: Yup.string(),
  companyAdmin: Yup.string().nullable(),
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: props.user.avatarUrl || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.resetForm();
      this.props.onRefreshSessionUser();
    }
  }

  handleImageSelect = e => {
    this.props.setFieldValue('avatar', e.target.files[0]);
    this.props.setFieldTouched('avatar');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('avatar', '');
    this.setState({
      previewImage: '',
    });
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <div className={styles['registration-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            <div className={styles['left-side']}>
              <InputField
                name="firstName"
                type="string"
                labelText={t('scenes.admin.users.form.firstName')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="lastName"
                type="string"
                labelText={t('scenes.admin.users.form.lastName')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="email"
                type="email"
                labelText={t('scenes.admin.users.form.email')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <FormGroup className={styles.input}>
                <Label>{t('scenes.admin.users.form.password.label')}</Label>
                <Button
                  onClick={this.props.onGeneratePassword}
                  color="secondary"
                  block
                >
                  {t('scenes.admin.users.form.password.button')}
                </Button>
              </FormGroup>
              {!values.confirmed && (
                <FormGroup className={styles.input}>
                  <Label>{t('scenes.admin.users.form.confirm.label')}</Label>
                  <Button
                    onClick={this.props.onConfirmationEmail}
                    color="secondary"
                    block
                  >
                    {t('scenes.admin.users.form.confirm.button')}
                  </Button>
                </FormGroup>
              )}
              <InputField
                name="admin"
                type="checkbox"
                labelText={t('scenes.admin.users.form.admin')}
                value={values.admin}
                errors={errors}
                touched={touched}
                className={styles.input}
              />
              <InputField
                name="companyAdmin"
                type="checkbox"
                labelText={
                  values.companyName
                    ? t('scenes.admin.users.form.companyAdminWithCompany', {
                        company: values.companyName,
                      })
                    : t('scenes.admin.users.form.companyAdmin')
                }
                value={values.companyAdmin}
                errors={errors}
                touched={touched}
                className={styles.input}
                disabled={values.companyAdmin === null}
              />
            </div>
            <div className={styles['right-side']}>
              <ImageUpload
                name="avatar"
                type="file"
                onChange={this.handleImageSelect}
                onClose={this.handleImageCancel}
                previewImage={this.state.previewImage}
                errorMessage={errors.avatar}
              />
              <div className={styles['name-preview']}>
                {values.firstName} {values.lastName}
              </div>
            </div>
          </div>
          <footer>
            <FormGroup>
              <Link to="/admin/users">
                <Button color="secondary" outline>
                  {t('scenes.admin.users.form.cancel')}
                </Button>
              </Link>
              <Button type="submit" color="primary" disabled={isFetching}>
                {t('scenes.admin.users.form.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
      </div>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => props.user || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.avatar === 'undefined') {
        delete values.avatar;
      }
      if (values.companyAdmin === null) {
        delete values.companyAdmin;
      }
      delete values.avatarUrl;
      delete values.companyName;

      formikBag.props.onSubmit(values);
    },

    displayName: 'AdminUserEditForm',
  })
)(UserForm);
