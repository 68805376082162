import { createAction } from 'redux-actions';

export const confirmation = createAction('CONFIRMATION_REQUEST');
export const confirmationSuccess = createAction('CONFIRMATION_SUCCESS');
export const confirmationFailure = createAction('CONFIRMATION_FAILURE');

export default {
  confirmation,
  confirmationSuccess,
  confirmationFailure,
};
