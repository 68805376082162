export const FILE_SIZE = 2000000;

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

export default {
  FILE_SIZE,
  SUPPORTED_FORMATS,
};
