import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LEVELS } from 'constants/SelectOptions';
import { FormGroup, Label, Button, Input } from 'reactstrap';
import { Field, FieldArray } from 'formik';
import { ErrorLabel } from 'components/Form';

import styles from './styles.module.scss';

class LanguageInput extends React.Component {
  // TODO: Refactor!
  getError = (index, name) => {
    return (
      this.props.errors[this.props.name] &&
      this.props.errors[this.props.name][index] &&
      this.props.errors[this.props.name][index][name]
    );
  };

  getTouched = (index, name) => {
    return (
      this.props.touched[this.props.name] &&
      this.props.touched[this.props.name][index] &&
      this.props.touched[this.props.name][index][name]
    );
  };

  render() {
    const isThereAValue = this.props.value && this.props.value.length > 0;
    const { t } = this.props;
    return (
      <FieldArray name={this.props.name}>
        {({ push, remove }) => (
          <div className={styles['language-select']}>
            <Label>
              {t('scenes.public.profileModal.studies.form.languages.label')}
            </Label>
            {isThereAValue &&
              this.props.value.map((language, index) => (
                <FormGroup key={index}>
                  <div className={styles.wrapper}>
                    <FormGroup className={styles['language-field']}>
                      <Input
                        tag={Field}
                        type="text"
                        name={`${this.props.name}[${index}].language`}
                        placeholder={t(
                          'scenes.public.profileModal.studies.form.languages.fields.language'
                        )}
                        valid={Boolean(
                          this.getTouched(index, 'language') &&
                            !this.getError(index, 'language')
                        )}
                        invalid={Boolean(
                          this.getTouched(index, 'language') &&
                            this.getError(index, 'language')
                        )}
                        value={this.props.value[index].language}
                      />
                      {this.getTouched(index, 'language') &&
                        this.getError(index, 'language') && (
                          <ErrorLabel
                            error={this.getError(index, 'language')}
                          />
                        )}
                    </FormGroup>
                    <FormGroup className={styles['level-field']}>
                      <Input
                        type="select"
                        id={`${this.props.name}[${index}].level`}
                        name={`${this.props.name}[${index}].level`}
                        onChange={this.props.onSelect}
                        valid={Boolean(
                          this.getTouched(index, 'level') &&
                            !this.getError(index, 'level')
                        )}
                        invalid={Boolean(
                          this.getTouched(index, 'level') &&
                            this.getError(index, 'level')
                        )}
                        value={this.props.value[index].level}
                      >
                        <option value={''} key={'choose'}>
                          {t(
                            'scenes.public.profileModal.studies.form.languages.fields.level.label'
                          )}
                        </option>
                        {LEVELS.map(option => (
                          <option
                            value={option.split('.').pop()}
                            key={option.split('.').pop()}
                          >
                            {t(option)}
                          </option>
                        ))}
                      </Input>
                      {this.getTouched(index, 'level') &&
                        this.getError(index, 'level') && (
                          <ErrorLabel error={this.getError(index, 'level')} />
                        )}
                    </FormGroup>
                    <Button
                      color="link"
                      className={styles['remove-button']}
                      onClick={() =>
                        this.props.value.length > 1 && remove(index)
                      }
                      disabled={this.props.value.length === 1}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </div>
                </FormGroup>
              ))}
            <div className={styles['add-button-wrapper']}>
              <Button
                color="link"
                className={styles['add-button']}
                onClick={() => push({ language: '', level: '' })}
              >
                <i className="fas fa-plus" />{' '}
                {t('scenes.public.profileModal.studies.form.languages.add')}
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    );
  }
}

LanguageInput.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
};

export default withTranslation('common')(LanguageInput);
