import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { EmployeesTable } from './components/EmployeesTable';
import { AddUserForm } from './components/AddUserForm';
import * as userActions from 'components/UsersSelect/actions';
import { default as employeesActions } from './actions';

import styles from './styles.module.scss';

class ManageEmployees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addUser: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(employeesActions.getEmployees(this.props.company.id));
    this.props.dispatch(userActions.users());
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.dispatch(userActions.users());
      this.setState({ addUser: false });
    }
  }

  handleClick = () => {
    if (this.props.users && this.props.users.length === 0) {
      return null;
    }
    this.setState({ addUser: true });
  };

  handleAddUser = values => {
    this.props.company &&
      this.props.dispatch(
        employeesActions.addEmployee({
          companyId: this.props.company.id,
          values,
        })
      );
  };

  handlePermissionChange = (id, admin) => {
    this.props.dispatch(
      employeesActions.updateEmployee({
        companyId: this.props.company.id,
        userId: id,
        company: { admin: !admin },
      })
    );
  };

  handleRemoveUser = id => {
    this.props.dispatch(
      employeesActions.removeEmployee({
        userId: id,
        id: this.props.company.id,
      })
    );
    this.props.sessionUser.id === id && this.props.onCancel('company');
  };

  render() {
    return (
      <div className={styles['manage-employees']}>
        <div className={styles['main-wrapper']}>
          <h3>{this.props.company.name}</h3>

          <EmployeesTable
            company={this.props.company}
            employees={this.props.employees}
            sessionUser={this.props.sessionUser}
            onPermissionChange={this.handlePermissionChange}
            onRemoveUser={this.handleRemoveUser}
          />

          {this.state.addUser && <AddUserForm onSubmit={this.handleAddUser} />}
          {!this.state.addUser && (
            <div className={styles['add-button']}>
              <Button
                color="link"
                className={styles['remove-button']}
                onClick={this.handleClick}
                disabled={this.props.users && this.props.users.length === 0}
              >
                <i className="fas fa-plus" />{' '}
                {this.props.t('components.manageEmployees.add')}
              </Button>
            </div>
          )}
        </div>
        <footer>
          <Button
            onClick={() => this.props.onCancel('company')}
            color="secondary"
            outline
          >
            {this.props.t('components.manageEmployees.back')}
          </Button>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.manageEmployees.isFetching,
  apiErrors: state.manageEmployees.errors,
  users: state.users.users,
  sessionUser: state.session.user,
  employees: state.manageEmployees.users,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(ManageEmployees);
