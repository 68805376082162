import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal as ReactstrapModal, ModalBody } from 'reactstrap';
import { Personal } from './components/Personal';
import { PasswordChange } from 'components/PasswordChange';
import { Studies } from './components/Studies';
import { Company } from './components/Company';
import * as sessionActions from 'services/session/actions';
import * as profileActions from './actions';

import styles from './styles.module.scss';

class ProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePanel: 'personal',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen !== this.props.modalOpen) {
      this.setState({
        activePanel: 'personal',
        refresh: false,
      });
      this.props.dispatch(sessionActions.profile());
    }
  }

  handleActivePanelChange = key => {
    if (key !== this.state.activePanel) {
      this.setState({ activePanel: key });
    }
  };

  handleSubmit = values => {
    this.props.dispatch(profileActions.updateUserProfile(values));
  };

  handleDeleteProfile = () => {
    this.props.dispatch(profileActions.deleteProfile());
  };

  renderContent = () => {
    switch (this.state.activePanel) {
      case 'personal':
        return (
          <Personal
            user={(({
              firstName,
              lastName,
              email,
              avatarUrl,
              emailConsent,
            }) => ({
              firstName,
              lastName,
              email,
              avatarUrl,
              emailConsent,
            }))(this.props.user)}
            isFetching={this.props.isFetching}
            apiErrors={this.props.apiErrors}
            onSubmit={this.handleSubmit}
            onDeleteProfile={this.handleDeleteProfile}
            onCancel={this.props.toggleModal}
            onChangePassword={this.handleActivePanelChange}
          />
        );
      case 'personal-password':
        return (
          <PasswordChange
            isFetching={this.props.isFetching}
            apiErrors={this.props.apiErrors}
            onSubmit={this.handleSubmit}
            onCancel={this.handleActivePanelChange}
          />
        );
      case 'studies':
        return (
          <Studies
            user={(({
              highestLevelOfEducation,
              student,
              workingHours,
              languageExamsAttributes,
              experiencesAttributes,
            }) => ({
              highestLevelOfEducation,
              student,
              workingHours,
              languageExamsAttributes,
              experiencesAttributes,
            }))(this.props.user)}
            isFetching={this.props.isFetching}
            apiErrors={this.props.apiErrors}
            onSubmit={this.handleSubmit}
            onCancel={this.props.toggleModal}
          />
        );
      case 'company':
        return (
          <Company
            company={this.props.company}
            isFetching={this.props.isFetching}
            apiErrors={this.props.apiErrors}
            onSubmit={this.handleSubmit}
            onCancel={this.props.toggleModal}
            onManageEmployees={this.handleActivePanelChange}
          />
        );
      default:
        return null;
    }
  };

  render() {
    if (!this.props.modalOpen) {
      return null;
    }

    const { t } = this.props;
    return (
      <ReactstrapModal
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        className={`${styles.modal} ${this.props.className}`}
        centered
      >
        <div className="modal-close" onClick={this.props.toggleModal}>
          <i className="fas fa-times" />
        </div>
        <ModalBody>
          <aside className={styles.sidebar}>
            <div
              className={`${styles['personal-button']} ${
                /personal.*/.test(this.state.activePanel) ? styles.active : ''
              }`}
              onClick={() => this.handleActivePanelChange('personal')}
            >
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.personal.title')}
              </span>
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.personal.subtitle')}
              </span>
            </div>
            <div
              className={`${styles['studies-button']} ${
                this.state.activePanel === 'studies' ? styles.active : ''
              }`}
              onClick={() => this.handleActivePanelChange('studies')}
            >
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.studies.title')}
              </span>
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.studies.subtitle')}
              </span>
            </div>
            <div
              className={`${styles['company-button']} ${
                /company.*/.test(this.state.activePanel) ? styles.active : ''
              }`}
              onClick={() => this.handleActivePanelChange('company')}
            >
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.company.title')}
              </span>
              <span className={styles['sidebar-text']}>
                {t('scenes.public.profileModal.company.subtitle')}
              </span>
            </div>
          </aside>
          <div className={`modal-body-content ${styles['modal-body']}`}>
            {this.renderContent()}
          </div>
        </ModalBody>
      </ReactstrapModal>
    );
  }
}

ProfileModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  user: state.session.user,
  company: state.session.user.company,
  isFetching: state.profile.isFetching,
  apiErrors: state.profile.errors,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(ProfileModal);
