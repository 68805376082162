import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: props.selectedValues || null,
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onOptionChange(selectedOption);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedValues &&
      this.props.selectedValues &&
      prevProps.selectedValues.length !== this.props.selectedValues.length
    ) {
      this.setState({ selectedOption: this.props.selectedValues });
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        isMulti
        options={this.props.options}
        value={selectedOption}
        onChange={this.handleChange}
        placeholder={this.props.t('forms.labels.multiSelect')}
      />
    );
  }
}

MultiSelect.propTypes = {
  selectedValues: PropTypes.array,
};

MultiSelect.defaultProps = {
  options: [],
};

export default withTranslation('common')(MultiSelect);
