import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { default as notificationActions } from './actions';

import styles from './styles.module.scss';

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.notifications.length !== this.props.notifications.length &&
      this.props.notifications.length > 0
    ) {
      this.setState({ visible: true });
      setTimeout(() => {
        this.onDismiss();
      }, 5000);
    }
  }

  onDismiss = () => {
    this.setState({ visible: false });
    this.props.dispatch(notificationActions.removeNotification());
  };

  renderAlert = (type, message, key) => {
    const { t } = this.props;
    switch (type) {
      case 'success':
        return (
          <Alert
            color="success"
            key={key}
            isOpen={this.state.visible}
            toggle={this.onDismiss}
            className={styles.notification}
          >
            <b>
              <i className="fas fa-check" /> {t('notifications.types.success')}
            </b>{' '}
            {t(`notifications.messages.${message.code}`, {
              resource: t(`notifications.resources.${message.resource}`),
            })}
          </Alert>
        );
      case 'error':
        return (
          <Alert
            color="danger"
            key={key}
            isOpen={this.state.visible}
            toggle={this.onDismiss}
            className={styles.notification}
          >
            <b>
              <i className="fas fa-exclamation" />{' '}
              {t('notifications.types.error')}
            </b>{' '}
            {t(`notifications.messages.${message}`)}
          </Alert>
        );
      default:
        return null;
    }
  };

  render() {
    if (this.props.notifications.length === 0) {
      return null;
    }

    return (
      <div className={styles['notification-wrapper']}>
        {this.props.notifications.map((notification, index) =>
          this.renderAlert(notification.type, notification.message, index)
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications.messages,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Notification);
