import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as adminCompaniesActions from '../../actions';
import { Nav, NavItem, Table } from 'reactstrap';

class CompaniesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(adminCompaniesActions.adminCompanies());
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h1>{t('scenes.admin.companies.title')}</h1>
        <Table hover responsive>
          <thead>
            <tr>
              <th>{t('scenes.admin.companies.table.name')}</th>
              <th>{t('scenes.admin.companies.table.email')}</th>
              <th>{t('scenes.admin.companies.table.actions.header')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.companies &&
              this.props.companies.length > 0 &&
              this.props.companies.map(company => (
                <tr key={company.id}>
                  <td>{company.name}</td>
                  <td>{company.email}</td>
                  <td>
                    <Nav>
                      <NavItem>
                        <Link to={`/admin/companies/${company.id}`}>
                          {t('scenes.admin.companies.table.actions.edit')}
                        </Link>
                      </NavItem>
                    </Nav>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.adminCompanies.companies,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(CompaniesTable);
