import { all } from 'redux-saga/effects';
import getEmployeesSaga from './watchers/getEmployees';
import addEmployeeSaga from './watchers/addEmployee';
import removeEmployeeSaga from './watchers/removeEmployee';
import updateEmployeeSaga from './watchers/updateEmployee';

function* competencesSaga() {
  yield all([
    addEmployeeSaga(),
    removeEmployeeSaga(),
    updateEmployeeSaga(),
    getEmployeesSaga(),
  ]);
}

export default competencesSaga;
