import { call, put, takeLatest } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileFailure,
} from '../../actions';
import { profile } from 'services/session/actions';
import { actions as notificationActions } from 'components/Notification';

function convertKey(key) {
  return key.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

function* updateUserSaga({ payload }) {
  try {
    const formDataNeeded =
      payload.hasOwnProperty('avatar') ||
      payload.hasOwnProperty('companyAttributes');
    let formData = new FormData();
    if (formDataNeeded) {
      Object.entries(payload).forEach(([key, value]) => {
        if (key !== 'email' && !key.includes('Attributes')) {
          formData.append(`user[${convertKey(key)}]`, value);
        }
        if (key.includes('Attributes')) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            formData.append(
              `user[${convertKey(key)}][${convertKey(subKey)}]`,
              subValue
            );
          });
        }
      });
    }
    const { data } = yield call(
      Users.update,
      formDataNeeded ? formData : { user: payload }
    );
    yield put(updateUserProfileSuccess(data));
    yield put(profile());
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'profile' } },
      ])
    );
  } catch (e) {
    yield put(updateUserProfileFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(updateUserProfile, updateUserSaga);
