import { handleActions } from 'redux-actions';
import {
  getDashboard,
  getDashboardSuccess,
  getDashboardFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  dashboard: {},
  errors: [],
};

const dashboardReducer = handleActions(
  {
    [getDashboard]: state => ({
      ...state,
      isFetching: true,
      errors: [],
    }),

    [getDashboardSuccess]: (state, { payload: dashboard }) => ({
      ...state,
      isFetching: false,
      dashboard,
      errors: [],
    }),

    [getDashboardFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload && payload.errors,
    }),
  },
  INITIAL_STATE
);

export default dashboardReducer;
