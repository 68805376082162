import { handleActions } from 'redux-actions';
import { users, usersSuccess, usersFailure } from './actions';

const INITIAL_STATE = {
  isFetching: false,
  users: [],
  errors: [],
};

const usersReducer = handleActions(
  {
    [users]: state => ({
      ...state,
      isFetching: true,
    }),

    [usersSuccess]: (state, { payload: users }) => ({
      ...state,
      isFetching: false,
      users: users.data,
    }),

    [usersFailure]: (state, { payload: errors }) => ({
      ...state,
      isFetching: false,
      errors: errors && errors.errors,
    }),
  },
  INITIAL_STATE
);

export default usersReducer;
