import { call, put, takeLatest } from 'redux-saga/effects';
import { Competences } from 'services/api';
import {
  updateCompetenceAnswers,
  updateCompetenceAnswersSuccess,
  updateCompetenceAnswersFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* updateCompetencesSaga(action) {
  try {
    const data = yield call(
      Competences.updateCompetenceAnswers,
      action.payload
    );
    yield put(updateCompetenceAnswersSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'changes' } },
      ])
    );
  } catch (e) {
    yield put(updateCompetenceAnswersFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(updateCompetenceAnswers, updateCompetencesSaga);
