import { get, post, patch, del } from '../rest';

export default {
  signIn: params => post('/users/login', params),
  signUp: params => post('/users', params),
  confirmation: params => get('/users/confirmation', params),
  profile: params => get('/users/profile', params),
  update: params => patch('/users/profile', params),
  delete: params => del('/users/profile', params),
  newPassword: params => post('/users/password', params),
  changePassword: params => patch('/users/password', params),
  checkEmail: params => get('/users/check_email', params),
  all: params => get('/users', params),

  // Admin
  adminUser: params => get('/admin/users', params),
  adminUserShow: id => get('/admin/users/' + id),
  adminUserUpdate: (id, params) => patch(`/admin/users/${id}`, params),
  generateNewPassword: (id, params) =>
    get(`/admin/users/${id}/generate_password`, params),
  confirmationEmail: (id, params) =>
    get(`/admin/users/${id}/confirmation_email`, params),
};
