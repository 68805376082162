import { call, put, takeLatest } from 'redux-saga/effects';
import { Companies } from 'services/api';
import {
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getEmployeesSaga({ payload }) {
  try {
    const { data } = yield call(Companies.employees, payload);
    yield put(getEmployeesSuccess(data));
  } catch (e) {
    yield put(getEmployeesFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(getEmployees, getEmployeesSaga);
