import { createAction } from 'redux-actions';

export const signIn = createAction('SIGN_IN_REQUEST');
export const signInSuccess = createAction('SIGN_IN_SUCCESS');
export const signInFailure = createAction('SIGN_IN_FAILURE');

export const logOut = createAction('LOG_OUT_REQUEST');

export const profile = createAction('PROFILE_REQUEST');
export const profileSuccess = createAction('PROFILE_SUCCESS');
export const profileFailure = createAction('PROFILE_FAILURE');

export default {
  signIn,
  signInSuccess,
  signInFailure,
  logOut,
  profile,
  profileSuccess,
  profileFailure,
};
