import { createAction } from 'redux-actions';

export const adminUsers = createAction('ADMIN_USERS_REQUEST');
export const adminUsersSuccess = createAction('ADMIN_USERS_SUCCESS');
export const adminUsersFailure = createAction('ADMIN_USERS_FAILURE');

export const adminUser = createAction('ADMIN_USER_REQUEST');
export const adminUserSuccess = createAction('ADMIN_USER_SUCCESS');
export const adminUserFailure = createAction('ADMIN_USER_FAILURE');

export const adminUpdateUser = createAction('ADMIN_UPDATE_USER_REQUEST');
export const adminUpdateUserSuccess = createAction('ADMIN_UPDATE_USER_SUCCESS');
export const adminUpdateUserFailure = createAction('ADMIN_UPDATE_USER_FAILURE');

export const generatePassword = createAction('GENERATE_PASSWORD_REQUEST');
export const generatePasswordSuccess = createAction(
  'GENERATE_PASSWORD_SUCCESS'
);
export const generatePasswordFailure = createAction(
  'GENERATE_PASSWORD_FAILURE'
);

export const sendConfirmationEmail = createAction(
  'SEND_CONFIRMATION_EMAIL_REQUEST'
);
export const sendConfirmationEmailSuccess = createAction(
  'SEND_CONFIRMATION_EMAIL_SUCCESS'
);
export const sendConfirmationEmailFailure = createAction(
  'SEND_CONFIRMATION_EMAIL_FAILURE'
);

export default {
  adminUsers,
  adminUsersSuccess,
  adminUsersFailure,
  adminUser,
  adminUserSuccess,
  adminUserFailure,
  adminUpdateUser,
  adminUpdateUserSuccess,
  adminUpdateUserFailure,
  generatePassword,
  generatePasswordSuccess,
  generatePasswordFailure,
  sendConfirmationEmail,
  sendConfirmationEmailSuccess,
  sendConfirmationEmailFailure,
};
