import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Row, Col, Label, Spinner } from 'reactstrap';
import { MultiSelect } from 'components/Form';
import { get } from 'services/rest';
import { saveFilter } from './actions';
import actions from './actions';
import { actions as competenceActions } from 'scenes/Admin/AdminCompetences';

import styles from './styles.module.scss';

const FILTERS = {
  competenceGroups: 'competenceGroups',
  competences: 'competences',
  answerTypes: 'answerTypes',
  users: 'users',
  companies: 'companies',
};

class CompetenceFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: { ...props.filter },
      locale: props.language,
    };
  }

  componentDidMount() {
    this.props.language &&
      (this.props.options &&
        Object.values(this.props.options)
          .map(value => value.length)
          .includes(0)) &&
      this.props.dispatch(
        actions.getFilterOptions({
          locale: this.props.language,
        })
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.language !== this.props.language &&
      this.props.language !== ''
    ) {
      this.props.dispatch(
        actions.getFilterOptions({ locale: this.props.language })
      );
    }
    if (prevProps.filter !== this.props.filter) {
      this.setState({ selected: this.props.filter });
      this.props.dispatch(
        actions.getFilterOptions({ locale: this.props.language })
      );
    }
  }

  handleFilterChange = (e, type) => {
    this.setState({
      selected: {
        ...this.state.selected,
        [type]: e.map(x => x.value),
      },
    });
  };

  handleSearch = e => {
    e.preventDefault();
    const filtered = this.isFiltered();
    this.props.dispatch(
      saveFilter({ ...this.state.selected, isFiltered: filtered })
    );
    this.props.onSubmit(filtered);
    this.props.dispatch(
      competenceActions.getAdminCompetences({
        ...this.state.selected,
        locale: this.props.language,
      })
    );
  };

  isFiltered = () => {
    return Boolean(
      this.state.selected.competences.length ||
        this.state.selected.competenceGroups.length ||
        this.state.selected.answerTypes.length
    );
  };

  onExport = () => {
    get(
      '/admin/competences.csv',
      { ...this.state.selected, locale: this.props.language },
      true
    );
  };

  findSelected = (options, selected) => {
    if (options && options.length > 0) {
      return options.filter(option => selected.includes(option.value));
    }
  };

  render() {
    const { isFetching, t } = this.props;
    const {
      competences,
      competenceGroups,
      answerTypes,
      users,
      companies,
    } = this.props.options;
    return (
      <div className={styles['filter-wrapper']}>
        {this.props.options && Object.keys(this.props.options).length > 0 ? (
          <Form onSubmit={this.handleSearch} className={styles.filter}>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {t('components.competenceFilter.labels.competenceGroup')}
                  </Label>
                  <MultiSelect
                    selectedValues={this.findSelected(
                      competenceGroups,
                      this.state.selected.competenceGroups
                    )}
                    options={competenceGroups}
                    onOptionChange={e =>
                      this.handleFilterChange(e, FILTERS.competenceGroups)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {t('components.competenceFilter.labels.competence')}
                  </Label>
                  <MultiSelect
                    selectedValues={this.findSelected(
                      competences,
                      this.state.selected.competences
                    )}
                    options={competences}
                    onOptionChange={e =>
                      this.handleFilterChange(e, FILTERS.competences)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {t('components.competenceFilter.labels.answerType')}
                  </Label>
                  <MultiSelect
                    selectedValues={this.findSelected(
                      answerTypes,
                      this.state.selected.answerTypes
                    )}
                    options={answerTypes}
                    onOptionChange={e =>
                      this.handleFilterChange(e, FILTERS.answerTypes)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label>{t('components.competenceFilter.labels.user')}</Label>
                  <MultiSelect
                    selectedValues={this.findSelected(
                      users,
                      this.state.selected.users
                    )}
                    options={users}
                    onOptionChange={e =>
                      this.handleFilterChange(e, FILTERS.users)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {t('components.competenceFilter.labels.company')}
                  </Label>
                  <MultiSelect
                    selectedValues={this.findSelected(
                      companies,
                      this.state.selected.companies
                    )}
                    options={companies}
                    onOptionChange={e =>
                      this.handleFilterChange(e, FILTERS.companies)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isFetching}
                  className={styles.buttons}
                >
                  {t('components.competenceFilter.buttons.search')}
                </Button>
                <Button
                  onClick={this.onExport}
                  color="primary"
                  disabled={isFetching}
                  className={styles.buttons}
                >
                  {t('components.competenceFilter.buttons.export')}
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Spinner color="primary" />
        )}
      </div>
    );
  }
}

CompetenceFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetching: state.competenceFilter.isFetching,
  apiErrors: state.competenceFilter.errors,
  options: state.competenceFilter.options,
  filter: state.competenceFilter.filter,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(CompetenceFilter);
