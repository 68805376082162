import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { AppSidebar } from 'components/AppSidebar';
import { AdminSidebar } from 'components/AdminSidebar';
import { ProgrammeLogos } from 'components/ProgrammeLogos';

import { AdminLayout } from 'layouts/AdminLayout';

import { Competences } from 'scenes/Competences';
import { Social } from 'scenes/Social';
import { Error404 } from 'scenes/Error404';

import styles from './styles.module.scss';

function isAdmin(session) {
  return Boolean(Object.keys(session.user).length) && session.user.admin;
}

const MainLayout = ({ session, location, language, t }) => {
  return (
    <article className={styles.main}>
      {isAdmin(session) && location.pathname.startsWith('/admin') ? (
        <AdminSidebar />
      ) : (
        <AppSidebar />
      )}

      <section className={styles['main-wrapper']}>
        <div className={styles['main-content']}>
          <Switch>
            <Route path="/competences" component={Competences} />
            <Route path="/social" component={Social} />
            {isAdmin(session) && (
              <Route path="/admin" component={AdminLayout} />
            )}

            <Redirect exact from="/" to="/social" />

            {/* "Catch all" page */}
            <Route component={Error404} />
          </Switch>
        </div>

        <footer className={styles.footer}>
          <ProgrammeLogos language={language} />
          <p className={styles.disclaimer}>{t('footer.disclaimer')}</p>
        </footer>
      </section>
    </article>
  );
};

function mapStateToProps(state) {
  return {
    session: state.session,
    language: state.language.language,
  };
}

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(MainLayout);
