import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import { Modal } from 'components/Modal';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload, FileUpload } from 'components/Form';
import { Button, FormGroup } from 'reactstrap';
import { default as postActions } from './actions';

import styles from './styles.module.scss';

const initialValues = {
  title: '',
  content: '',
  attachments: [],
  asCompany: false,
  subscription: false,
  postTopicId: '',
  banner: undefined,
};

const validationSchema = Yup.object({
  title: Yup.string().required('required'),
  content: Yup.string(),
  // attachments: Yup.array(),
  asCompany: Yup.boolean(),
  subscription: Yup.boolean(),
  postTopicId: Yup.string().required('required'),
  banner: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});

class PostModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: props.post.bannerUrl || '',
      attachmentsPreview:
        props.post.attachmentUrls.map(file => file.name) || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.post !== this.props.post && this.props.post) {
      this.setState({
        previewImage: this.props.post.bannerUrl || '',
        attachmentsPreview:
          this.props.post.attachmentUrls.map(file => file.name) || [],
      });
    }
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      if (this.props.openNewPostModal && !this.props.moderation) {
        this.props.resetForm();
        this.setState({
          previewImage: '',
          attachmentsPreview: [],
        });
        this.props.toggleNewPostModal();
      }
    }
  }

  handleAttachmentsSelect = e => {
    this.props.setFieldValue('attachments', e.target.files);
    this.props.setFieldTouched('attachments');
    this.setState({
      attachmentsPreview: Array.from(e.target.files).map(file => file.name),
    });
  };

  handleAttachmentsUnselect = index => {
    let array = Array.from(this.props.values.attachments);
    index > -1 && array.splice(index, 1);
    this.props.setFieldValue('attachments', array);
    this.setState({
      attachmentsPreview: array.map(file => file.name),
    });
  };

  handleImageSelect = e => {
    this.props.setFieldValue('banner', e.target.files[0]);
    this.props.setFieldTouched('banner');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('banner', '');
    this.setState({
      previewImage: '',
    });
  };

  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  handleCancel = () => {
    this.props.resetForm();
    this.setState({
      previewImage: '',
      attachmentsPreview: [],
    });
    this.props.toggleNewPostModal();
  };

  render() {
    const { isFetching, touched, errors, values, moderation, t } = this.props;
    return (
      <Modal
        modalOpen={this.props.openNewPostModal}
        sidebarName="newPost"
        sidebarTitleText={t(
          `scenes.public.newPost.sidebar.${moderation ? 'moderation' : 'new'}`
        )}
        toggleModal={this.handleCancel}
      >
        <div className={styles['post-form']}>
          {moderation && (
            <div className={styles['left-arrow']}>
              <Button
                className={styles.icon}
                onClick={() => this.props.paginate('prev')}
                color="link"
              >
                <i className="fas fa-arrow-left" />
              </Button>
            </div>
          )}
          <Form className={styles.form}>
            <div className={styles['form-wrapper']}>
              <div className={styles.wrapped}>
                <ImageUpload
                  name="banner"
                  type="file"
                  previewImage={this.state.previewImage}
                  errorMessage={errors.banner}
                  className={styles.banner}
                  uploadName="banner"
                  onChange={this.handleImageSelect}
                  onClose={this.handleImageCancel}
                  disabled={moderation}
                />
                <div className={styles.wrapper}>
                  <InputField
                    name="title"
                    type="text"
                    labelText={t('scenes.public.newPost.form.title')}
                    errors={errors}
                    touched={touched}
                    className={styles['left-input']}
                    disabled={moderation}
                  />
                  <InputField
                    name="postTopicId"
                    type="select"
                    labelText={t('scenes.public.newPost.form.topic')}
                    options={this.props.postTopics}
                    value={values.postTopicId}
                    errors={errors}
                    touched={touched}
                    className={styles['right-input']}
                    errorLabel
                    onChange={this.handleSelect}
                    disabled={moderation}
                  />
                </div>
                <InputField
                  name="content"
                  type="textarea"
                  labelText={t('scenes.public.newPost.form.content')}
                  errors={errors}
                  touched={touched}
                  disabled={moderation}
                />
                <div className={styles.downer}>
                  <div className={styles.left}>
                    <FileUpload
                      name="attachments"
                      labelText={t('scenes.public.newPost.form.attachments')}
                      selected={this.state.attachmentsPreview}
                      multiple
                      onChange={this.handleAttachmentsSelect}
                      onDeleteSelected={this.handleAttachmentsUnselect}
                      disabled={moderation}
                    />
                  </div>
                  <div className={styles.right}>
                    {this.props.user.companyAdmin && (
                      <InputField
                        name="asCompany"
                        type="checkbox"
                        labelText={t('scenes.public.newPost.form.asCompany')}
                        value={values.asCompany}
                        errors={errors}
                        touched={touched}
                        disabled={moderation}
                      />
                    )}
                    <InputField
                      name="subscription"
                      type="checkbox"
                      labelText={t('scenes.public.newPost.form.interested')}
                      value={values.subscription}
                      errors={errors}
                      touched={touched}
                      disabled={moderation}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.props.moderation ? (
              <footer>
                <FormGroup className={styles.buttons}>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.moderatePost('decline')}
                    disabled={isFetching}
                    outline
                  >
                    {t('scenes.public.newPost.form.decline')}
                  </Button>
                </FormGroup>
                <FormGroup className={styles.buttons}>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => this.props.moderatePost('accept')}
                    disabled={isFetching}
                  >
                    {t('scenes.public.newPost.form.accept')}
                  </Button>
                </FormGroup>
              </footer>
            ) : (
              <footer>
                <FormGroup className={styles.buttons}>
                  <Button onClick={this.handleCancel} color="secondary" outline>
                    {t('scenes.public.newPost.form.cancel')}
                  </Button>
                </FormGroup>
                <FormGroup className={styles.buttons}>
                  <Button type="submit" color="primary" disabled={isFetching}>
                    {t('scenes.public.newPost.form.submit')}
                  </Button>
                </FormGroup>
              </footer>
            )}
          </Form>
          {moderation && (
            <div className={styles['right-arrow']}>
              <Button
                className={styles.icon}
                onClick={() => this.props.paginate('next')}
                color="link"
              >
                <i className="fas fa-arrow-right" />
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

PostModal.propTypes = {
  moderation: PropTypes.bool,
  paginate: PropTypes.func,
  moderatePost: PropTypes.func,
  post: PropTypes.object,
};

PostModal.defaultProps = {
  moderation: false,
  post: { attachmentUrls: [] },
};

const mapStateToProps = state => ({
  user: state.session.user,
  postTopics: state.post.postTopics,
  isFetching: state.post.isFetching,
  apiErrors: state.post.errors,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => props.post || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.banner === 'undefined') {
        delete values.banner;
      }

      const user = formikBag.props.user;
      let authorId;
      let authorType;
      if (
        values.asCompany &&
        user.companyAdmin &&
        user.company &&
        user.company.id
      ) {
        authorId = user.company.id;
        authorType = 'Company';
      } else {
        authorId = user.id;
        authorType = 'User';
      }

      !formikBag.props.moderation &&
        formikBag.props.dispatch(
          postActions.createPost({ ...values, authorId, authorType })
        );
    },

    displayName: 'PostFrom',
  })
)(PostModal);
