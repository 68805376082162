import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { Sidebar } from 'components/Sidebar';
import { InputField } from 'components/Form';
import * as forgotPasswordActions from './actions';

import styles from './styles.module.scss';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('email.invalid')
    .required('required'),
});

class ForgotPassword extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.props.resetForm();
    }
  }

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <article className="public">
        <Sidebar
          name="forgot-password"
          titleText={t('scenes.public.forgotPassword.sidebar.title')}
          subtitleText={t('scenes.public.forgotPassword.sidebar.subtitle')}
        />
        <section className="public-wrapper">
          <div className={styles['forgot-password-form']}>
            <div className={styles.description}>
              <p>{t('scenes.public.forgotPassword.text1')}</p>
              <p>{t('scenes.public.forgotPassword.text2')}</p>
            </div>
            <Form className="form">
              <InputField
                name="email"
                type="email"
                placeholder={t('scenes.public.forgotPassword.form.email')}
                errors={errors}
                touched={touched}
                className={styles['email-field']}
              />
              <div className={styles.buttons}>
                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isFetching}
                    block
                  >
                    {t('scenes.public.forgotPassword.form.submit')}
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Link to="/login">
                    <Button color="secondary" block outline>
                      {t('scenes.public.forgotPassword.form.cancel')}
                    </Button>
                  </Link>
                </FormGroup>
              </div>
            </Form>
          </div>
        </section>
      </article>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.forgotPassword.isFetching,
  apiErrors: state.forgotPassword.errors,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.dispatch(
        forgotPasswordActions.forgotPassword({
          values: values,
          locale: formikBag.props.language,
        })
      );
    },

    displayName: 'ForgotPasswordForm',
  })
)(ForgotPassword);
