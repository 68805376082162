import { call, put, takeLatest } from 'redux-saga/effects';
import { AnswerTypes } from 'services/api';
import {
  updateAnswerType,
  updateAnswerTypeSuccess,
  updateAnswerTypeFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* updateAnswerTypeSaga({ payload: answerType }) {
  try {
    const data = yield call(AnswerTypes.updateAnswerType, answerType.id, {
      answerType,
    });
    yield put(updateAnswerTypeSuccess(data));
    yield put(
      notificationActions.addNotification([
        { type: 'success', message: { code: 'saved', resource: 'changes' } },
      ])
    );
  } catch (e) {
    yield put(updateAnswerTypeFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(updateAnswerType, updateAnswerTypeSaga);
