import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from './actions';
import { Spinner, Button } from 'reactstrap';
import { PostTopic } from './components/PostTopic';
import { PostTopicForm } from './components/PostTopicForm';

import styles from './styles.module.scss';

class PostTopics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createForm: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.getPostTopics());
  }

  handleCreate = values => {
    this.props.dispatch(actions.createPostTopic(values));
    this.setState({ createForm: false });
  };

  toggleCreateForm = () => {
    this.setState({
      createForm: !this.state.createForm,
    });
  };

  render() {
    const { isFetching, postTopics, t } = this.props;
    return (
      <div className={styles['post-topics-wrapper']}>
        <h1>{t('scenes.admin.postTopics.title')}</h1>
        <div className={styles['post-topics']}>
          {postTopics.length
            ? postTopics.map(postTopic => (
                <div className={styles.topic} key={postTopic.id}>
                  <PostTopic postTopic={postTopic} isFetching={isFetching} />
                </div>
              ))
            : postTopics.length !== 0 && <Spinner color="primary" />}

          <div className={styles.topic}>
            {this.state.createForm ? (
              <PostTopicForm
                onSubmit={this.handleCreate}
                onCancel={() => this.toggleCreateForm()}
              />
            ) : (
              <Button
                color="link"
                className={styles['remove-button']}
                onClick={this.toggleCreateForm}
              >
                <span className={styles.add}>
                  <i className="fas fa-plus" />
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.postTopics.isFetching,
  apiErrors: state.postTopics.errors,
  postTopics: state.postTopics.postTopics,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(PostTopics);
