import { call, take, put } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  registration,
  registrationSuccess,
  registrationFailure,
} from './actions';
import { actions as notificationActions } from 'components/Notification';

function convertKey(key) {
  return key.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

function* registrationSaga() {
  while (true) {
    const { payload } = yield take(registration);
    try {
      const formData = new FormData();
      Object.entries(payload.values).forEach(([key, value]) => {
        if (value !== '') {
          formData.append(`user[${convertKey(key)}]`, value);
        }
      });
      formData.append('locale', payload.locale);
      const { data } = yield call(Users.signUp, formData);
      yield put(registrationSuccess(data));
      yield put(
        notificationActions.addNotification([
          { type: 'success', message: { code: 'registered' } },
        ])
      );
    } catch (e) {
      yield put(registrationFailure(e.data));
      yield put(
        notificationActions.addNotification(
          e.data.errors.map(error => ({ type: 'error', message: error.code }))
        )
      );
    }
  }
}

export default registrationSaga;
