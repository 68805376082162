import React from 'react';

import programme_logo_en from 'assets/images/programme/programme_logo_en.png';
import programme_logo_hu from 'assets/images/programme/programme_logo_hu.png';
import programme_logo_sr from 'assets/images/programme/programme_logo_sr.png';
import programme_slogan_en from 'assets/images/programme/programme_slogan_en.png';
import programme_slogan_hu from 'assets/images/programme/programme_slogan_hu.png';
import programme_slogan_sr from 'assets/images/programme/programme_slogan_sr.png';
import eu_emble_en from 'assets/images/programme/eu_emble_en.png';
import eu_emble_hu from 'assets/images/programme/eu_emble_hu.png';
import eu_emble_sr from 'assets/images/programme/eu_emble_sr.png';

import styles from './styles.module.scss';

class ProgrammeLogos extends React.Component {
  render() {
    const { language } = this.props;

    switch (language) {
      case 'en':
      default:
        return (
          <div className={styles.logos}>
            <div className={styles['programme-logo']}>
              <img src={programme_logo_en} alt="Programme logo" />
            </div>
            <div className={styles['eu-emble']}>
              <img src={eu_emble_en} alt="EU emble" />
            </div>
            <div className={styles['programme-slogan']}>
              <img src={programme_slogan_en} alt="Programme slogan" />
            </div>
          </div>
        );
      case 'hu':
        return (
          <div className={styles.logos}>
            <div className={styles['programme-logo']}>
              <img src={programme_logo_hu} alt="Programme logo" />
            </div>
            <div className={styles['eu-emble']}>
              <img src={eu_emble_hu} alt="EU emble" />
            </div>
            <div className={styles['programme-slogan']}>
              <img src={programme_slogan_hu} alt="Programme slogan" />
            </div>
          </div>
        );
      case 'sr':
        return (
          <div className={styles.logos}>
            <div className={styles['programme-logo']}>
              <img src={programme_logo_sr} alt="Programme logo" />
            </div>
            <div className={styles['eu-emble']}>
              <img src={eu_emble_sr} alt="EU emble" />
            </div>
            <div className={styles['programme-slogan']}>
              <img src={programme_slogan_sr} alt="Programme slogan" />
            </div>
          </div>
        );
    }
  }
}

export default ProgrammeLogos;
