import { createAction } from 'redux-actions';

export const updateUserProfile = createAction('UPDATE_USER_PROFILE_REQUEST');
export const updateUserProfileSuccess = createAction(
  'UPDATE_USER_PROFILE_SUCCESS'
);
export const updateUserProfileFailure = createAction(
  'UPDATE_USER_PROFILE_FAILURE'
);

export const deleteProfile = createAction('DELETE_USER_PROFILE_REQUEST');

export default {
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  deleteProfile,
};
