import { createAction } from 'redux-actions';

export const forgotPassword = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordFailure = createAction('FORGOT_PASSWORD_FAILURE');

export default {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
};
