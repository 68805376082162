import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { UsersTable } from './components/UsersTable';
import { UserEdit } from './components/UserEdit';

class Users extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/users" component={UsersTable} />
        <Route path="/admin/users/:number" component={UserEdit} />
      </Switch>
    );
  }
}

export default Users;
