import { handleActions, combineActions } from 'redux-actions';
import {
  posts,
  postsSuccess,
  postsFailure,
  deletePost,
  deletePostSuccess,
  deletePostFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  posts: [],
  activeTopics: [],
  errors: [],
};

const postsReducer = handleActions(
  {
    [combineActions(posts, deletePost)]: state => ({
      ...state,
      isFetching: true,
    }),

    [postsSuccess]: (state, { payload }) => ({
      ...state,
      posts: payload.data,
      activeTopics: payload.activeTopics,
      isFetching: false,
    }),

    [deletePostSuccess]: (state, { payload }) => ({
      ...state,
      posts: state.posts.filter(value => {
        return value.id !== payload;
      }),
      isFetching: false,
    }),

    [combineActions(postsFailure, deletePostFailure)]: (
      state,
      { payload }
    ) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default postsReducer;
