import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as ReactstrapModal,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import styles from './styles.module.scss';

class ConfirmModal extends React.Component {
  render() {
    if (!this.props.modalOpen) {
      return null;
    }

    return (
      <ReactstrapModal
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        className={`${styles.modal} ${this.props.className}`}
        backdrop="static"
        centered
      >
        <div className={styles['modal-content']}>
          <div className="modal-close" onClick={this.props.toggleModal}>
            <i className="fas fa-times" />
          </div>
          <ModalBody className={styles['modal-body']}>
            {this.props.children}
          </ModalBody>
          <ModalFooter className={styles['modal-footer']}>
            <Button
              color={this.props.secondaryColor}
              onClick={this.props.toggleModal}
              outline
            >
              {this.props.secondaryButtonText}
            </Button>
            <Button
              color={this.props.primaryColor}
              onClick={this.props.onModalConfirm}
            >
              {this.props.primaryButtonText}
            </Button>
          </ModalFooter>
        </div>
      </ReactstrapModal>
    );
  }
}

ConfirmModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onModalConfirm: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  primaryColor: 'danger',
  secondaryColor: 'secondary',
  primaryButtonText: 'Yes',
  secondaryButtonText: 'Cancel',
};

export default ConfirmModal;
