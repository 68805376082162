import { handleActions, combineActions } from 'redux-actions';
import {
  comments,
  commentsSuccess,
  commentsFailure,
  addComment,
  addCommentSuccess,
  addCommentFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  comments: {},
  errors: [],
};

const commentsReducer = handleActions(
  {
    [combineActions(comments, addComment)]: state => ({
      ...state,
      isFetching: true,
    }),

    [commentsSuccess]: (state, { payload }) => ({
      ...state,
      comments: payload.postId
        ? {
            ...state.comments,
            [payload.postId]: payload.data,
          }
        : { ...state.comments },
      isFetching: false,
    }),

    [addCommentSuccess]: (state, { payload }) => ({
      ...state,
      comments: {
        ...state.comments,
        [payload.postId]: payload.data,
      },
      isFetching: false,
    }),

    [combineActions(commentsFailure, addCommentFailure)]: (
      state,
      { payload }
    ) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default commentsReducer;
