import { createAction } from 'redux-actions';

export const posts = createAction('POSTS_REQUEST');
export const postsSuccess = createAction('POSTS_SUCCESS');
export const postsFailure = createAction('POSTS_FAILURE');
export const deletePost = createAction('DELETE_POST_REQUEST');
export const deletePostSuccess = createAction('DELETE_POST_SUCCESS');
export const deletePostFailure = createAction('DELETE_POST_FAILURE');

export default {
  posts,
  postsSuccess,
  postsFailure,
  deletePost,
  deletePostSuccess,
  deletePostFailure,
};
