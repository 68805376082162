import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Competence } from 'components/Competence';
import actions from './actions';
import { Spinner, Form, Button } from 'reactstrap';

import styles from './styles.module.scss';

class Competences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
    };
  }

  componentDidMount() {
    this.props.language &&
      this.props.dispatch(actions.getCompetences(this.props.language));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.competenceAnswers !== this.props.competenceAnswers) {
      this.setState({
        answers: JSON.parse(JSON.stringify(this.props.competenceAnswers)),
      });
    }

    if (
      prevProps.language !== this.props.language &&
      this.props.language !== ''
    ) {
      this.props.dispatch(actions.getCompetences(this.props.language));
    }
  }

  handleAnswerChange = e => {
    let answers = this.state.answers;
    let competenceId = parseInt(e.target.name.split('_')[1]);
    let answerId = parseInt(e.target.value);

    let answer = answers.find(obj => {
      return obj.competenceId === competenceId;
    });

    if (answer) {
      answers = answers.filter(e => e !== answer);
      answer.answerOptionId = answerId;
      answers.push(answer);
    } else {
      let newAnswer = { competenceId: competenceId, answerOptionId: answerId };
      answers.push(newAnswer);
    }

    this.setState({
      answers: answers,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let competenceAnswers = { competenceAnswers: this.state.answers };

    this.props.dispatch(actions.updateCompetenceAnswers(competenceAnswers));
  };

  handleCancel = e => {
    e.preventDefault();
    this.setState({
      answers: JSON.parse(JSON.stringify(this.props.competenceAnswers)),
    });
  };

  render() {
    const { competences, competenceGroups, isFetching, t } = this.props;
    return (
      <div>
        <h1>{t('scenes.public.competences.title')}</h1>
        {competences.length ? (
          <Form onSubmit={this.handleSubmit} className={styles['form-wrapper']}>
            {competenceGroups.map(competenceGroup => (
              <div key={competenceGroup.id} className={styles.competences}>
                <h4 className={styles['competence-group-name']}>
                  {competenceGroup.name}
                </h4>
                {competences
                  .filter(
                    competence =>
                      competence.competenceGroupId === competenceGroup.id
                  )
                  .map(competence => (
                    <Competence
                      key={competence.id}
                      competence={competence}
                      answers={this.state.answers}
                      answerTypes={this.props.answerTypes}
                      onAnswerChange={this.handleAnswerChange}
                    />
                  ))}
              </div>
            ))}
            <div className={styles.buttons}>
              <Button
                type="submit"
                color="primary"
                disabled={isFetching}
                block
                className={styles.button}
              >
                {t('scenes.public.competences.submit')}
              </Button>
              <Button
                onClick={this.handleCancel}
                color="secondary"
                disabled={isFetching}
                className={styles.button}
                block
                outline
              >
                {t('scenes.public.competences.cancel')}
              </Button>
            </div>
          </Form>
        ) : (
          <Spinner color="primary" />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.competences.isFetching,
  apiErrors: state.competences.errors,
  competences: state.competences.competences,
  competenceGroups: state.competences.competenceGroups,
  answerTypes: state.competences.answerTypes,
  competenceAnswers: state.competences.competenceAnswers,
  language: state.language.language,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Competences);
