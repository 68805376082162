import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

import styles from './styles.module.scss';

class List extends React.Component {
  render() {
    return (
      <div className={styles.list}>
        {this.props.comments && this.props.comments.length > 0 ? (
          this.props.comments.map(comment => (
            <div className={styles.comment} key={comment.id}>
              <div className={styles.avatar}>
                <div className={styles.content}>
                  {comment.userImage === '' ? (
                    <span className={styles.icon}>
                      <i className="fas fa-user" />
                    </span>
                  ) : (
                    <img src={comment.userImage} alt="Avatar" />
                  )}
                </div>
              </div>
              <div className={styles.content}>
                <p className={styles.body}>
                  <span className={styles.name}>{comment.userName}</span>
                  {comment.body}
                </p>
                <span className={styles.date}>{comment.date}</span>
              </div>
            </div>
          ))
        ) : this.props.comments && this.props.comments.length !== 0 ? (
          <Spinner color="primary" />
        ) : (
          <div className={styles['no-comments']}>
            <i className="far fa-comments" />
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  comments: PropTypes.array,
};

export default List;
