import { get, post, del } from '../rest';

export default {
  index: params => get('/posts', params),
  comments: id => get(`/posts/${id}/comments`),
  addComment: (id, params) => post(`/posts/${id}/comments`, params),
  subscriptions: id => get(`/posts/${id}/subscriptions`),
  addSubscription: (id, params) => post(`/posts/${id}/subscriptions`, params),
  create: params => post('/posts', params),
  delete: id => del(`/posts/${id}`),
  getPosts: params => get('/admin/posts', params),
  acceptPost: id => get(`/admin/posts/${id}/accept`),
  declinePost: id => get(`/admin/posts/${id}/decline`),
};
