import { call, put, takeLatest } from 'redux-saga/effects';
import { AnswerTypes } from 'services/api';
import {
  getAnswerTypes,
  getAnswerTypesSuccess,
  getAnswerTypesFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getAnswerTypesSaga() {
  try {
    const data = yield call(AnswerTypes.getAnswerTypes);
    yield put(getAnswerTypesSuccess(data));
  } catch (e) {
    yield put(getAnswerTypesFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(getAnswerTypes, getAnswerTypesSaga);
