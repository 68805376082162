import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'reactstrap/es/Spinner';
import { CompanyForm } from './CompanyForm';
import { ManageEmployees } from 'components/ManageEmployees';
import * as adminCompaniesActions from '../../actions';

class CompanyEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      adminCompaniesActions.adminCompany(this.props.match.params.number)
    );
  }

  handlePageChange = () => {
    this.setState({ form: !this.state.form });
  };

  handleSubmit = values => {
    this.props.dispatch(adminCompaniesActions.adminUpdateCompany(values));
  };

  render() {
    return this.props.company &&
      this.props.company.id &&
      this.props.company.id.toString() === this.props.match.params.number ? (
      this.state.form ? (
        <CompanyForm
          company={this.props.company}
          isFetching={this.props.isFetching}
          apiErrors={this.props.apiErrors}
          onSubmit={this.handleSubmit}
          onPageChange={this.handlePageChange}
        />
      ) : (
        <ManageEmployees
          company={this.props.company}
          onCancel={this.handlePageChange}
        />
      )
    ) : (
      <Spinner color="primary" />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.adminCompanies.isFetching,
  apiErrors: state.adminCompanies.errors,
  company: state.adminCompanies.company,
});

export default connect(mapStateToProps)(CompanyEdit);
