import { handleActions } from 'redux-actions';
import { addNotification, removeNotification } from './actions';

const INITIAL_STATE = {
  messages: [],
};

const notificationReducer = handleActions(
  {
    [addNotification]: (state, { payload: messages }) => ({
      ...state,
      messages: [...state.messages, ...messages],
    }),

    [removeNotification]: state => ({
      ...state,
      messages: [],
    }),
  },
  INITIAL_STATE
);

export default notificationReducer;
