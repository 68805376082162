import { take, put } from 'redux-saga/effects';
import store from 'store2';
import {
  selectLanguage,
  selectLanguageSuccess,
  selectLanguageFailure,
} from './actions';

function* languageSelectorSaga() {
  while (true) {
    const { payload } = yield take(selectLanguage);
    try {
      if (payload) {
        store.set('lng', payload);
      }
      yield put(selectLanguageSuccess(store('lng')));
    } catch (e) {
      yield put(selectLanguageFailure(e.data));
    }
  }
}

export default languageSelectorSaga;
