import { call, take, put } from 'redux-saga/effects';
import { Users } from 'services/api';
import { users, usersSuccess, usersFailure } from './actions';
import { actions as notificationActions } from 'components/Notification';

function* usersSaga() {
  while (true) {
    const { payload } = yield take(users);
    try {
      const { data } = yield call(Users.all, payload);
      yield put(usersSuccess(data));
    } catch (e) {
      yield put(usersFailure(e.data));
      yield put(
        notificationActions.addNotification(
          e.data.errors.map(error => ({ type: 'error', message: error.code }))
        )
      );
    }
  }
}

export default usersSaga;
