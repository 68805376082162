import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  getAdminPosts,
  getAdminPostsSuccess,
  getAdminPostsFailure,
} from '../../actions';

function* getPostsSaga() {
  try {
    const data = yield call(Posts.getPosts);
    yield put(getAdminPostsSuccess(data));
  } catch (e) {
    yield put(getAdminPostsFailure(e.data));
  }
}

export default () => takeLatest(getAdminPosts, getPostsSaga);
