import { createAction } from 'redux-actions';

export const getEmployees = createAction('GET_EMPLOYEES_REQUEST');
export const getEmployeesSuccess = createAction('GET_EMPLOYEES_SUCCESS');
export const getEmployeesFailure = createAction('GET_EMPLOYEES_FAILURE');

export const addEmployee = createAction('ADD_EMPLOYEE_REQUEST');
export const addEmployeeSuccess = createAction('ADD_EMPLOYEE_SUCCESS');
export const addEmployeeFailure = createAction('ADD_EMPLOYEE_FAILURE');

export const updateEmployee = createAction('UPDATE_EMPLOYEE_REQUEST');
export const updateEmployeeSuccess = createAction('UPDATE_EMPLOYEE_SUCCESS');
export const updateEmployeeFailure = createAction('UPDATE_EMPLOYEE_FAILURE');

export const removeEmployee = createAction('REMOVE_EMPLOYEE_REQUEST');
export const removeEmployeeSuccess = createAction('REMOVE_EMPLOYEE_SUCCESS');
export const removeEmployeeFailure = createAction('REMOVE_EMPLOYEE_FAILURE');

export default {
  getEmployees,
  getEmployeesSuccess,
  getEmployeesFailure,
  addEmployee,
  addEmployeeSuccess,
  addEmployeeFailure,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFailure,
  removeEmployee,
  removeEmployeeSuccess,
  removeEmployeeFailure,
};
