import { call, put, takeLatest } from 'redux-saga/effects';
import { Companies } from 'services/api';
import { companies, companiesSuccess, companiesFailure } from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getCompaniesSaga(action) {
  try {
    const { data } = yield call(Companies.all, action.payload);
    yield put(companiesSuccess(data));
  } catch (e) {
    yield put(companiesFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(companies, getCompaniesSaga);
