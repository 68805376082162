import { call, put, takeEvery } from 'redux-saga/effects';
import { Posts } from 'services/api';
import { comments, commentsSuccess, commentsFailure } from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getCommentsSaga({ payload }) {
  try {
    const { data } = yield call(Posts.comments, payload);
    yield put(commentsSuccess(data));
  } catch (e) {
    yield put(commentsFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeEvery(comments, getCommentsSaga);
