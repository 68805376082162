import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from './components/Form';
import { List } from './components/List';
import { default as commentActions } from './actions';

import styles from './styles.module.scss';

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
      count: 0,
    };

    this.mql = window.matchMedia('(min-width: 992px)');
  }

  componentDidMount() {
    this.changeView();
    this.mql.addListener(this.changeView);
    this.setState({ count: this.props.count });
  }

  componentDidUpdate(prevProps) {
    prevProps.count !== this.props.count &&
      this.setState({ count: this.props.count });
  }

  componentWillUnmount() {
    this.mql.removeListener(this.changeView);
  }

  changeView = () => {
    if (this.mql.matches) {
      this.props.count > 0 &&
        this.props.dispatch(commentActions.comments(this.props.postId));
      this.setState({ listOpen: true });
    } else {
      this.setState({ listOpen: false });
    }
  };

  handleListOpenChange = () => {
    !this.state.listOpen &&
      this.state.count > 0 &&
      !this.props.comments &&
      this.props.dispatch(commentActions.comments(this.props.postId));
    this.setState({ listOpen: !this.state.listOpen });
  };

  handleSubmit = values => {
    this.props.dispatch(
      commentActions.addComment({ postId: this.props.postId, comment: values })
    );
    this.setState({ count: this.state.count + 1 });
  };

  render() {
    return (
      <div className={styles.comment}>
        <Form
          count={this.state.count}
          isFetching={this.props.isFetching}
          apiErrors={this.props.apiErrors}
          onListOpenChange={this.handleListOpenChange}
          onSubmit={this.handleSubmit}
        />
        {this.state.listOpen && (
          <List comments={this.props.comments} listOpen={this.state.listOpen} />
        )}
      </div>
    );
  }
}

Comment.propTypes = {
  count: PropTypes.number,
  comments: PropTypes.array,
  postId: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.comment.isFetching,
  apiErrors: state.comment.errors,
  comments: state.comment.comments[ownProps.postId],
});

export default connect(mapStateToProps)(Comment);
