import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { CompetenceFilter } from 'components/CompetenceFilter';
import { getDashboard } from './actions';

import styles from './styles.module.scss';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.dispatch(getDashboard());
  }

  handleSearch = () => {
    this.props.history.push('/admin/competences');
  };

  render() {
    const { dashboard } = this.props;
    return (
      <div className={styles['dashboard-wrapper']}>
        <h1 className={styles.title}>Dashboard</h1>
        <div className={styles.wrapper}>
          <CompetenceFilter onSubmit={this.handleSearch} />
          {dashboard ? (
            <div className={styles.dashboard}>
              <div className={styles.box}>
                <div className={styles.inner}>
                  <span className={styles.number}>
                    {dashboard.waitingForModeration}
                  </span>
                  <p className={styles.text}>posts waiting for moderation</p>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.inner}>
                  <span className={styles.number}>
                    {dashboard.answered}/{dashboard.allUsers}
                  </span>
                  <p className={styles.text}>
                    users answered at least one competence
                  </p>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.inner}>
                  <span className={styles.number}>{dashboard.allUsers}</span>
                  <p className={styles.text}>users in the system</p>
                </div>
                <div className={styles.inner}>
                  <span className={styles.number}>
                    {dashboard.allCompanies}
                  </span>
                  <p className={styles.text}>companies in the system</p>
                </div>
              </div>
            </div>
          ) : (
            <Spinner color="primary" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard.dashboard,
});

export default connect(mapStateToProps)(Dashboard);
