import { handleActions } from 'redux-actions';
import {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  errors: [],
};

const forgotPasswordReducer = handleActions(
  {
    [forgotPassword]: state => ({
      ...state,
      isFetching: true,
    }),

    [forgotPasswordSuccess]: state => ({
      ...state,
      isFetching: false,
    }),

    [forgotPasswordFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default forgotPasswordReducer;
