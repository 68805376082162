import { handleActions, combineActions } from 'redux-actions';
import {
  createPost,
  createPostSuccess,
  createPostFailure,
  getPostTopics,
  getPostTopicsSuccess,
  getPostTopicsFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  isTopicsFetching: false,
  postTopics: [],
  errors: [],
};

const createPostReducer = handleActions(
  {
    [createPost]: state => ({
      ...state,
      isFetching: true,
    }),

    [getPostTopics]: state => ({
      ...state,
      isTopicsFetching: true,
    }),

    [createPostSuccess]: state => ({
      ...state,
      isFetching: false,
    }),

    [getPostTopicsSuccess]: (state, { payload }) => ({
      ...state,
      postTopics: payload.data,
      isTopicsFetching: false,
    }),

    [combineActions(createPostFailure, getPostTopicsFailure)]: (
      state,
      { payload }
    ) => ({
      ...state,
      isFetching: false,
      isTopicsFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default createPostReducer;
