import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { Sidebar } from 'components/Sidebar';
import { InputField } from 'components/Form';
import * as resetPasswordActions from './actions';

import styles from './styles.module.scss';

const initialValues = {
  password: '',
  passwordConfirmation: '',
  token: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, 'password')
    .required('required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'passwordConfirmationMatch')
    .required('required'),
});

class ResetPassword extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.resetForm();
      this.props.history.push('/login');
    }
  }

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <article className="public">
        <Sidebar
          name="reset-password"
          titleText={t('scenes.public.resetPassword.sidebar.title')}
          subtitleText={t('scenes.public.resetPassword.sidebar.subtitle')}
        />
        <section className="public-wrapper">
          <div className={styles['reset-password-form']}>
            <Form className="form">
              <InputField
                name="password"
                type="password"
                labelText={t('scenes.public.resetPassword.form.password')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="passwordConfirmation"
                type="password"
                labelText={t(
                  'scenes.public.resetPassword.form.passwordConfirmation'
                )}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <div className={styles.buttons}>
                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isFetching}
                    block
                  >
                    {t('scenes.public.resetPassword.form.submit')}
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Link to="/login">
                    <Button color="secondary" block outline>
                      {t('scenes.public.resetPassword.form.cancel')}
                    </Button>
                  </Link>
                </FormGroup>
              </div>
            </Form>
          </div>
        </section>
      </article>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.resetPassword.isFetching,
  apiErrors: state.resetPassword.errors,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps),
  withFormik({
    mapPropsToValues: props => ({
      ...initialValues,
      token:
        props.location.search && props.location.search.replace('?token=', ''),
    }),

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.dispatch(resetPasswordActions.resetPassword(values));
    },

    displayName: 'ResetPasswordForm',
  })
)(ResetPassword);
