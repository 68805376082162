import { handleActions } from 'redux-actions';
import {
  subscriptions,
  subscriptionsSuccess,
  subscriptionsFailure,
  addSubscription,
  addSubscriptionSuccess,
  addSubscriptionFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  addIsFetching: false,
  subscriptions: {},
  errors: [],
};

const subscriptionsReducer = handleActions(
  {
    [subscriptions]: state => ({
      ...state,
      isFetching: true,
    }),

    [addSubscription]: state => ({
      ...state,
      addIsFetching: true,
    }),

    [subscriptionsSuccess]: (state, { payload }) => ({
      ...state,
      subscriptions: payload.postId
        ? {
            ...state.subscriptions,
            [payload.postId]: payload.data,
          }
        : { ...state.subscriptions },
      isFetching: false,
    }),

    [addSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      subscriptions: {
        ...state.subscriptions,
        [payload.postId]: payload.data,
      },
      addIsFetching: false,
    }),

    [subscriptionsFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [addSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      addIsFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default subscriptionsReducer;
