import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import styles from './styles.module.scss';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
  }

  handleClick = () => {
    !this.props.disabled && this.fileInput.current.click();
  };

  render() {
    const {
      name,
      className,
      previewImage,
      onClose,
      onChange,
      errorMessage,
      uploadName,
      t,
      disabled,
    } = this.props;

    if (errorMessage) {
      return (
        <div
          onClick={this.handleClick}
          className={
            styles['preview-image'] +
            ' ' +
            styles['error-message'] +
            ' ' +
            className
          }
        >
          <div className={styles['image-upload-content']}>
            <input
              type="file"
              name={name}
              ref={this.fileInput}
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <span className={styles['error-icon']}>
              <i className="fas fa-exclamation-circle" />
            </span>
            <span className={styles['icon-text']}>{errorMessage}</span>
          </div>
        </div>
      );
    }

    if (previewImage) {
      return (
        <div className={styles['preview-image'] + ' ' + className}>
          <div className={styles['image-upload-content']}>
            {!disabled && (
              <span className={styles.close} onClick={onClose}>
                <i className="far fa-times-circle" />
              </span>
            )}
            <img src={previewImage} alt="Preview" />
          </div>
        </div>
      );
    }
    return (
      <FormGroup
        onClick={this.handleClick}
        className={`${styles['image-upload-wrapper']} ${className}`}
      >
        <div className={styles['image-upload-content']}>
          <input
            type="file"
            name={name}
            ref={this.fileInput}
            style={{ display: 'none' }}
            onChange={onChange}
          />
          <span className={styles.icon}>
            <i className="far fa-file-image" />
          </span>
          <span className={styles['icon-text']}>
            {t('forms.labels.imageUpload', {
              image: t(`forms.labels.${uploadName}`),
            })}
          </span>
        </div>
      </FormGroup>
    );
  }
}

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  uploadName: PropTypes.string,
};

ImageUpload.defaultProps = {
  disabled: false,
  uploadName: 'image',
};

export default withTranslation('common')(ImageUpload);
