import { createAction } from 'redux-actions';

export const getAdminCompetences = createAction(
  'ADMIN_COMPETENCE_LIST_REQUEST'
);
export const getAdminCompetencesSuccess = createAction(
  'ADMIN_COMPETENCE_LIST_SUCCESS'
);
export const getAdminCompetencesFailure = createAction(
  'ADMIN_COMPETENCE_LIST_FAILURE'
);
export const createCompetenceGroup = createAction(
  'COMPETENCE_GROUP_CREATE_REQUEST'
);
export const createCompetenceGroupSuccess = createAction(
  'COMPETENCE_GROUP_CREATE_SUCCESS'
);
export const createCompetenceGroupFailure = createAction(
  'COMPETENCE_GROUP_CREATE_FAILURE'
);
export const updateCompetenceGroup = createAction(
  'COMPETENCE_GROUP_UPDATE_REQUEST'
);
export const updateCompetenceGroupSuccess = createAction(
  'COMPETENCE_GROUP_UPDATE_SUCCESS'
);
export const updateCompetenceGroupFailure = createAction(
  'COMPETENCE_GROUP_UPDATE_FAILURE'
);
export const deleteCompetenceGroup = createAction(
  'COMPETENCE_GROUP_DELETE_REQUEST'
);
export const deleteCompetenceGroupSuccess = createAction(
  'COMPETENCE_GROUP_DELETE_SUCCESS'
);
export const deleteCompetenceGroupFailure = createAction(
  'COMPETENCE_GROUP_DELETE_FAILURE'
);
export const createCompetence = createAction('COMPETENCE_CREATE_REQUEST');
export const createCompetenceSuccess = createAction(
  'COMPETENCE_CREATE_SUCCESS'
);
export const createCompetenceFailure = createAction(
  'COMPETENCE_CREATE_FAILURE'
);
export const updateCompetence = createAction('COMPETENCE_UPDATE_REQUEST');
export const updateCompetenceSuccess = createAction(
  'COMPETENCE_UPDATE_SUCCESS'
);
export const updateCompetenceFailure = createAction(
  'COMPETENCE_UPDATE_FAILURE'
);
export const deleteCompetence = createAction('COMPETENCE_DELETE_REQUEST');
export const deleteCompetenceSuccess = createAction(
  'COMPETENCE_DELETE_SUCCESS'
);
export const deleteCompetenceFailure = createAction(
  'COMPETENCE_DELETE_FAILURE'
);

export default {
  getAdminCompetences,
  getAdminCompetencesSuccess,
  getAdminCompetencesFailure,
  createCompetence,
  createCompetenceSuccess,
  createCompetenceFailure,
  updateCompetence,
  updateCompetenceSuccess,
  updateCompetenceFailure,
  deleteCompetence,
  deleteCompetenceSuccess,
  deleteCompetenceFailure,
  createCompetenceGroup,
  createCompetenceGroupSuccess,
  createCompetenceGroupFailure,
  updateCompetenceGroup,
  updateCompetenceGroupSuccess,
  updateCompetenceGroupFailure,
  deleteCompetenceGroup,
  deleteCompetenceGroupSuccess,
  deleteCompetenceGroupFailure,
};
