import { takeLatest, put } from 'redux-saga/effects';
import {
  clearFilter,
  clearFilterSuccess,
  clearFilterFailure,
} from '../../actions';

function* clearFilterSaga() {
  try {
    yield put(clearFilterSuccess());
  } catch (e) {
    yield put(clearFilterFailure(e.data));
  }
}

export default () => takeLatest(clearFilter, clearFilterSaga);
