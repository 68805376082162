import { combineActions, handleActions } from 'redux-actions';
import {
  companies,
  companiesSuccess,
  companiesFailure,
  companiesPublic,
  companiesPublicSuccess,
  companiesPublicFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  companies: [],
  errors: [],
};

const CompaniesReducer = handleActions(
  {
    [combineActions(companies, companiesPublic)]: state => ({
      ...state,
      isFetching: true,
    }),

    [combineActions(companiesSuccess, companiesPublicSuccess)]: (
      state,
      { payload: companies }
    ) => ({
      ...state,
      isFetching: false,
      companies: companies.data,
    }),

    [combineActions(companiesFailure, companiesPublicFailure)]: (
      state,
      { payload: errors }
    ) => ({
      ...state,
      isFetching: false,
      errors: errors && errors.errors,
    }),
  },
  INITIAL_STATE
);

export default CompaniesReducer;
