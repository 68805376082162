import { handleActions } from 'redux-actions';
import {
  registration,
  registrationSuccess,
  registrationFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  user: {},
  errors: [],
};

const RegistrationReducer = handleActions(
  {
    [registration]: state => ({
      ...state,
      isFetching: true,
    }),

    [registrationSuccess]: (state, { payload: user }) => ({
      ...state,
      isFetching: false,
      user,
    }),

    [registrationFailure]: (state, { payload: errors }) => ({
      ...state,
      isFetching: false,
      errors: errors && errors.errors,
    }),
  },
  INITIAL_STATE
);

export default RegistrationReducer;
