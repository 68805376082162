import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';

import styles from './styles.module.scss';

const initialValues = {
  name: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('required'),
});

class PostTopicForm extends React.Component {
  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  handleClick = () => {
    this.props.onCancel();
  };

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <div className={styles['form-wrapper']}>
        <Form className={styles.form}>
          <InputField
            name="name"
            type="text"
            placeholder={t('scenes.admin.postTopics.name')}
            errors={errors}
            touched={touched}
            onChange={this.handleSelect}
            className={styles.inputs}
            errorLabel
          />
          <Button
            type="submit"
            color="primary"
            disabled={isFetching}
            className={styles.buttons}
          >
            {t('scenes.admin.postTopics.submit')}
          </Button>
          <Button
            color="link"
            onClick={this.handleClick}
            className={styles.buttons}
          >
            {t('scenes.admin.postTopics.cancel')}
          </Button>
        </Form>
      </div>
    );
  }
}

PostTopicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => props.postTopic || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.onSubmit(values);
    },

    displayName: 'PostTopicForm',
  })
)(PostTopicForm);
