import { handleActions } from 'redux-actions';
import {
  getAnswerTypes,
  getAnswerTypesSuccess,
  getAnswerTypesFailure,
  createAnswerType,
  createAnswerTypeSuccess,
  createAnswerTypeFailure,
  updateAnswerType,
  updateAnswerTypeSuccess,
  updateAnswerTypeFailure,
  deleteAnswerType,
  deleteAnswerTypeSuccess,
  deleteAnswerTypeFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  answerTypes: [],
  errors: [],
};

const answerTypesReducer = handleActions(
  {
    [getAnswerTypes]: state => ({
      ...state,
      isFetching: true,
    }),

    [getAnswerTypesSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      answerTypes: payload.data.answerTypes,
    }),

    [getAnswerTypesFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [createAnswerType]: state => ({
      ...state,
      isFetching: true,
    }),

    [createAnswerTypeSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      answerTypes: payload.data.answerTypes,
    }),

    [createAnswerTypeFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [updateAnswerType]: state => ({
      ...state,
      isFetching: true,
    }),

    [updateAnswerTypeSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      answerTypes: payload.data.answerTypes,
    }),

    [updateAnswerTypeFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),

    [deleteAnswerType]: state => ({
      ...state,
      isFetching: true,
    }),

    [deleteAnswerTypeSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      answerTypes: payload.data.answerTypes,
    }),

    [deleteAnswerTypeFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default answerTypesReducer;
