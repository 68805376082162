import { createAction } from 'redux-actions';

export const addNotification = createAction('ADD_NOTIFICATION');

export const removeNotification = createAction('REMOVE_NOTIFICATION');

export default {
  addNotification,
  removeNotification,
};
