import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from './actions';
import { Table, Button } from 'reactstrap';
import { PostModal } from 'components/PostModal';

import styles from './styles.module.scss';

class Moderation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      activePost: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.getAdminPosts());
  }

  toggleModal = item => {
    this.setState({
      activePost: this.state.activePost === null ? item : null,
      openModal: !this.state.openModal,
    });
  };

  paginateModal = direction => {
    let index = this.props.posts.indexOf(this.state.activePost);
    let max = this.props.posts.length - 1;

    this.setState({
      activePost:
        direction === 'next'
          ? this.props.posts[index < max ? index + 1 : 0]
          : this.props.posts[index === 0 ? max : index - 1],
    });
  };

  handlePost = type => {
    this.paginateModal('prev');
    this.props.posts.length === 1 && this.toggleModal();
    this.props.dispatch(
      type === 'accept'
        ? actions.acceptPost(this.state.activePost.id)
        : actions.declinePost(this.state.activePost.id)
    );
  };

  handleAccept = id => {
    this.props.dispatch(actions.acceptPost(id));
  };

  handleDecline = id => {
    this.props.dispatch(actions.declinePost(id));
  };

  render() {
    const { posts, t } = this.props;
    return (
      <div>
        <h1>{t('scenes.admin.moderation.title')}</h1>
        {posts.length > 0 && (
          <Table hover responsive className={styles.table}>
            <thead>
              <tr>
                <th className={styles.main}>
                  {t('scenes.admin.moderation.table.title')}
                </th>
                <th className={styles.main}>
                  {t('scenes.admin.moderation.table.author')}
                </th>
                <th className={styles.main}>
                  {t('scenes.admin.moderation.table.topic')}
                </th>
                <th className={styles.actions}>
                  {t('scenes.admin.moderation.table.actions.header')}
                </th>
              </tr>
            </thead>
            <tbody>
              {posts.map(post => (
                <tr key={post.id}>
                  <td className="align-middle">{post.title}</td>
                  <td className="align-middle">{post.authorName}</td>
                  <td className="align-middle">{post.topic}</td>
                  <td>
                    <Button
                      className={styles['nav-link']}
                      onClick={() => this.toggleModal(post)}
                      color="link"
                    >
                      {t('scenes.admin.moderation.table.actions.view')}
                    </Button>
                    <Button
                      className={styles['nav-link']}
                      onClick={() => this.handleAccept(post.id)}
                      color="link"
                    >
                      {t('scenes.admin.moderation.table.actions.accept')}
                    </Button>
                    <Button
                      className={styles['nav-link']}
                      onClick={() => this.handleDecline(post.id)}
                      color="link"
                    >
                      {t('scenes.admin.moderation.table.actions.decline')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {this.state.activePost && (
          <PostModal
            openNewPostModal={this.state.openModal}
            toggleNewPostModal={this.toggleModal}
            moderation={true}
            post={this.state.activePost}
            paginate={this.paginateModal}
            moderatePost={this.handlePost}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.moderation.isFetching,
  apiErrors: state.moderation.errors,
  posts: state.moderation.posts,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Moderation);
