import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { POSITION_OPTIONS, validationOptions } from 'constants/SelectOptions';
import { Button } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';
import { UsersSelect } from 'components/UsersSelect';

import styles from './styles.module.scss';

const initialValues = {
  userId: '',
  position: '',
  admin: false,
};

const validationSchema = Yup.object({
  userId: Yup.string().required('required'),
  position: Yup.string()
    .required('required')
    .oneOf(validationOptions(POSITION_OPTIONS), 'selectOption'),
  admin: Yup.boolean().required('required'),
});

class AddUserForm extends React.Component {
  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <Form className={styles.form}>
        <div className={styles['form-wrapper']}>
          <div className={styles.user}>
            <UsersSelect
              onChange={this.handleSelect}
              className={styles.inputs}
            />
          </div>
          <div className={styles.position}>
            {' '}
            <InputField
              name="position"
              type="select"
              placeholder={t('components.manageEmployees.form.position.label')}
              errors={errors}
              touched={touched}
              options={POSITION_OPTIONS}
              onChange={this.handleSelect}
              className={styles.inputs}
              validate={false}
              errorLabel
            />
          </div>
          <div className={styles.admin}>
            <InputField
              name="admin"
              type="checkbox"
              labelText={t('components.manageEmployees.form.admin')}
              errors={errors}
              touched={touched}
              value={values.admin}
              className={styles.inputs}
              errorLabel
            />
          </div>
          <div className={styles.button}>
            <Button
              className={styles.inputs}
              type="submit"
              color="primary"
              disabled={isFetching}
            >
              {t('components.manageEmployees.form.submit')}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

AddUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.onSubmit(values);
    },

    displayName: 'AddCompanyUserForm',
  })
)(AddUserForm);
