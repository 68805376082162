import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import {
  LEGAL_FORM_OPTIONS,
  POSITION_OPTIONS,
  validationOptions,
} from 'constants/SelectOptions';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';
import { CompaniesSelect } from 'components/CompaniesSelect';
import Users from 'services/api/Users';

import styles from './styles.module.scss';

const initialValues = {
  name: '',
  email: '',
  website: '',
  legalForm: '',
  position: '',
  logo: undefined,
  checkEmail: false,
};

const validationSchema = Yup.object({
  checkEmail: Yup.bool(),
  name: Yup.string().required('required'),
  email: Yup.string()
    .required('required')
    .email('email.invalid')
    .when('checkEmail', {
      is: true,
      then: Yup.string().test('free-email', 'email.taken', value => {
        if (typeof value !== 'undefined') {
          return Users.checkEmail({
            email: value,
            type: 'company',
          })
            .then(({ data: { isOk } }) => isOk)
            .catch(false);
        }
      }),
    }),
  website: Yup.string()
    .matches(/[.]/, 'urlFormat')
    .required('required'),
  legalForm: Yup.string()
    .required('required')
    .oneOf(validationOptions(LEGAL_FORM_OPTIONS), 'selectOption'),
  position: Yup.string()
    .oneOf(validationOptions(POSITION_OPTIONS), 'selectOption')
    .when('checkEmail', {
      is: true,
      then: Yup.string().required('required'),
    }),
  logo: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: (props.company && props.company.logoUrl) || '',
    };
  }

  componentDidMount() {
    if (!Boolean(this.props.company)) {
      this.props.setFieldValue('checkEmail', true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      Object.keys(initialValues).forEach(key => {
        this.props.setFieldTouched(key, false);
      });
    }

    if (
      prevProps.company !== this.props.company &&
      this.props.company === null
    ) {
      Object.keys(initialValues).forEach(key => {
        this.props.setFieldValue(key, '');
        this.props.setFieldTouched(key, false);
      });
    }
  }

  handleImageSelect = e => {
    this.props.setFieldValue('logo', e.target.files[0]);
    this.props.setFieldTouched('logo');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('logo', '');
    this.setState({
      previewImage: '',
    });
  };

  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  handleCompanySelect = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === '') {
      // Reset field values
      this.props.setFieldValue(name, value);

      this.props.setFieldValue('checkEmail', true);

      Object.keys(initialValues).forEach(key => {
        if (key !== 'logo') {
          this.props.setFieldValue(key, '');
          this.props.setFieldTouched(key, false);
        }
      });

      this.setState({
        previewImage: '',
      });
    } else {
      // Set field values to the selected company
      this.props.setFieldValue(name, value);

      this.props.setFieldValue('checkEmail', false);

      const selectedCompany = this.props.companies.find(company => {
        return company.id.toString() === value;
      });

      Object.keys(initialValues).forEach(key => {
        if (key !== 'logo') {
          this.props.setFieldValue(key, selectedCompany[key]);
          this.props.setFieldTouched(key, false);
        }
      });

      this.setState({
        previewImage: selectedCompany.logoUrl,
      });
    }
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    const disabled =
      Boolean(values.companyId) ||
      (Boolean(this.props.company) && !this.props.companyAdmin);
    return (
      <div className={styles['registration-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            {!this.props.company && (
              <CompaniesSelect onChange={this.handleCompanySelect} />
            )}
            <div className={styles['left-side']}>
              <InputField
                name="name"
                type="string"
                labelText={t('scenes.public.profileModal.company.form.name')}
                errors={errors}
                touched={touched}
                className={styles.input}
                disabled={disabled}
                errorLabel
              />
              <InputField
                name="email"
                type="email"
                labelText={t('scenes.public.profileModal.company.form.email')}
                errors={errors}
                touched={touched}
                className={styles.input}
                disabled={Boolean(this.props.company) || disabled}
                errorLabel
              />
              <InputField
                name="website"
                type="string"
                labelText={t('scenes.public.profileModal.company.form.website')}
                errors={errors}
                touched={touched}
                className={styles.input}
                disabled={disabled}
                errorLabel
              />
              <InputField
                name="legalForm"
                type="select"
                labelText={t(
                  'scenes.public.profileModal.company.form.legalForm.label'
                )}
                errors={errors}
                touched={touched}
                options={LEGAL_FORM_OPTIONS}
                onChange={this.handleSelect}
                className={styles.input}
                value={values.legalForm}
                disabled={disabled}
                validate
                errorLabel
              />
              {Boolean(this.props.company) ? (
                <FormGroup className={styles.input}>
                  <Label>
                    {t(
                      'scenes.public.profileModal.company.form.employees.label'
                    )}
                  </Label>
                  <Button
                    onClick={() =>
                      this.props.onManageEmployees('company-manage-employees')
                    }
                    color="secondary"
                    disabled={Boolean(!this.props.company) || disabled}
                    block
                  >
                    {t(
                      'scenes.public.profileModal.company.form.employees.button'
                    )}
                  </Button>
                </FormGroup>
              ) : (
                <InputField
                  name="position"
                  type="select"
                  labelText={t(
                    'scenes.public.profileModal.company.form.position.label'
                  )}
                  errors={errors}
                  touched={touched}
                  options={POSITION_OPTIONS}
                  onChange={this.handleSelect}
                  className={styles.input}
                  validate
                  errorLabel
                />
              )}
            </div>
            <div className={styles['right-side']}>
              <ImageUpload
                name="logo"
                type="file"
                onChange={this.handleImageSelect}
                onClose={this.handleImageCancel}
                previewImage={this.state.previewImage}
                errorMessage={errors.logo}
                disabled={disabled}
              />
              <div className={styles['name-preview']}>{values.name}</div>
            </div>
          </div>
          <footer>
            <FormGroup>
              <Button onClick={this.props.onCancel} color="secondary" outline>
                {t('scenes.public.profileModal.company.form.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  isFetching || (disabled && Boolean(this.props.company))
                }
              >
                {t('scenes.public.profileModal.company.form.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companies.companies,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps),
  withFormik({
    mapPropsToValues: props => props.company || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.logo === 'undefined') {
        delete values.logo;
      }
      delete values.checkEmail;
      delete values.logoUrl;
      delete values.companiesUsers;

      let payload;
      if (
        typeof values.companyId !== 'undefined' &&
        values.companyId !== '' &&
        !Boolean(formikBag.props.company)
      ) {
        payload = { id: values.companyId, position: values.position };
      }
      delete values.companyId;

      formikBag.props.onSubmit({
        companyAttributes: typeof payload === 'undefined' ? values : payload,
      });
    },

    displayName: 'ProfileCompanyForm',
  })
)(CompanyForm);
