import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import { Link } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';
import Users from 'services/api/Users';

import privacy_statement_en from 'assets/documents/privacy_statement_en.pdf';
import privacy_statement_hu from 'assets/documents/privacy_statement_hu.pdf';

import styles from './styles.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  registerCompany: false,
  privacyStatement: false,
  emailConsent: false,
  avatar: undefined,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  email: Yup.string()
    .required('required')
    .email('email.invalid')
    .test('free-email', 'email.taken', value => {
      if (typeof value !== 'undefined') {
        return Users.checkEmail({
          email: value,
          type: 'user',
        })
          .then(({ data: { isOk } }) => isOk)
          .catch(false);
      }
    }),
  password: Yup.string()
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/, 'password')
    .required('required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'passwordConfirmationMatch')
    .required('required'),
  registerCompany: Yup.boolean(),
  emailConsent: Yup.boolean(),
  privacyStatement: Yup.boolean().oneOf([true], 'privacyStatement'),
  avatar: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});

class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: props.personalData.avatarPreview || '',
    };
  }

  handleImageSelect = e => {
    this.props.setFieldValue('avatar', e.target.files[0]);
    this.props.setFieldValue(
      'avatarPreview',
      URL.createObjectURL(e.target.files[0])
    );
    this.props.setFieldTouched('avatar');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('avatar', '');
    this.props.setFieldValue('avatarPreview', '');
    this.setState({
      previewImage: '',
    });
  };

  getPrivacyStatement = () => {
    switch (this.props.language) {
      case 'en':
      case 'sb':
      default:
        return privacy_statement_en;
      case 'hu':
        return privacy_statement_hu;
    }
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <section className="public-wrapper">
        <div className={styles.title}>
          <h2>{t('scenes.public.registration.personal.title')}</h2>
          <hr />
        </div>
        <div className={styles['registration-form']}>
          <Form className={styles.form}>
            <div className={styles['form-wrapper']}>
              <div className={styles['left-side']}>
                <InputField
                  name="firstName"
                  type="string"
                  labelText={t(
                    'scenes.public.registration.personal.form.firstName'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  errorLabel
                />
                <InputField
                  name="lastName"
                  type="string"
                  labelText={t(
                    'scenes.public.registration.personal.form.lastName'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  errorLabel
                />
                <InputField
                  name="email"
                  type="email"
                  labelText={t(
                    'scenes.public.registration.personal.form.email'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  errorLabel
                />
                <InputField
                  name="password"
                  type="password"
                  labelText={t(
                    'scenes.public.registration.personal.form.password'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  errorLabel
                />
                <InputField
                  name="passwordConfirmation"
                  type="password"
                  labelText={t(
                    'scenes.public.registration.personal.form.passwordConfirmation'
                  )}
                  errors={errors}
                  touched={touched}
                  className={styles.input}
                  errorLabel
                />
              </div>
              <div className={styles['right-side']}>
                <ImageUpload
                  name="avatar"
                  type="file"
                  onChange={this.handleImageSelect}
                  onClose={this.handleImageCancel}
                  previewImage={this.state.previewImage}
                  errorMessage={errors.avatar}
                />
                <div className={styles['name-preview']}>
                  {values.firstName} {values.lastName}
                </div>
              </div>
            </div>
            <footer>
              <div className={styles.checkboxes}>
                <InputField
                  name="registerCompany"
                  type="checkbox"
                  labelText={t(
                    'scenes.public.registration.personal.form.registerCompany'
                  )}
                  value={values.registerCompany}
                  errors={errors}
                  touched={touched}
                />
                <InputField
                  name="privacyStatement"
                  type="checkbox"
                  labelText={t(
                    'scenes.public.registration.personal.form.privacyStatement'
                  )}
                  errors={errors}
                  touched={touched}
                  value={values.privacyStatement}
                  downloadPdf
                  pdf={this.getPrivacyStatement()}
                  errorLabel
                />
                <InputField
                  name="emailConsent"
                  type="checkbox"
                  labelText={t(
                    'scenes.public.registration.personal.form.emailConsent'
                  )}
                  errors={errors}
                  touched={touched}
                  value={values.emailConsent}
                  errorLabel
                />
              </div>
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isFetching}
                  block
                >
                  {t(
                    `scenes.public.registration.personal.form.submit.${
                      values.registerCompany ? 'continue' : 'register'
                    }`
                  )}
                </Button>
              </FormGroup>
              <FormGroup>
                {t('scenes.public.registration.personal.form.cancel.text')}{' '}
                <Link to="/login">
                  {t('scenes.public.registration.personal.form.cancel.link')}
                </Link>
              </FormGroup>
            </footer>
          </Form>
        </div>
      </section>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props =>
      (Object.keys(props.personalData).length && props.personalData) ||
      initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.avatar === 'undefined') {
        delete values.avatar;
      }
      delete values.avatarPreview;
      formikBag.props.onSubmit(values);
    },

    displayName: 'RegistrationPersonalForm',
  })
)(Personal);
