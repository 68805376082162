import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AnswerTypes } from 'scenes/Admin/AnswerTypes';
import { AdminCompetences } from 'scenes/Admin/AdminCompetences';
import { Dashboard } from 'scenes/Admin/Dashboard';
import { Moderation } from 'scenes/Admin/Moderation';
import { PostTopics } from 'scenes/Admin/PostTopics';
import { Users } from 'scenes/Admin/Users';
import { Companies } from 'scenes/Admin/Companies';
import { Error404 } from 'scenes/Error404';

const AdminLayout = () => {
  return (
    <Switch>
      <Route path="/admin/answer-types" component={AnswerTypes} />
      <Route path="/admin/competences" component={AdminCompetences} />
      <Route path="/admin/dashboard" component={Dashboard} />
      <Route path="/admin/moderation" component={Moderation} />
      <Route path="/admin/post-topics" component={PostTopics} />
      <Route path="/admin/users" component={Users} />
      <Route path="/admin/companies" component={Companies} />

      <Redirect exact from="/admin" to="/admin/dashboard" />

      {/* "Catch all" page */}
      <Route component={Error404} />
    </Switch>
  );
};

export default AdminLayout;
