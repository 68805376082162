import { handleActions } from 'redux-actions';
import {
  confirmation,
  confirmationSuccess,
  confirmationFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  errors: [],
};

const confirmationReducer = handleActions(
  {
    [confirmation]: state => ({
      ...state,
      isFetching: true,
    }),

    [confirmationSuccess]: state => ({
      ...state,
      isFetching: false,
    }),

    [confirmationFailure]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload.errors,
    }),
  },
  INITIAL_STATE
);

export default confirmationReducer;
