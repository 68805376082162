import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import actions from './actions';
import { Table, FormGroup, Button } from 'reactstrap';
import { Form, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';
import { Modal } from 'components/Modal';
import { ConfirmModal } from 'components/ConfirmModal';

import styles from './styles.module.scss';

const initialValues = {
  name: '',
  answerOptions: [{ textEn: '', textHu: '', textSr: '' }],
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(40)
    .required('required'),
  answerOptions: Yup.array().of(
    Yup.object().shape({
      textEn: Yup.string()
        .max(20, 'Must be at most 20 characters')
        .required('required'),
      textHu: Yup.string()
        .max(20, 'Must be at most 20 characters')
        .required('required'),
      textSr: Yup.string()
        .max(20, 'Must be at most 20 characters')
        .required('required'),
    })
  ),
});

class AnswerTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      openDelete: false,
      activeItem: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.getAnswerTypes());
  }

  toggleModal = item => {
    this.setState({ activeItem: item, openModal: !this.state.openModal });
  };

  toggleDelete = item => {
    item.deletable &&
      this.setState({ activeItem: item, openDelete: !this.state.openDelete });
  };

  handleDelete = () => {
    this.props.dispatch(actions.deleteAnswerType(this.state.activeItem.id));
    this.toggleDelete(this.state.activeItem);
  };

  render() {
    const { isFetching, answerTypes, t } = this.props;
    return (
      <div>
        <div className={styles.header}>
          <h1 className={styles.title}>
            {t('scenes.admin.answerTypes.title')}
          </h1>
          <Button
            className={styles.button}
            onClick={() => this.toggleModal()}
            color="link"
          >
            <span className={styles.icon}>
              <i className="fas fa-plus" />
            </span>
          </Button>
        </div>
        {answerTypes.length > 0 && (
          <Table hover responsive className={styles.table}>
            <thead>
              <tr>
                <th className={styles.main}>
                  {t('scenes.admin.answerTypes.table.name')}
                </th>
                <th className={styles.actions}>
                  {t('scenes.admin.answerTypes.table.actions.header')}
                </th>
              </tr>
            </thead>
            <tbody>
              {answerTypes.map(answerType => (
                <tr key={answerType.id}>
                  <td className="align-middle">{answerType.name}</td>
                  <td>
                    <Button
                      className={styles['nav-link']}
                      onClick={() => this.toggleModal(answerType)}
                      color="link"
                    >
                      {t('scenes.admin.answerTypes.table.actions.edit')}
                    </Button>
                    <Button
                      className={styles['nav-link']}
                      onClick={() => this.toggleDelete(answerType)}
                      color="link"
                      disabled={!answerType.deletable}
                    >
                      {t('scenes.admin.answerTypes.table.actions.delete')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <ConfirmModal
          modalOpen={this.state.openDelete}
          toggleModal={this.toggleDelete}
          primaryButtonText={t('confirmationModal.submit')}
          onModalConfirm={this.handleDelete}
        >
          <i className={`far fa-times-circle ${styles['modal-icon']}`} />
          <h3>{t('confirmationModal.title')}</h3>
          <p>
            {t('confirmationModal.text', {
              name: this.state.activeItem && this.state.activeItem.name,
              resource: 'answer type',
            })}
          </p>
        </ConfirmModal>
        {/* Add/Edit modal - will be a component */}
        <Modal
          modalOpen={this.state.openModal}
          toggleModal={() => this.toggleModal()}
          sidebarName="Answer Type"
          sidebarTitleText={t(
            `scenes.admin.answerTypes.modal.sidebar.${
              this.state.activeItem ? 'edit' : 'add'
            }`
          )}
          sidebarSubTitleText={t(
            'scenes.admin.answerTypes.modal.sidebar.subtitle'
          )}
        >
          <Formik
            initialValues={
              this.state.activeItem ? this.state.activeItem : initialValues
            }
            validationSchema={validationSchema}
            onSubmit={values => {
              this.state.activeItem
                ? this.props.dispatch(actions.updateAnswerType(values))
                : this.props.dispatch(actions.createAnswerType(values));
              this.toggleModal();
            }}
            render={({ values, errors, touched }) => (
              <div className={styles['answer-type-form']}>
                <Form className={styles.form}>
                  <div className={styles['form-wrapper']}>
                    <div className={styles.wrapped}>
                      <InputField
                        name="name"
                        type="text"
                        labelText={t(
                          'scenes.admin.answerTypes.modal.form.labels.name'
                        )}
                        errors={errors}
                        touched={touched}
                        errorLabel
                      />
                      <FieldArray
                        name="answerOptions"
                        render={arrayHelpers => (
                          <div className={styles.options}>
                            {values.answerOptions &&
                              values.answerOptions.length > 0 &&
                              values.answerOptions.map((options, index) => (
                                <div key={index} className={styles.option}>
                                  <InputField
                                    name={`answerOptions.${index}.textEn`}
                                    type="text"
                                    placeholder={
                                      t(
                                        'scenes.admin.answerTypes.modal.form.labels.buttonText'
                                      ) + ' (en)'
                                    }
                                    errors={errors}
                                    touched={touched}
                                    errorLabel
                                    className={styles.input}
                                  />
                                  <InputField
                                    name={`answerOptions.${index}.textHu`}
                                    type="text"
                                    placeholder={
                                      t(
                                        'scenes.admin.answerTypes.modal.form.labels.buttonText'
                                      ) + ' (hu)'
                                    }
                                    errors={errors}
                                    touched={touched}
                                    errorLabel
                                    className={styles.input}
                                  />
                                  <InputField
                                    name={`answerOptions.${index}.textSr`}
                                    type="text"
                                    placeholder={
                                      t(
                                        'scenes.admin.answerTypes.modal.form.labels.buttonText'
                                      ) + ' (sr)'
                                    }
                                    errors={errors}
                                    touched={touched}
                                    errorLabel
                                    className={styles.input}
                                  />
                                  <div className={styles['answer-buttons']}>
                                    {values.answerOptions.length < 10 && (
                                      <Button
                                        className={styles['answer-button']}
                                        onClick={() =>
                                          arrayHelpers.insert(index + 1, {
                                            text: '',
                                          })
                                        }
                                        color="link"
                                      >
                                        <span className={styles.icon}>
                                          <i className="fas fa-plus" />
                                        </span>
                                      </Button>
                                    )}
                                    {values.answerOptions.length > 1 && (
                                      <Button
                                        className={styles['answer-button']}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        color="link"
                                      >
                                        <span className={styles.icon}>
                                          <i className="fas fa-minus" />
                                        </span>
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <footer>
                    <FormGroup className={styles.buttons}>
                      <Button
                        onClick={() => this.toggleModal()}
                        color="secondary"
                        outline
                      >
                        {t(
                          'scenes.admin.answerTypes.modal.form.buttons.cancel'
                        )}
                      </Button>
                    </FormGroup>
                    <FormGroup className={styles.buttons}>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isFetching}
                      >
                        {t(
                          'scenes.admin.answerTypes.modal.form.buttons.submit'
                        )}
                      </Button>
                    </FormGroup>
                  </footer>
                </Form>
              </div>
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.answerTypes.isFetching,
  apiErrors: state.answerTypes.errors,
  answerTypes: state.answerTypes.answerTypes,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(AnswerTypes);
