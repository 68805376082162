import React from 'react';
import PropTypes from 'prop-types';
import { Table, UncontrolledCollapse } from 'reactstrap';
import styles from './styles.module.scss';

const UserAnswers = ({ competenceId, competenceAnswers }) => (
  <div className={styles['user-answers']}>
    <UncontrolledCollapse toggler={'#toggler_' + competenceId}>
      <Table hover responsive bordered className={styles.table}>
        <tbody>
          {competenceAnswers &&
            competenceAnswers.map(answer => (
              <tr className={styles.row} key={answer.user.id}>
                <td className={styles.name}>{answer.user.name}</td>
                <td className={styles.answer}>{answer.answerOption.text}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </UncontrolledCollapse>
  </div>
);

UserAnswers.propTypes = {
  competenceId: PropTypes.number.isRequired,
  competenceAnswers: PropTypes.array.isRequired,
};

export default UserAnswers;
