import { call, put, takeLatest } from 'redux-saga/effects';
import { AdminCompetences } from 'services/api';
import {
  createCompetenceGroup,
  createCompetenceGroupSuccess,
  createCompetenceGroupFailure,
  getAdminCompetences,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';
import { clearFilter } from 'components/CompetenceFilter/actions';

function* createCompetenceGroupSaga({ payload }) {
  try {
    const data = yield call(AdminCompetences.createCompetenceGroup, {
      competenceGroup: payload.values,
    });
    yield put(createCompetenceGroupSuccess(data));
    yield put(getAdminCompetences({ locale: payload.locale }));
    yield put(clearFilter());
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'created', resource: 'competenceGroup' },
        },
      ])
    );
  } catch (e) {
    yield put(createCompetenceGroupFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () =>
  takeLatest(createCompetenceGroup, createCompetenceGroupSaga);
