import { all } from 'redux-saga/effects';
import getAdminCompanies from './watchers/getAdminCompanies';
import getAdminCompany from './watchers/getAdminCompany';
import updateCompany from './watchers/updateCompany';

function* answerTypesSaga() {
  yield all([getAdminCompanies(), getAdminCompany(), updateCompany()]);
}

export default answerTypesSaga;
