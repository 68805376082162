import { createAction } from 'redux-actions';

export const companies = createAction('COMPANIES_REQUEST');
export const companiesSuccess = createAction('COMPANIES_SUCCESS');
export const companiesFailure = createAction('COMPANIES_FAILURE');

export const companiesPublic = createAction('COMPANIES_PUBLIC_REQUEST');
export const companiesPublicSuccess = createAction('COMPANIES_PUBLIC_SUCCESS');
export const companiesPublicFailure = createAction('COMPANIES_PUBLIC_FAILURE');

export default {
  companies,
  companiesSuccess,
  companiesFailure,
  companiesPublic,
  companiesPublicSuccess,
  companiesPublicFailure,
};
