import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/FormValidations';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ImageUpload } from 'components/Form';
import { ConfirmModal } from 'components/ConfirmModal';

import styles from './styles.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  avatar: undefined,
  emailConsent: false,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  emailConsent: Yup.boolean(),
  avatar: Yup.mixed()
    .test(
      'fileFormat',
      'imageUpload.format',
      value =>
        typeof value === 'undefined' ||
        (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      'imageUpload.size',
      value =>
        typeof value === 'undefined' || (value && value.size <= FILE_SIZE)
    ),
});

class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: props.user.avatarUrl || '',
      openModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.values &&
        Object.keys(this.props.values).forEach(key => {
          this.props.setFieldTouched(key, false);
        });
    }
  }

  handleImageSelect = e => {
    this.props.setFieldValue('avatar', e.target.files[0]);
    this.props.setFieldTouched('avatar');
    this.setState({
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleImageCancel = () => {
    this.props.setFieldValue('avatar', '');
    this.setState({
      previewImage: '',
    });
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <div className={styles['registration-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            <div className={styles['left-side']}>
              <InputField
                name="firstName"
                type="string"
                labelText={t(
                  'scenes.public.profileModal.personal.form.firstName'
                )}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="lastName"
                type="string"
                labelText={t(
                  'scenes.public.profileModal.personal.form.lastName'
                )}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
              />
              <InputField
                name="email"
                type="email"
                labelText={t('scenes.public.profileModal.personal.form.email')}
                errors={errors}
                touched={touched}
                className={styles.input}
                errorLabel
                disabled
              />
              <FormGroup className={styles.input}>
                <Label>
                  {t('scenes.public.profileModal.personal.form.password.label')}
                </Label>
                <Button
                  onClick={() =>
                    this.props.onChangePassword('personal-password')
                  }
                  color="secondary"
                  block
                >
                  {t(
                    'scenes.public.profileModal.personal.form.password.button'
                  )}
                </Button>
              </FormGroup>
              <FormGroup className={styles.input}>
                <Label>
                  {t('scenes.public.profileModal.personal.form.delete.label')}
                </Label>
                <Button onClick={this.toggleModal} color="danger" block>
                  {t('scenes.public.profileModal.personal.form.delete.button')}
                </Button>
              </FormGroup>
              <FormGroup>
                <InputField
                  name="emailConsent"
                  type="checkbox"
                  labelText={t(
                    'scenes.public.profileModal.personal.form.emailConsent'
                  )}
                  errors={errors}
                  touched={touched}
                  value={values.emailConsent}
                  errorLabel
                />
              </FormGroup>
            </div>
            <div className={styles['right-side']}>
              <ImageUpload
                name="avatar"
                onChange={this.handleImageSelect}
                onClose={this.handleImageCancel}
                previewImage={this.state.previewImage}
                errorMessage={errors.avatar}
              />
              <div className={styles['name-preview']}>
                {values.firstName} {values.lastName}
              </div>
            </div>
          </div>
          <footer>
            <FormGroup>
              <Button onClick={this.props.onCancel} color="secondary" outline>
                {t('scenes.public.profileModal.personal.form.cancel')}
              </Button>
              <Button type="submit" color="primary" disabled={isFetching}>
                {t('scenes.public.profileModal.personal.form.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
        <ConfirmModal
          modalOpen={this.state.openModal}
          toggleModal={this.toggleModal}
          primaryButtonText={t('confirmationModal.submit')}
          onModalConfirm={this.props.onDeleteProfile}
        >
          <i className={`far fa-times-circle ${styles['modal-icon']}`} />
          <h3>{t('confirmationModal.title')}</h3>
          <p>
            {t('confirmationModal.text', { name: 'your', resource: 'profile' })}
          </p>
        </ConfirmModal>
      </div>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => props.user || initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      if (typeof values.avatar === 'undefined') {
        delete values.avatar;
      }
      delete values.avatarUrl;
      formikBag.props.onSubmit(values);
    },

    displayName: 'ProfilePersonalForm',
  })
)(Personal);
