import { createAction } from 'redux-actions';

export const getPostTopics = createAction('POST_TOPIC_LIST_REQUEST');
export const getPostTopicsSuccess = createAction('POST_TOPIC_LIST_SUCCESS');
export const getPostTopicsFailure = createAction('POST_TOPIC_LIST_FAILURE');
export const createPostTopic = createAction('POST_TOPIC_CREATE_REQUEST');
export const createPostTopicSuccess = createAction('POST_TOPIC_CREATE_SUCCESS');
export const createPostTopicFailure = createAction('POST_TOPIC_CREATE_FAILURE');
export const updatePostTopic = createAction('POST_TOPIC_UPDATE_REQUEST');
export const updatePostTopicSuccess = createAction('POST_TOPIC_UPDATE_SUCCESS');
export const updatePostTopicFailure = createAction('POST_TOPIC_UPDATE_FAILURE');
export const deletePostTopic = createAction('POST_TOPIC_DELETE_REQUEST');
export const deletePostTopicSuccess = createAction('POST_TOPIC_DELETE_SUCCESS');
export const deletePostTopicFailure = createAction('POST_TOPIC_DELETE_FAILURE');

export default {
  getPostTopics,
  getPostTopicsSuccess,
  getPostTopicsFailure,
  createPostTopic,
  createPostTopicSuccess,
  createPostTopicFailure,
  updatePostTopic,
  updatePostTopicSuccess,
  updatePostTopicFailure,
  deletePostTopic,
  deletePostTopicSuccess,
  deletePostTopicFailure,
};
