import { combineReducers } from 'redux';
// Services
import { reducer as session } from 'services/session';
// Public Scenes
import { reducer as registration } from 'scenes/Registration';
import { reducer as forgotPassword } from 'scenes/ForgotPassword';
import { reducer as resetPassword } from 'scenes/ResetPassword';
import { reducer as competences } from 'scenes/Competences';
import { reducer as social } from 'scenes/Social';
import { reducer as confirmation } from 'scenes/Confirmation';
// Admin Scenes
import { reducer as dashboard } from 'scenes/Admin/Dashboard';
import { reducer as answerTypes } from 'scenes/Admin/AnswerTypes';
import { reducer as adminCompetences } from 'scenes/Admin/AdminCompetences';
import { reducer as adminUsers } from 'scenes/Admin/Users';
import { reducer as adminCompanies } from 'scenes/Admin/Companies';
import { reducer as postTopics } from 'scenes/Admin/PostTopics';
import { reducer as moderation } from 'scenes/Admin/Moderation';
// Components
import { reducer as notifications } from 'components/Notification';
import { reducer as language } from 'components/LanguageSelector';
import { reducer as companies } from 'components/CompaniesSelect';
import { reducer as users } from 'components/UsersSelect';
import { reducer as profile } from 'components/ProfileModal';
import { reducer as comment } from 'components/Comment';
import { reducer as post } from 'components/PostModal';
import { reducer as manageEmployees } from 'components/ManageEmployees';
import { reducer as competenceFilter } from 'components/CompetenceFilter';
import { reducer as postSubscription } from 'components/PostSubscription';

export const rootReducer = combineReducers({
  // Services
  session,
  // Public Scenes
  registration,
  forgotPassword,
  resetPassword,
  competences,
  social,
  confirmation,
  // Admin Scenes
  dashboard,
  answerTypes,
  adminCompetences,
  adminUsers,
  adminCompanies,
  postTopics,
  moderation,
  // Components
  notifications,
  language,
  companies,
  users,
  profile,
  comment,
  post,
  manageEmployees,
  competenceFilter,
  postSubscription,
});
