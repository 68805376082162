import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  addSubscription,
  addSubscriptionSuccess,
  addSubscriptionFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* addSubscriptionSaga({ payload }) {
  try {
    const { data } = yield call(Posts.addSubscription, payload, {
      postId: payload,
    });
    yield put(addSubscriptionSuccess(data));
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'subscribed' },
        },
      ])
    );
  } catch (e) {
    yield put(addSubscriptionFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(addSubscription, addSubscriptionSaga);
