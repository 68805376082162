import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const TextArea = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  valid,
  invalid,
  disabled,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label for={name}>{label}</Label>}
      <Input
        name={name}
        id={id}
        type="textarea"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        valid={valid}
        invalid={invalid}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default TextArea;
