import { createAction } from 'redux-actions';

export const adminCompanies = createAction('ADMIN_COMPANIES_REQUEST');
export const adminCompaniesSuccess = createAction('ADMIN_COMPANIES_SUCCESS');
export const adminCompaniesFailure = createAction('ADMIN_COMPANIES_FAILURE');

export const adminCompany = createAction('ADMIN_COMPANY_REQUEST');
export const adminCompanySuccess = createAction('ADMIN_COMPANY_SUCCESS');
export const adminCompanyFailure = createAction('ADMIN_COMPANY_FAILURE');

export const adminUpdateCompany = createAction('ADMIN_UPDATE_COMPANY_REQUEST');
export const adminUpdateCompanySuccess = createAction(
  'ADMIN_UPDATE_COMPANY_SUCCESS'
);
export const adminUpdateCompanyFailure = createAction(
  'ADMIN_UPDATE_COMPANY_FAILURE'
);

export default {
  adminCompanies,
  adminCompaniesSuccess,
  adminCompaniesFailure,
  adminCompany,
  adminCompanySuccess,
  adminCompanyFailure,
  adminUpdateCompany,
  adminUpdateCompanySuccess,
  adminUpdateCompanyFailure,
};
