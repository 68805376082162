import { call, put, takeLatest } from 'redux-saga/effects';
import { Posts } from 'services/api';
import {
  deletePost,
  deletePostSuccess,
  deletePostFailure,
} from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* deletePostSaga(action) {
  try {
    yield call(Posts.delete, action.payload);
    yield put(deletePostSuccess(action.payload));
    yield put(
      notificationActions.addNotification([
        {
          type: 'success',
          message: { code: 'deleted', resource: 'post' },
        },
      ])
    );
  } catch (e) {
    yield put(deletePostFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(deletePost, deletePostSaga);
