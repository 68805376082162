import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactstrapModal, ModalBody } from 'reactstrap';
import { Sidebar } from 'components/Sidebar';

import styles from './styles.module.scss';

class Modal extends React.Component {
  render() {
    if (!this.props.modalOpen) {
      return null;
    }

    return (
      <ReactstrapModal
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        className={`${styles.modal} ${this.props.className}`}
        centered
      >
        <div className="modal-close" onClick={this.props.toggleModal}>
          <i className="fas fa-times" />
        </div>
        <ModalBody>
          <Sidebar
            name={this.props.sidebarName}
            titleText={this.props.sidebarTitleText}
            subtitleText={this.props.sidebarSubTitleText}
            className={this.props.sidebarClassName}
          />
          <div className="modal-body-content">{this.props.children}</div>
        </ModalBody>
      </ReactstrapModal>
    );
  }
}

Modal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  sidebarName: PropTypes.string.isRequired,
  sidebarTitleText: PropTypes.string.isRequired,
  sidebarSubTitleText: PropTypes.string,
  sidebarClassName: PropTypes.string,
};

export default Modal;
