import { handleActions, combineActions } from 'redux-actions';
import {
  saveFilter,
  saveFilterSuccess,
  saveFilterFailure,
  clearFilter,
  clearFilterSuccess,
  clearFilterFailure,
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsFailure,
} from './actions';

const INITIAL_STATE = {
  isFetching: false,
  filter: {
    competences: [],
    competenceGroups: [],
    answerTypes: [],
    users: [],
    companies: [],
  },
  options: {
    competences: [],
    competenceGroups: [],
    answerTypes: [],
    users: [],
    companies: [],
  },
  errors: [],
};

const competenceFilterReducer = handleActions(
  {
    [combineActions(saveFilter, clearFilter, getFilterOptions)]: state => ({
      ...state,
      isFetching: true,
      errors: [],
    }),

    [saveFilterSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      filter: { ...state.filter, ...payload },
      errors: [],
    }),

    [clearFilterSuccess]: (state, { _payload }) => ({
      ...state,
      isFetching: false,
      filter: {
        competences: [],
        competenceGroups: [],
        answerTypes: [],
        users: [],
        companies: [],
        isFiltered: false,
      },
      errors: [],
    }),

    [getFilterOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      options: { ...state.options, ...payload },
      errors: [],
    }),

    [combineActions(
      saveFilterFailure,
      clearFilterFailure,
      getFilterOptionsFailure
    )]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      errors: payload && payload.errors,
    }),
  },
  INITIAL_STATE
);

export default competenceFilterReducer;
