import { createAction } from 'redux-actions';

export const getPostTopics = createAction('GET_POST_TOPICS_REQUEST');
export const getPostTopicsSuccess = createAction('GET_POST_TOPICS_SUCCESS');
export const getPostTopicsFailure = createAction('GET_POST_TOPICS_FAILURE');

export const createPost = createAction('CREATE_POST_REQUEST');
export const createPostSuccess = createAction('CREATE_POST_SUCCESS');
export const createPostFailure = createAction('CREATE_POST_FAILURE');

export default {
  createPost,
  createPostSuccess,
  createPostFailure,
  getPostTopics,
  getPostTopicsSuccess,
  getPostTopicsFailure,
};
