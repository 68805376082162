import { createAction } from 'redux-actions';

export const subscriptions = createAction('SUBSCRIPTIONS_REQUEST');
export const subscriptionsSuccess = createAction('SUBSCRIPTIONS_SUCCESS');
export const subscriptionsFailure = createAction('SUBSCRIPTIONS_FAILURE');

export const addSubscription = createAction('ADD_SUBSCRIPTION_REQUEST');
export const addSubscriptionSuccess = createAction('ADD_SUBSCRIPTION_SUCCESS');
export const addSubscriptionFailure = createAction('ADD_SUBSCRIPTION_FAILURE');

export default {
  subscriptions,
  subscriptionsSuccess,
  subscriptionsFailure,
  addSubscription,
  addSubscriptionSuccess,
  addSubscriptionFailure,
};
