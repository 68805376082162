import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  STUDENT,
  EDUCATION_LEVELS,
  validationOptions,
} from 'constants/SelectOptions';
import { Button, FormGroup, Label } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ErrorLabel } from 'components/Form';
import { LanguageInput, ExperienceInput } from './components';

import styles from './styles.module.scss';

const initialValues = {
  highestLevelOfEducation: '',
  student: '',
  languageExamsAttributes: [
    {
      language: '',
      level: '',
    },
  ],
  workingHours: '',
  experiencesAttributes: [
    {
      workplace: '',
      position: '',
      from: '',
      to: '',
    },
  ],
};

const validationSchema = Yup.object().shape({
  highestLevelOfEducation: Yup.string().oneOf(
    validationOptions(EDUCATION_LEVELS),
    'selectOption'
  ),
  student: Yup.string().oneOf(['', 'true', 'false'], 'selectOption'),
  languageExamsAttributes: Yup.array().of(
    Yup.object().shape(
      {
        language: Yup.string().when('level', {
          is: level => level && level.length > 0,
          then: s => s.required('languages.languageRequired'),
        }),
        level: Yup.string().when('language', {
          is: language => language && language.length > 0,
          then: s => s.required('languages.levelRequired'),
        }),
      },
      [['language', 'level'], ['level', 'language']]
    )
  ),
  workingHours: Yup.number()
    .transform(cv => (isNaN(cv) ? undefined : cv))
    .nullable()
    .oneOf([null, 0, 20, 40, 60], 'selectOption'),
  experiencesAttributes: Yup.array().of(
    Yup.object().shape(
      {
        workplace: Yup.string()
          .nullable()
          .when('position', {
            is: position => position && position.length > 0,
            then: s => s.required('experiences.required'),
          })
          .when('from', {
            is: from => from && from > 0,
            then: s => s.required('experiences.required'),
          })
          .when('to', {
            is: to => to && to > 0,
            then: s => s.required('experiences.required'),
          }),
        position: Yup.string()
          .nullable()
          .when('workplace', {
            is: workplace => workplace && workplace.length > 0,
            then: s => s.required('experiences.required'),
          })
          .when('from', {
            is: from => from && from > 0,
            then: s => s.required('experiences.required'),
          })
          .when('to', {
            is: to => to && to > 0,
            then: s => s.required('experiences.required'),
          }),
        from: Yup.number()
          .transform(cv => (isNaN(cv) ? undefined : cv))
          .nullable()
          .when('workplace', {
            is: workplace => workplace && workplace.length > 0,
            then: s => s.required('experiences.required'),
          })
          .when('position', {
            is: position => position && position.length > 0,
            then: s => s.required('experiences.required'),
          })
          .when('to', {
            is: to => to && to > 0,
            then: s => s.required('experiences.required'),
          })
          .test('valueRange', 'experiences.date', value => {
            if (typeof value === 'number') {
              const today = new Date();
              return value >= 1900 && value <= today.getFullYear();
            }
            return true;
          }),
        to: Yup.number()
          .transform(cv => (isNaN(cv) ? undefined : cv))
          .nullable()
          .test('valueRange', 'experiences.date', value => {
            if (typeof value === 'number') {
              const today = new Date();
              if (Yup.ref('from') && Yup.ref('from') > 0) {
                return (
                  value >= 1900 &&
                  value >= Yup.ref('from') &&
                  value <= today.getFullYear()
                );
              }
              return value >= 1900 && value <= today.getFullYear();
            }
            return true;
          }),
      },
      [
        ['workplace', 'position', 'from', 'to'],
        ['workplace', 'position', 'to', 'from'],
        ['workplace', 'from', 'position', 'to'],
        ['workplace', 'to', 'position', 'from'],
        ['workplace', 'from', 'to', 'position'],
        ['workplace', 'to', 'from', 'position'],

        ['position', 'workplace', 'from', 'to'],
        ['position', 'workplace', 'to', 'from'],
        ['position', 'from', 'workplace', 'to'],
        ['position', 'to', 'workplace', 'from'],
        ['position', 'from', 'to', 'workplace'],
        ['position', 'to', 'from', 'workplace'],

        ['from', 'workplace', 'position', 'to'],
        ['from', 'workplace', 'to', 'position'],
        ['from', 'position', 'workplace', 'to'],
        ['from', 'to', 'workplace', 'position'],
        ['from', 'position', 'to', 'workplace'],
        ['from', 'to', 'position', 'workplace'],

        ['to', 'workplace', 'position', 'from'],
        ['to', 'workplace', 'from', 'position'],
        ['to', 'position', 'workplace', 'from'],
        ['to', 'from', 'workplace', 'position'],
        ['to', 'position', 'from', 'workplace'],
        ['to', 'from', 'position', 'workplace'],
      ]
    )
  ),
});

class Studies extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching &&
      !this.props.isFetching &&
      this.props.apiErrors.length === 0
    ) {
      this.props.values &&
        Object.keys(this.props.values).forEach(key => {
          this.props.setFieldTouched(key, false);
        });
    }
  }

  handleSelect = e => {
    this.props.setFieldValue(e.target.name, e.target.value);
    this.props.setFieldTouched(e.target.name);
  };

  render() {
    const { isFetching, touched, errors, values, t } = this.props;
    return (
      <div className={styles['registration-form']}>
        <Form className={styles.form}>
          <div className={styles['form-wrapper']}>
            <div className={styles['left-side']}>
              <InputField
                name="highestLevelOfEducation"
                type="select"
                labelText={t(
                  'scenes.public.profileModal.studies.form.education'
                )}
                errors={errors}
                touched={touched}
                options={EDUCATION_LEVELS}
                onChange={this.handleSelect}
                className={styles.input}
                value={values.highestLevelOfEducation}
                errorLabel
                validate
              />
              <LanguageInput
                name="languageExamsAttributes"
                errors={errors}
                touched={touched}
                value={values.languageExamsAttributes}
                onSelect={this.handleSelect}
              />
            </div>
            <div className={styles['right-side']}>
              <InputField
                name="student"
                type="select"
                labelText={t('scenes.public.profileModal.studies.form.student')}
                errors={errors}
                touched={touched}
                options={[
                  { label: STUDENT[0], value: 'true' },
                  { label: STUDENT[1], value: 'false' },
                ]}
                onChange={this.handleSelect}
                className={styles.input}
                value={values.student}
                errorLabel
                validate
              />
              <FormGroup tag="fieldset" className={styles.input}>
                <Label>
                  {t(
                    'scenes.public.profileModal.studies.form.workingHours.label'
                  )}
                </Label>
                <div className={styles['radio-wrapper']}>
                  <div className={styles['left-radio']}>
                    <InputField
                      id="0"
                      name="workingHours"
                      labelText={t(
                        'scenes.public.profileModal.studies.form.workingHours.options.zero'
                      )}
                      errors={errors}
                      touched={touched}
                      type="radio"
                      value={values.workingHours}
                      onChange={this.handleSelect}
                    />
                    <InputField
                      id="40"
                      name="workingHours"
                      labelText={t(
                        'scenes.public.profileModal.studies.form.workingHours.options.forty'
                      )}
                      errors={errors}
                      touched={touched}
                      type="radio"
                      value={values.workingHours}
                      onChange={this.handleSelect}
                    />
                  </div>
                  <div className={styles['right-radio']}>
                    <InputField
                      id="20"
                      name="workingHours"
                      labelText={t(
                        'scenes.public.profileModal.studies.form.workingHours.options.twenty'
                      )}
                      errors={errors}
                      touched={touched}
                      type="radio"
                      value={values.workingHours}
                      onChange={this.handleSelect}
                    />
                    <InputField
                      id="60"
                      name="workingHours"
                      labelText={t(
                        'scenes.public.profileModal.studies.form.workingHours.options.sixty'
                      )}
                      errors={errors}
                      touched={touched}
                      type="radio"
                      value={values.workingHours}
                      onChange={this.handleSelect}
                    />
                  </div>
                  {touched.workingHours && errors.workingHours && (
                    <ErrorLabel error={errors.workingHours} />
                  )}
                </div>
              </FormGroup>
            </div>
            <ExperienceInput
              name="experiencesAttributes"
              errors={errors}
              touched={touched}
              value={values.experiencesAttributes}
              onSelect={this.handleSelect}
            />
          </div>
          <footer>
            <FormGroup>
              <Button onClick={this.props.onCancel} color="secondary" outline>
                {t('scenes.public.profileModal.studies.form.cancel')}
              </Button>
              <Button type="submit" color="primary" disabled={isFetching}>
                {t('scenes.public.profileModal.studies.form.submit')}
              </Button>
            </FormGroup>
          </footer>
        </Form>
      </div>
    );
  }
}

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: props => ({
      ...props.user,
      languageExamsAttributes:
        (props.user.languageExamsAttributes &&
          props.user.languageExamsAttributes.length > 0 &&
          props.user.languageExamsAttributes) ||
        initialValues.languageExamsAttributes,
      experiencesAttributes:
        (props.user.experiencesAttributes &&
          props.user.experiencesAttributes.length > 0 &&
          props.user.experiencesAttributes) ||
        initialValues.experiencesAttributes,
    }),

    validationSchema,

    handleSubmit: (values, formikBag) => {
      // ['languageExamsAttributes', 'experiencesAttributes'].forEach(key => {
      //   if (values[key] && values[key].length > 0) {
      //     const vals = values[key].map(value => Object.values(value));
      //     if (vals.flat().includes('')) {
      //       delete values[key];
      //     }
      //   }
      // });
      formikBag.props.onSubmit(values);
    },

    displayName: 'StudiesForm',
  })
)(Studies);
