import { call, put, takeLatest } from 'redux-saga/effects';
import { Users } from 'services/api';
import { adminUser, adminUserSuccess, adminUserFailure } from '../../actions';
import { actions as notificationActions } from 'components/Notification';

function* getAdminUser({ payload }) {
  try {
    const { data } = yield call(Users.adminUserShow, payload);
    yield put(adminUserSuccess(data));
  } catch (e) {
    yield put(adminUserFailure(e.data));
    yield put(
      notificationActions.addNotification(
        e.data.errors.map(error => ({ type: 'error', message: error.code }))
      )
    );
  }
}

export default () => takeLatest(adminUser, getAdminUser);
