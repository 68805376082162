import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from 'components/Form';

import styles from './styles.module.scss';

const initialValues = {
  nameEn: '',
  nameHu: '',
  nameSr: '',
};

const validationSchema = Yup.object().shape(
  {
    nameEn: Yup.string().when(['nameHu', 'nameSr'], {
      is: (nameHu, nameSr) => !(nameHu || nameSr),
      then: s => s.required('required'),
    }),
    nameHu: Yup.string().when(['nameEn', 'nameSr'], {
      is: (nameEn, nameSr) => !(nameEn || nameSr),
      then: s => s.required('required'),
    }),
    nameSr: Yup.string().when(['nameEn', 'nameHu'], {
      is: (nameEn, nameHu) => !(nameEn || nameHu),
      then: s => s.required('required'),
    }),
  },
  [['nameEn', 'nameHu'], ['nameEn', 'nameSr'], ['nameHu', 'nameSr']]
);

class AddCompetenceGroupForm extends React.Component {
  handleClick = () => {
    this.props.onCancel();
  };

  render() {
    const { isFetching, touched, errors, t } = this.props;
    return (
      <div className={styles['form-wrapper']}>
        <Form className={styles.form}>
          <InputField
            name="nameEn"
            type="text"
            placeholder={
              t('scenes.admin.competences.competenceGroupName') + ' (en)'
            }
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <InputField
            name="nameHu"
            type="text"
            placeholder={
              t('scenes.admin.competences.competenceGroupName') + ' (hu)'
            }
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <InputField
            name="nameSr"
            type="text"
            placeholder={
              t('scenes.admin.competences.competenceGroupName') + ' (sr)'
            }
            errors={errors}
            touched={touched}
            className={styles.inputs}
            errorLabel
          />
          <Button
            type="submit"
            color="primary"
            disabled={isFetching}
            className={styles.buttons}
          >
            {t('scenes.admin.competences.buttons.add')}
          </Button>
          <Button
            color="link"
            onClick={this.handleClick}
            className={styles.buttons}
          >
            {t('scenes.admin.competences.buttons.cancel')}
          </Button>
        </Form>
      </div>
    );
  }
}

AddCompetenceGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  withFormik({
    mapPropsToValues: () => initialValues,

    validationSchema,

    handleSubmit: (values, formikBag) => {
      formikBag.props.onSubmit(values);
    },

    displayName: 'AddCompetenceGroupForm',
  })
)(AddCompetenceGroupForm);
