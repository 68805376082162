import { all } from 'redux-saga/effects';
import watchGetPosts from './watchers/getPosts';
import watchAcceptPost from './watchers/acceptPost';
import watchDeclinePost from './watchers/declinePost';

function* moderationSaga() {
  yield all([watchGetPosts(), watchAcceptPost(), watchDeclinePost()]);
}

export default moderationSaga;
