import { all, spawn, call } from 'redux-saga/effects';
// Services
import { saga as sessionSaga } from 'services/session';
// Public Scenes
import { saga as registrationSaga } from 'scenes/Registration';
import { saga as forgotPasswordSaga } from 'scenes/ForgotPassword';
import { saga as resetPasswordSaga } from 'scenes/ResetPassword';
import { saga as competencesSaga } from 'scenes/Competences';
import { saga as socialSaga } from 'scenes/Social';
import { saga as confirmationSaga } from 'scenes/Confirmation';
// Admin Scenes
import { saga as dashboardSaga } from 'scenes/Admin/Dashboard';
import { saga as answerTypeSaga } from 'scenes/Admin/AnswerTypes';
import { saga as adminCompetencesSaga } from 'scenes/Admin/AdminCompetences';
import { saga as adminUsersSaga } from 'scenes/Admin/Users';
import { saga as adminCompaniesSaga } from 'scenes/Admin/Companies';
import { saga as postTopicsSaga } from 'scenes/Admin/PostTopics';
import { saga as moderationSaga } from 'scenes/Admin/Moderation';
// Components
import { saga as companiesSaga } from 'components/CompaniesSelect';
import { saga as logoutSaga } from 'components/AppSidebar';
import { saga as usersSaga } from 'components/UsersSelect';
import { saga as profileSaga } from 'components/ProfileModal';
import { saga as commentSaga } from 'components/Comment';
import { saga as postSaga } from 'components/PostModal';
import { saga as manageEmployeesSaga } from 'components/ManageEmployees';
import { saga as languageSelectorSaga } from 'components/LanguageSelector';
import { saga as competenceFilterSaga } from 'components/CompetenceFilter';
import { saga as postSubscriptionSaga } from 'components/PostSubscription';

const rootSagas = [
  // Services
  sessionSaga,
  // Public Scenes
  registrationSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
  competencesSaga,
  socialSaga,
  confirmationSaga,
  // Admin Scenes
  dashboardSaga,
  answerTypeSaga,
  adminCompetencesSaga,
  adminUsersSaga,
  adminCompaniesSaga,
  postTopicsSaga,
  moderationSaga,
  // Components
  companiesSaga,
  logoutSaga,
  usersSaga,
  profileSaga,
  commentSaga,
  postSaga,
  manageEmployeesSaga,
  languageSelectorSaga,
  competenceFilterSaga,
  postSubscriptionSaga,
];

const spawnRestartableSaga = saga =>
  spawn(function*() {
    while (true) {
      try {
        yield call(saga);
      } catch (error) {
        console.error(error, saga);
      }
    }
  });

export function* rootSaga() {
  yield all(rootSagas.map(spawnRestartableSaga));
}
