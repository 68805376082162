import { call, take, put } from 'redux-saga/effects';
import { Users } from 'services/api';
import {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
} from './actions';
import { actions as notificationActions } from 'components/Notification';

function* resetPasswordSaga() {
  while (true) {
    const { payload: user } = yield take(resetPassword);
    try {
      const data = yield call(Users.changePassword, { user });
      yield put(resetPasswordSuccess(data));
      yield put(
        notificationActions.addNotification([
          { type: 'success', message: { code: 'saved', resource: 'password' } },
        ])
      );
    } catch (e) {
      yield put(resetPasswordFailure(e.data));
      yield put(
        notificationActions.addNotification(
          e.data.errors.map(error => ({ type: 'error', message: error.code }))
        )
      );
    }
  }
}

export default resetPasswordSaga;
